import { createStyles, makeStyles, Tab, Tabs, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomPaper from "../../CustomPaper";
import TabPanel from "../../TabPanelComp";
import User from "../../user/User";
import { isAdmin } from "../../Util";
import DemosWaitlist from "./DemosWaitlist";
import LJDemosWaitlist from "./LJDemosWaitlist";
import MapsWaitlist from "./MapsWaitlist";

export interface TabbedDemosWaitlistProps {
  jwt: string | null,
  user: User | undefined,
  reloadWaitlistCount: () => void,
  reloadLJWaitlistCount: () => void,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    tabsWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(4)
    }
  })
);

export default function TabbedDemosWaitlist(props: TabbedDemosWaitlistProps) {
  const classes = useStyles();
  const {t} = useTranslation();

  const [tabIndex, set_tabIndex] = React.useState(0);

  return <CustomPaper>
    <div className={classes.wrapperDiv}>
      <Typography variant="overline">
        {t("menu.sent_demos")}
      </Typography>

      <div className={classes.tabsWrapper}>
        <Tabs
          value={tabIndex}
          onChange={(_, value) => set_tabIndex(value)}
          indicatorColor="primary"
          style={{
            marginTop: 0,
            paddingTop: 0,
          }}
        >
          <Tab label="KZ"/>
          <Tab label="LJ"/>

          {props.user && isAdmin(props.user) &&
            <Tab label={t("maps.label")}/>
          }
        </Tabs>
      </div>

      <TabPanel value={tabIndex} index={0} noMarginTop>
        <DemosWaitlist jwt={props.jwt} user={props.user} reloadWaitlistCount={props.reloadWaitlistCount}/>
      </TabPanel>

      <TabPanel value={tabIndex} index={1} noMarginTop>
        <LJDemosWaitlist jwt={props.jwt} user={props.user} reloadWaitlistCount={props.reloadLJWaitlistCount}/>
      </TabPanel>

      {props.user && isAdmin(props.user) &&
        <TabPanel value={tabIndex} index={2} noMarginTop>
          <MapsWaitlist jwt={props.jwt} user={props.user}/>
        </TabPanel>
      }
    </div>
  </CustomPaper>;
};
