import { createStyles, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as RWList } from 'react-window';
import { getBadge } from "../demo/page/LJTop";

import ServerTopEntry from "../server/ServerTopEntry";
import AuthorLink from "../user/AuthorLink";
import TopDataEntry from "./TopDataEntry";

export interface TopListCompProps {
  entries: TopDataEntry[] | ServerTopEntry[]
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemWrapper: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(1)
    },
    itemsWrapper: {
      display: "grid",
      alignItems: "center",
    },
  })
);

const isServerTop = (input: TopDataEntry | ServerTopEntry): input is ServerTopEntry => {
  return (input as TopDataEntry).count === undefined;
};

export default function TopListComp(props: TopListCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();


  const RowHeight = 75;

  const Row = (input: { index: number, style: CSSProperties }) => {
    const data = props.entries[input.index];

    return <div
      className={classes.itemsWrapper}
      style={{
        gridTemplateColumns: "1fr 1fr 1fr",
        height: `${RowHeight}px`,
        ...input.style,
      }}
    >
      <div className={classes.itemWrapper}>
        <Typography>
          {
            input.index <= 2 ? getBadge(input.index) : <># {input.index + 1}</>
          }
        </Typography>
      </div>

      <div className={classes.itemWrapper}>
        <AuthorLink
          author={data.player ? data.player : (data as ServerTopEntry).player_name}
          steam_id={data.player ? data.player.steamID : (data as ServerTopEntry).player_steam_id}
          avatar
        />
      </div>

      <div className={classes.itemWrapper}>
        <Typography>
          {(isServerTop(data) ? data.points : data.count).toLocaleString()}
        </Typography>
      </div>
    </div>;
  };

  return <div
    style={{
      minHeight: 2000
    }}
  >
    <div
      className={classes.itemsWrapper}
      style={{
        gridTemplateColumns: "1fr 1fr 1fr",
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        backgroundColor: theme.palette.background.default
      }}
    >
      {[
        { label: t("top.full.place") },
        { label: t("top.full.player") },
        { label: t(isServerTop(props.entries[0]) ? "top.full.points_count" : "top.full.demos_count") },
      ].map((data, index) =>
        <div
          key={index}
          className={classes.itemWrapper}>
          <Typography>
            {data.label}
          </Typography>
        </div>
      )}
    </div>

    <AutoSizer>
      {(param: { width: number }) => (
        <RWList
          height={1800}
          width={param.width}
          itemSize={RowHeight}
          itemCount={props.entries.length}
        >
          {Row}
        </RWList>
      )}
    </AutoSizer>
  </div>;
};
