import {
  Chip,
  createStyles,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import { UnregisteredUser } from "../../server/ServerRecord";
import User from "../../user/User";
import { formatDate, formatMS } from "../../Util";
import RecordFileData from "../../viewdemo/RecordFileData";
import ViewDemoLink from "../../viewdemo/ViewDemoLink";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as RWList } from "react-window";
import MapLinkComp from "../MapLinkComp";
import ViewMapLinkComp from "./ViewMapLinkComp";
import MapDownloadLinkComp from "../MapDownloadLinkComp";
import AuthorLink from "../../user/AuthorLink";

type RecT = {
  recording: RecordFileData;
  user: UnregisteredUser | null;
};

export interface ServerRecordingsProps {
  jwt: string | null;
  user: User | undefined;
  recordings: RecT[];
  set_loading: (state: boolean) => void;
  disableSearch?: boolean;
  disableHeader?: boolean;
  minHeight?: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    itemsWrapper: {
      display: "grid",
      alignItems: "center",
    },
    itemWrapper: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(1),
    },
  })
);

const cameraToStr = (camera: number) => {
  switch (camera) {
    case 1:
      return "3rd person";
    case 2:
      return "top down";

    default:
      return "";
  }
};

export default function ServerRecordings(props: ServerRecordingsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [recordings, set_recordings] = React.useState<RecT[]>([
    // {
    //   user: {
    //     avatar: {
    //       large: "",
    //       medium: "",
    //       small: ""
    //     },
    //     countryCode: "ro",
    //     displayName: "test user",
    //     steamID: "STEAM_0:1:16406939"
    //   },
    //   recording: {
    //     aa: 100,
    //     camera: 1,
    //     cp: 0,
    //     gc: 0,
    //     fileName: "somefilename",
    //     map: "bkz_goldbhop",
    //     md5: "md5",
    //     time: 1500,
    //     upload_date: new Date(),
    //     weapon: "knife"
    //   }
    // }
  ]);
  const [filterText, set_filterText] = React.useState("");

  const [sortField, set_sortField] = React.useState("date");
  const [sortAscending, set_sortAscending] = React.useState(false);

  const getSortingIcon = React.useCallback(
    (field: string) => {
      if (field !== sortField) {
        return null;
      }

      if (sortAscending) {
        return <ArrowUpwardIcon />;
      }

      return <ArrowDownwardIcon />;
    },
    [sortAscending, sortField]
  );

  React.useEffect(() => {
    set_recordings(props.recordings);
  }, [props.recordings]);

  const sortFunc = React.useCallback(
    (a: RecT, b: RecT) => {
      const factor = sortAscending ? 1 : -1;

      if (sortField === "map") {
        return a.recording.map.localeCompare(b.recording.map) * factor;
      }

      if (sortField === "weapon") {
        return a.recording.weapon.localeCompare(b.recording.weapon) * factor;
      }

      if (sortField === "player" && a.user && b.user) {
        return a.user.displayName.localeCompare(b.user.displayName) * factor;
      }

      if (sortField === "time") {
        return (a.recording.time < b.recording.time ? -1 : 1) * factor;
      }

      return (
        (a.recording.upload_date < b.recording.upload_date ? -1 : 1) * factor
      );
    },
    [sortField, sortAscending]
  );

  const filter = React.useCallback(
    (a: RecT) => {
      if (filterText.length === 0) {
        return true;
      }

      const filters = filterText.toLowerCase().split(",");

      let matches = false;

      for (const localFilter of filters) {
        if (localFilter.length === 0) {
          continue;
        }

        if (
          a.user &&
          a.user.displayName.toLowerCase().indexOf(localFilter) !== -1
        ) {
          matches = true;
          continue;
        } else if (a.recording.map.toLowerCase().indexOf(localFilter) !== -1) {
          matches = true;
          continue;
        } else {
          matches = false;
        }
      }

      return matches;
    },
    [filterText]
  );

  React.useEffect(() => {
    set_recordings(props.recordings.sort(sortFunc));
  }, [sortField, sortAscending, sortFunc, props.recordings]);

  React.useEffect(() => {
    if (filterText.length === 0) {
      set_recordings(props.recordings.sort(sortFunc));
    } else {
      set_recordings(props.recordings.sort(sortFunc).filter(filter));
    }
  }, [filterText, props.recordings, sortFunc, filter]);

  const RowHeight = 75;

  const Row = (input: { index: number; style: CSSProperties }) => {
    const data = recordings[input.index];

    return (
      <div
        className={classes.itemsWrapper}
        style={{
          gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr",
          height: `${RowHeight}px`,
          ...input.style,
        }}
      >
        <div className={classes.itemWrapper}>
          <MapLinkComp
            map={data.recording.map}
            route={
              !data.recording.route || data.recording.route.length === 0
                ? undefined
                : data.recording.route
            }
          />

          <ViewMapLinkComp
            map={data.recording.map}
            jwt={props.jwt}
            on_unauthorized={() => {}}
            set_downloading={props.set_loading}
          />

          <MapDownloadLinkComp
            icon
            hideText
            map={data.recording.map}
            jwt={props.jwt}
            set_downloading={props.set_loading}
          />
        </div>

        <div className={classes.itemWrapper}>
          {data.user ? (
            <AuthorLink
              author={data.user}
              steam_id={data.user.steamID}
              avatar
            />
          ) : (
            <Typography>NonSteam Player</Typography>
          )}

          {data.recording.aa !== 10 && (
            <Typography variant="overline">AA {data.recording.aa}</Typography>
          )}

          {data.recording.camera !== 0 && (
            <Typography variant="overline">
              {data.recording.aa !== 10 && " | "}
              {cameraToStr(data.recording.camera)}
            </Typography>
          )}
        </div>

        <div className={classes.itemWrapper}>
          <Tooltip title={formatDate(data.recording.upload_date)}>
            <Typography>{formatMS(data.recording.time)}</Typography>
          </Tooltip>

          <ViewDemoLink
            jwt={props.jwt}
            serverRecord
            demo={{
              demoMD5: data.recording.md5,
              map: data.recording.map,
              player: data.user?.displayName,
              time: data.recording.time,
            }}
          />
        </div>

        <div className={classes.itemWrapper}>
          <Typography>{data.recording.weapon}</Typography>
        </div>

        {data.recording.gc === 0 ? (
          <div className={classes.itemWrapper}>
            <Chip label="Pro" color="primary" />
          </div>
        ) : (
          <div className={classes.itemWrapper}>
            <Typography>
              {data.recording.cp} / {data.recording.gc}
            </Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={classes.wrapperDiv}>
      {!props.disableSearch && (
        <TextField
          value={filterText}
          onChange={(e) => {
            set_filterText(e.target.value.toLowerCase());
          }}
          autoComplete="off"
          variant="outlined"
          fullWidth
          label={t("demo.table.filter.map_player")}
          style={{
            paddingBottom: theme.spacing(2),
          }}
        />
      )}

      {!props.disableSearch && (
        <div
          className={classes.itemWrapper}
          style={{
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
          }}
        >
          <Typography>
            {recordings.length} {t("server_records.count.plural")}
          </Typography>
        </div>
      )}

      {!props.disableHeader && (
        <div
          className={classes.itemsWrapper}
          style={{
            gridTemplateColumns: "2fr 2fr 1fr 1fr 1fr",
            paddingBottom: theme.spacing(6),
            paddingTop: theme.spacing(4.7),
          }}
        >
          {[
            {
              field: "map",
              label: t("demo.table.columns.map"),
              sortable: true,
            },
            {
              field: "player",
              label: t("demo.table.columns.player"),
              sortable: true,
            },
            {
              field: "time",
              label: t("demo.table.columns.time"),
              sortable: true,
            },
            {
              field: "weapon",
              label: t("demo.table.columns.weapon"),
              sortable: true,
            },
            { field: "cp/gc", label: "CP/GC", sortable: false },
          ].map(({ field, label, sortable }, index) => (
            <div
              key={index}
              className={classes.itemWrapper}
              onClick={() => {
                if (sortable) {
                  set_sortField(field);
                  set_sortAscending(!sortAscending);
                }
              }}
            >
              <Typography>{label}</Typography>

              {sortable && getSortingIcon(field)}
            </div>
          ))}
        </div>
      )}

      <div
        style={{
          minHeight: props.minHeight
            ? Math.min(props.minHeight, RowHeight * recordings.length)
            : 1800,
        }}
      >
        <AutoSizer>
          {(param: { width: number }) => (
            <RWList
              height={
                props.minHeight
                  ? Math.min(props.minHeight, RowHeight * recordings.length)
                  : 1800
              }
              width={param.width}
              itemSize={RowHeight}
              itemCount={recordings.length}
            >
              {Row}
            </RWList>
          )}
        </AutoSizer>
      </div>
    </div>
  );
}
