import { createStyles, List, ListItem, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";

import AuthorLink from "../user/AuthorLink";

import CustomPaper from "../CustomPaper";
import User from "../user/User";

export interface OnlineUsersCompProps {
  users: User[]
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sectionTitle: {
      padding: theme.spacing(2),
    },
  })
);

export default function OnlineUsersComp(props: OnlineUsersCompProps) {
  const classes = useStyles();

  return <CustomPaper>
    <Tooltip title="Useri online pe site">
      <Typography variant="overline" className={classes.sectionTitle}>
        Stalkerbox
      </Typography>
    </Tooltip>

    <List>
      {props.users.map(((user, index) => <ListItem key={index}>
        <AuthorLink author={user} steam_id={user.steamID} avatar/>
      </ListItem>))}
    </List>
  </CustomPaper>;
};
