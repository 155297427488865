import React from "react";
import { useHistory, useParams } from "react-router-dom";

export interface LoginSuccessProps {
  jwt_setter: (token: string) => void
};

export default function LoginSuccess(props: LoginSuccessProps) {
  const params = useParams<{ token: string }>();
  const history = useHistory();

  React.useEffect(() => {
    props.jwt_setter(params.token);

    history.push('/');
  }, [props, params.token, history]);

  return null;
};
