import { createStyles, Fade, Link, List, ListItem, ListItemText, makeStyles, Tab, Tabs, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { RecordableWeapons, wpnToString } from "../demo/Weapon";
import TabPanel from "../TabPanelComp";

export interface RulesProps {
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    }
  })
);

export default function Rules(props: RulesProps) {
  const classes = useStyles();
  const {t} = useTranslation();

  const [tabIndex, set_tabIndex] = React.useState(0);

  const validSettings = <List>
    {[
      "edgefriction \"2\"",
      "sv_gravity \"800\"",
      "sv_stopspeed \"75\"",
      "sv_maxspeed \"320\"",
      "sv_accelerate \"5\"",
      "sv_airaccelerate \"10\"",
      "sv_wateraccelerate \"10\"",
      "sv_friction \"4\"",
      "sv_waterfriction \"1\"",
      "sv_stepsize \"18\"",
      "sv_maxvelocity \"2000\"",
      "sv_cheats \"0\"",
      "mp_footsteps \"1\"",
      "cl_lw \"1\"",
      "cl_sidespeed \"400\"",
      "cl_backspeed \"400\"",
      "cl_forwardspeed \"400\"",
      "cl_movespeedkey \"0.52\"",
      "fps_max \"99.5\"",
      "fps_override \"0\"",
      "gl_vsync \"0\"",
    ].map((a, index) => <ListItem key={index}>
      <ListItemText primary={a}/>
    </ListItem>)}
  </List>;

  return <div className={classes.wrapper}>
    <Typography variant="overline">
      Reguli
    </Typography>

    <Tabs
      value={tabIndex}
      onChange={(_, value) => set_tabIndex(value)}
      indicatorColor="primary"
      centered
    >
      <Tab label="Site"/>
      <Tab label="KZ Demo"/>
      <Tab label="LJ Demo"/>
      <Tab label={`${t("maps.label")}`}/>
    </Tabs>

    <TabPanel value={tabIndex} index={0}>
      <Fade in timeout={500} unmountOnExit>
        <List>
          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.site.1")}
              </Typography> 
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText primary={
                <Typography variant="subtitle2">
                  {t("rules.site.2")}
                </Typography> 
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText primary={
                <Typography variant="subtitle2">
                  {t("rules.site.3")}
                </Typography> 
              }
            />
          </ListItem>
        </List>
      </Fade>
    </TabPanel>

    <TabPanel value={tabIndex} index={1}>
      <Fade in timeout={500} unmountOnExit>
        <List>
          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.1")} <Link href="https://kz-rush.ru/downloads.php?action=download&id=1" target="_blank" style={{color: "red"}}>
                KZ RUSH
                </Link>
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.2.1")}<b>{t("rules.kz.2.2")}</b>{t("rules.kz.2.3")}
              </Typography>
            }
            secondary={
              <Typography variant="subtitle2">
                {t("rules.kz.2.example.1")} <i>{t("rules.kz.2.example.2")}</i>{t("rules.kz.2.example.3")}<b>{t("rules.kz.2.example.4")}</b>{t("rules.kz.2.example.5")}<u style={{color:"red"}}>{t("rules.kz.2.example.6")}</u>
                <br/>{t("rules.kz.2.example.7")} <i>{t("rules.kz.2.example.8")}</i>{t("rules.kz.2.example.9")}<b>{t("rules.kz.2.example.10")}</b>{t("rules.kz.2.example.11")}<u style={{color:"red"}}>{t("rules.kz.2.example.12")}</u>
              </Typography>
            }
            />
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.3.1")}<b>{t("rules.kz.3.2")}</b>
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.4")}<b>LAN</b>
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.5")}(<b>/speed, /showpre, /ducks, /multibhop, /duckspre, /ljpre, /jumpoff, etc</b>)
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.6.1")}<b>{t("rules.kz.6.2")}</b>{t("rules.kz.6.3")}
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.7")}
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.8.1")}<b>{t("rules.kz.8.2")}</b>{t("rules.kz.8.3")}
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
              <Typography variant="subtitle2">
                {t("rules.kz.9")}
              </Typography>
              }
              secondary={
                <Typography variant="subtitle2">
                  {validSettings}
                </Typography>
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.10.1")}<b>{t("rules.kz.10.2")}</b>{t("rules.kz.10.3")}
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.11.1")}<b>{t("rules.kz.11.2")}</b>{t("rules.kz.11.3")}<b>{t("rules.kz.11.2")}</b>{t("rules.kz.11.4")}
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.12")}
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
                <Typography variant="subtitle2">
                  {t("rules.kz.13")}
                </Typography>
              }
              secondary={
                <Typography variant="subtitle2">
                  <List>
                    {RecordableWeapons.map((a, index) => <ListItem key={index}>
                      <ListItemText primary={wpnToString(a)}/>
                    </ListItem>)}
                  </List>
                </Typography>
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.kz.14")}
              </Typography>
            }/>
          </ListItem>
        </List>
      </Fade>
    </TabPanel>

    <TabPanel value={tabIndex} index={2}>
      <Fade in timeout={500} unmountOnExit>
        <List>
          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.lj.1.1")}<b>kz_longjumps2</b>{t("rules.lj.1.2")}<b>kz_longjumps3</b>
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.lj.2.1")}<b>{t("rules.lj.2.2")}</b> !
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.lj.3.1")}<b>{t("rules.lj.3.2")}</b>
              </Typography>
            }
            secondary={
              <Typography variant="subtitle2">
                {t("rules.lj.3.3")}

                <List>
                  {[
                    "Longjump - lj",
                    "Countjump - cj",
                    "Standup Countjump - scj",
                    "Double/Multi Countjump - dcj/mcj",
                    "Highjump - hj",
                    "Standup Bhopjump - sbj",
                    "Bhopjump - bj",
                    "Weirdjump - wj",
                    "Ladderjump - ldj",
                    "Slide Longjump - slj"
                  ].map((item, index) => <ListItem key={index}>
                    <ListItemText primary={item}/>
                  </ListItem>)}
                </List>
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
              <Typography variant="subtitle2">
                {t("rules.lj.4")}
              </Typography>
              }
              secondary={
                <Typography variant="subtitle2">
                  {validSettings}
                </Typography>
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
              <Typography variant="subtitle2">
                {t("rules.lj.5")}
              </Typography>
              }
            />
          </ListItem>

          <ListItem>
            <ListItemText
              primary={
              <Typography variant="subtitle2">
                {t("rules.lj.6")}
              </Typography>
              }
            />
          </ListItem>
        </List>
      </Fade>
    </TabPanel>

    <TabPanel value={tabIndex} index={3}>
      <Fade in timeout={500} unmountOnExit>
        <List>
          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.maps.1")}
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.maps.2")}
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.maps.3")}
              </Typography>
            }/>
          </ListItem>

          <ListItem>
            <ListItemText primary={
              <Typography variant="subtitle2">
                {t("rules.maps.4")}
              </Typography>
            }/>
          </ListItem>
        </List>
      </Fade>
    </TabPanel>
  </div>;
};
