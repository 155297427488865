import React from "react";
import { Backdrop, Button, CircularProgress, createStyles, Dialog, Fade, IconButton, Link, List, ListItem, ListItemText, makeStyles, Tab, Tabs, TextField, Theme, Tooltip, Typography } from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import { useParams } from "react-router-dom";

import SteamID from 'steamid';

import { MaxAllowedWarnings, NEW_API_URL } from "../../Config";
import TabPanel from "../../TabPanelComp";

import DemoWaitlistData from "../../demo/DemoWaitlistData";
import LJDemoData from "../../demo/LJDemoData";
import DemosTableComp from "../../demo/DemosTableComp";
import LJTableComp from "../../demo/LJTableComp";

import User from "../User";
import { userTypeToStr } from "../UserType";
import { useTranslation } from "react-i18next";
import { isAdmin } from "../../Util";
import MapTableComp from "../../map/MapTableComp";
import MapWaitlistData from "../../map/MapWaitlistData";
import ServerRecordsTabs from "../../server/ServerRecordsTabs";
import ServerRecord from "../../server/ServerRecord";
import { SortMode } from "../../server/ServerRecordListComp";
import { MapInfo } from "../../map/MapDataDTO";
import CryptoJS from "crypto-js";

export interface ProfileProps {
  jwt: string | null,
  user: User | undefined
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    },
    gridWrapper: {
      minHeight: "50vh"
    },
    generalInfoWrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: theme.spacing(3)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
    confirmationWrapper: {
      margin: theme.spacing(3)
    },
    confirmationButtonWrapper: {
      paddingTop: theme.spacing(3),
      display: "flex"
    },
    link: {
      color: theme.palette.text.secondary,
      textDecoration: "none",
    },
  })
);

export default function Profile(props: ProfileProps) {
  const classes = useStyles();
  const params = useParams<{ id: string }>();
  const { t } = useTranslation();

  const [tabIndex, set_tabIndex] = React.useState(0);

  const [userToDisplay, set_userToDisplay] = React.useState<User | undefined>(props.user);
  const [fetchedUser, set_fetchedUser] = React.useState(false);

  const [displayName, set_displayName] = React.useState(userToDisplay?.displayName);
  const [nameChanged, set_nameChanged] = React.useState(displayName !== userToDisplay?.displayName);

  const [kzRecords, set_kzRecords] = React.useState<DemoWaitlistData[]>([]);
  const [fetchedkzRecords, set_fetchedkzRecords] = React.useState(false);

  const [ljRecords, set_ljRecords] = React.useState<LJDemoData[]>([]);
  const [fetchedLjRecords, set_fetchedLjRecords] = React.useState(false);

  const [maps, set_maps] = React.useState<MapWaitlistData[]>([]);
  const [fetchedMaps, set_fetchedMaps] = React.useState(false);

  const [serverRecords, set_serverRecords] = React.useState<ServerRecord[]>([]);
  const [fetchedServerRecords, set_fetchedServerRecords] = React.useState(false);
  const [unfinishedMaps, set_unfinishedMaps] = React.useState<MapInfo[]>([]);

  const [downloading, set_downloading] = React.useState(false);

  const [warnedThisSession, set_warnedThisSession] = React.useState(false);

  const [warnConfirmationDialogOpen, set_warnConfirmationDialogOpen] = React.useState(false);
  const [userBanned, set_userBanned] = React.useState(false);

  const [newPassword, set_newPassword] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!props.user) {
      return;
    }

    if (tabIndex === 0 && !fetchedUser) {
      fetch(`${NEW_API_URL}/user/profile/${params.id}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${props.jwt}`
        }
      }).then(async (response) => {
        if (response.status !== 200) {
          if (response.status === 423) {
            set_userBanned(true);
          }
        } else {
          const data = await response.json() as User;

          set_userToDisplay(data);
          set_displayName(data.displayName);
        }
      }).catch((reason) => {
        console.error(reason);
      }).finally(() => {
        set_fetchedUser(true);
      });
    }
  }, [tabIndex, params, fetchedUser, props.jwt, props.user]);

  React.useEffect(() => {
    if (!props.user) {
      return;
    }

    if (tabIndex === 1 && !fetchedkzRecords) {
      fetch(`${NEW_API_URL}/demos/kz/${params.id}/released`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${props.jwt}`
        }
      }).then(async (response) => {
        if (response.status !== 200) {
          if (response.status === 423) {
            set_userBanned(true);
          }
        } else {
          set_kzRecords(await response.json());
        }
      }).catch((reason) => {
        console.error(reason);
      }).finally(() => {
        set_fetchedkzRecords(true);
      });
    }
  }, [tabIndex, props, params, fetchedkzRecords]);

  React.useEffect(() => {
    if (!props.user) {
      return;
    }

    if (tabIndex === 2 && !fetchedLjRecords) {
      fetch(`${NEW_API_URL}/demos/lj/${params.id}/released`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${props.jwt}`
        }
      }).then(async (response) => {
        if (response.status !== 200) {
          if (response.status === 423) {
            set_userBanned(true);
          }
        } else {
          set_ljRecords(await response.json());
        }
      }).catch((reason) => {
        console.error(reason);
      }).finally(() => {
        set_fetchedLjRecords(true);
      });
    }
  }, [tabIndex, props, params, fetchedLjRecords]);

  React.useEffect(() => {
    if (!props.user) {
      return;
    }

    if (tabIndex === 3 && !fetchedMaps) {
      fetch(`${NEW_API_URL}/maps/${params.id}/released`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${props.jwt}`
        }
      }).then(async (response) => {
        if (response.status !== 200) {
          if (response.status === 423) {
            set_userBanned(true);
          }
        } else {
          set_maps(await response.json());
        }
      }).catch((reason) => {
        console.error(reason);
      }).finally(() => {
        set_fetchedMaps(true);
      });
    }
  }, [tabIndex, props, params, fetchedMaps]);

  React.useEffect(() => {
    if (!props.user) {
      return;
    }

    if (tabIndex === 4 && !fetchedServerRecords) {
      set_downloading(true);

      fetch(`${NEW_API_URL}/server/records/of_player/${params.id}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${props.jwt}`
        }
      }).then(async (response) => {
        if (response.status !== 200) {
          if (response.status === 423) {
            set_userBanned(true);
          }
        } else {
          const data = await response.json();

          set_serverRecords(data.finished);
          set_unfinishedMaps(data.unfinished);
        }
      }).catch((reason) => {
        console.error(reason);
      }).finally(() => {
        set_fetchedServerRecords(true);
        set_downloading(false);
      });
    }
  }, [props.jwt, props.user, params.id, fetchedServerRecords, tabIndex]);

  const onNameSave = () => {
    if (!props.user || nameChanged) {
      return;
    }

    if (displayName === props.user.displayName) {
      return;
    }

    fetch(`${NEW_API_URL}/user/update/profile/display_name`, {
      method: "PATCH",
      body: displayName,
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {

      }
    }).finally(() => {
      set_nameChanged(true);
    });
  };

  const onNewPasswordSave = React.useCallback(() => {
    if (!userToDisplay || !props.user || !newPassword) {
      return;
    }

    if (props.user.steamID !== userToDisplay.steamID) {
      return;
    }

    fetch(`${NEW_API_URL}/user/update/profile/password`, {
      method: "PATCH",
      body: CryptoJS.MD5(newPassword).toString(),
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {

      }
    }).finally(() => {
      set_newPassword(null);
    });
  },
    [userToDisplay, props.user, props.jwt, newPassword]);

  const onWarnAdded = React.useCallback(() => {
    if (!userToDisplay || !props.user || !isAdmin(props.user)) {
      return;
    }

    fetch(`${NEW_API_URL}/user/update/warn`, {
      method: "POST",
      body: userToDisplay.steamID,
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {

      }
    }).finally(() => {
      set_warnedThisSession(true);
      set_warnConfirmationDialogOpen(false);

      set_userToDisplay(prev => {
        if (!prev) {
          return;
        }

        return {
          ...prev,
          warnings: prev.warnings + 1
        };
      });
    });
  }, [userToDisplay, props.user, props.jwt]);

  const removeDemos = React.useCallback(() => {
    console.log("not implemented");
    // fetch(`${API_URL}/demos/${userToDisplay?.steamID}`, {
    //   method: "DELETE",
    //   headers: {
    //     "Authorization": `Bearer ${props.jwt}`
    //   }
    // });
  }, [/* props.jwt, userToDisplay?.steamID */]);

  // const restoreDemo = React.useCallback(() => {

  // }, []);

  if (!props.jwt || !props.user || !userToDisplay) {
    return <div className={classes.wrapper}>
      {t("unauthorized")}
    </div>;
  }

  if (userBanned) {
    return <div className={classes.wrapper}>
      {t("banned")}
    </div>;
  }

  return <div className={classes.wrapper}>
    <Fade in timeout={500} unmountOnExit>
      <div>
        <Typography variant="overline">
          {t("profile.title")}
        </Typography>

        <Backdrop className={classes.backdrop} open={downloading}>
          <CircularProgress size="5rem" color="secondary" />
        </Backdrop>

        <Tabs
          value={tabIndex}
          onChange={(_, value) => set_tabIndex(value)}
          indicatorColor="primary"
          centered
        >
          <Tab label={t("profile.tabs.general")} />
          <Tab label={t("profile.tabs.kz_records")} />
          <Tab label={t("profile.tabs.lj_records")} />
          <Tab label={t("profile.tabs.maps")} />
          <Tab label={t("server_records.label")} />
        </Tabs>

        <TabPanel value={tabIndex} index={0}>
          <Fade in timeout={500} unmountOnExit>
            <div className={classes.generalInfoWrapper}>
              <div>
                <img src={userToDisplay.avatar.large} alt="avatar" />
              </div>

              <List>
                <ListItem>
                  <ListItemText
                    disableTypography
                    secondary={
                      <div style={{ display: "flex" }}>
                        <TextField
                          value={displayName}
                          onChange={(e) => {
                            set_displayName(e.target.value);
                          }}
                          autoComplete="off"
                          variant="outlined"
                          disabled={props.user.steamID !== userToDisplay.steamID || nameChanged}
                          fullWidth
                        />

                        {props.user.steamID === userToDisplay.steamID &&
                          <IconButton disabled={nameChanged || displayName === props.user.displayName} onClick={onNameSave}>
                            <SaveIcon />
                          </IconButton>
                        }
                      </div>
                    } primary={t("profile.name")} />
                </ListItem>

                {props.user.steamID === userToDisplay.steamID &&
                  <ListItem>
                    <ListItemText
                      disableTypography
                      secondary={
                        <div style={{ display: "flex" }}>
                          <TextField
                            value={newPassword ? newPassword : ""}
                            onChange={(e) => {
                              set_newPassword(e.target.value);
                            }}
                            type="password"
                            autoComplete="off"
                            variant="outlined"
                            fullWidth
                          />

                          <IconButton disabled={newPassword === null || newPassword.length === 0} onClick={onNewPasswordSave}>
                            <SaveIcon />
                          </IconButton>
                        </div>
                      } primary={t('login.newPassowrd')}
                    />
                  </ListItem>
                }

                <ListItem>
                  <ListItemText secondary={
                    <Link
                      href={`http://steamcommunity.com/profiles/${new SteamID(userToDisplay.steamID).getSteamID64()}`}
                      target="_blank"
                      className={classes.link}
                    >
                      {userToDisplay.steamID}
                    </Link>
                  }
                    primary="SteamID"
                  />
                </ListItem>

                <ListItem>
                  <ListItemText
                    secondary={userTypeToStr(userToDisplay.type)}
                    primary={t("profile.type")}
                  />
                </ListItem>

                {userToDisplay.warnings !== undefined &&
                  <ListItem>
                    {userToDisplay.warnings <= MaxAllowedWarnings &&
                      <ListItemText
                        secondary={userToDisplay.warnings}
                        primary={t("profile.warnings")}
                      />
                    }

                    {userToDisplay.warnings > MaxAllowedWarnings &&
                      <ListItemText
                        primary={t("banned")}
                      />
                    }
                  </ListItem>
                }
              </List>

              {userToDisplay.warnings <= MaxAllowedWarnings && displayName !== props.user.displayName && isAdmin(props.user) &&
                <div>
                  <Tooltip title={`${t("profile.warn_button.tooltip")}`}>
                    <Button
                      disabled={warnedThisSession}
                      variant="contained"
                      style={{
                        backgroundColor: warnedThisSession ? "currentcolor" : "red",
                        color: warnedThisSession ? "currentColor" : "white"
                      }}
                      onClick={() => set_warnConfirmationDialogOpen(true)}
                    >
                      {t("profile.warn_button.label")}
                    </Button>
                  </Tooltip>

                  <Dialog
                    onClose={() => set_warnConfirmationDialogOpen(false)}
                    open={warnConfirmationDialogOpen}
                  >
                    <div className={classes.confirmationWrapper}>
                      <Typography>
                        {userToDisplay.warnings + 1 > MaxAllowedWarnings
                          ? t("profile.warn_button.confirmation.ban", { who: userToDisplay.displayName })
                          : t("profile.warn_button.confirmation.warn", { who: userToDisplay.displayName })
                        }
                      </Typography>

                      <div className={classes.confirmationButtonWrapper}>
                        <Button variant="contained" color="secondary" onClick={onWarnAdded}>
                          <Typography>
                            {t("profile.warn_button.confirmation.yes")}
                          </Typography>
                        </Button>

                        <Button variant="outlined" style={{ marginLeft: "auto" }} onClick={() => set_warnConfirmationDialogOpen(false)}>
                          <Typography>
                            {t("profile.warn_button.confirmation.no")}
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  </Dialog>
                </div>
              }

              {isAdmin(props.user) && userToDisplay.warnings > MaxAllowedWarnings &&
                <div>
                  <Tooltip title={`${t("profile.remove_demos.tooltip")}`}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "red",
                        color: "white"
                      }}
                      onClick={removeDemos}
                    >
                      {t("profile.remove_demos.label")}
                    </Button>
                  </Tooltip>
                </div>
              }
            </div>
          </Fade>
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          <Fade in timeout={500} unmountOnExit>
            <div className={classes.gridWrapper}>
              <DemosTableComp
                user={props.user}
                jwt={props.jwt}
                set_downloading={set_downloading}
                demos={kzRecords}
                showPlayer={false}
                showActiveSwitch={true}
              />
            </div>
          </Fade>
        </TabPanel>

        <TabPanel value={tabIndex} index={2}>
          <Fade in timeout={500} unmountOnExit>
            <div className={classes.gridWrapper}>
              <LJTableComp
                jwt={props.jwt}
                demos={ljRecords}
                set_downloading={set_downloading}
              />
            </div>
          </Fade>
        </TabPanel>

        <TabPanel value={tabIndex} index={3}>
          <Fade in timeout={500} unmountOnExit>
            <MapTableComp
              maps={maps}
              jwt={props.jwt}
              set_downloading={set_downloading}
            />
          </Fade>
        </TabPanel>

        <TabPanel value={tabIndex} index={4}>
          <ServerRecordsTabs
            jwt={props.jwt}
            useCustomIndex
            records={serverRecords}
            unfinishedMaps={unfinishedMaps}
            defaultSortMode={SortMode.Map}
            set_loading={set_downloading}
          />
        </TabPanel>
      </div>
    </Fade>
  </div>;
};
