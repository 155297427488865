import { createStyles, makeStyles, Tab, Tabs, Theme } from "@material-ui/core";
import React from "react";
import TabPanel from "../TabPanelComp";
import ServerRecord from "./ServerRecord";
import { SortMode } from "./ServerRecordListComp";
import ServerRecordsAATabs from "./ServerRecordsAATabs";

export interface WeaponTableCompProps {
  jwt: string | null,
  records: ServerRecord[],
  isNoob: boolean,
  useCustomIndex?: boolean,
  defaultSortMode?: SortMode,
  set_loading: (state: boolean) => void,
  disableSorting?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(4)
    }
  })
);

export default function ServerRecordsRouteTabs(props: WeaponTableCompProps) {
  const classes = useStyles();

  const [tabIndex, set_tabIndex] = React.useState(0);

  const routes = React.useMemo(() => {
    return props.records
        .map(rec => rec.route)
        .filter((r, index, self) => self.indexOf(r) === index).sort((a, b) => a && b ? a.localeCompare(b) : -1);
  }, [props.records]);

  return <div>
    <div className={classes.tabsWrapper}>
      <Tabs
        value={tabIndex}
        onChange={(_, value) => set_tabIndex(value)}
        indicatorColor="primary"
      >
        {routes.map((route, index) => {
          const recordsCount = props.records.filter(rec => rec.route === route).length;
          let recordsCountStr = "";

          if (recordsCount !== 0) {
            recordsCountStr = ` (${recordsCount})`;
          }

          return <Tab label={`${route ? route : "normal"}${recordsCountStr}`} key={`${route}_tab_${index}`} />;
        })}
      </Tabs>
    </div>

    {routes.map((route, index) => <TabPanel value={tabIndex} index={index} key={`${route}_${index}_${props.isNoob}`} noMarginTop>
        <ServerRecordsAATabs
            defaultSortMode={props.defaultSortMode}
            jwt={props.jwt}
            useCustomIndex={props.useCustomIndex}
            set_loading={props.set_loading}
            disableSorting={props.disableSorting}
            records={props.records.filter(rec => rec.route === route)}
            isNoob={props.isNoob}
        />
    </TabPanel>)}
  </div>;
};
