import React from "react";
import { NEW_API_URL } from "../Config";
import ServerRecord from "./ServerRecord";
import ServerRecordsTabs from "./ServerRecordsTabs";

export interface ServerRecordsCompProps {
  jwt: string | null,
  map: string,
  set_loading: (state: boolean) => void,
  disableSorting?: boolean
};

export default function ServerRecordsComp(props: ServerRecordsCompProps) {
  const [records, set_records] = React.useState<ServerRecord[]>([]);

  React.useEffect(() => {
    fetch(`${NEW_API_URL}/server/records/of_map/${props.map}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
      } else {
        set_records(await response.json());
      }
    }).catch((reason) => {
      console.error(reason);
    });
  }, [props]);

  return <ServerRecordsTabs
    jwt={props.jwt}
    records={records}
    unfinishedMaps={[]}
    set_loading={props.set_loading}
    disableSorting={props.disableSorting}
  />;
};
