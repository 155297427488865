import { createStyles, Link, makeStyles, Theme, Tooltip, useTheme } from "@material-ui/core";
import React from "react";

import { NEW_API_URL } from "../Config";
import DemoWaitlistData from "./DemoWaitlistData";
import { formatMS } from "../Util";
import { useTranslation } from "react-i18next";

import { saveAs } from "file-saver";

export interface DemoTimeLinkCompProps {
  jwt: string | null,
  demo: DemoWaitlistData,
  set_downloading: (state: boolean) => void,
  on_unauthorized: () => void,
  useColor?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: "grabbing"
    },
  })
);

export default function DemoTimeLinkComp(props: DemoTimeLinkCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const {t} = useTranslation();

  const onTimeLinkClick = React.useCallback(() => {
    if (! props.jwt) {
      props.on_unauthorized && props.on_unauthorized();

      return;
    }

    props.set_downloading(true);

    fetch(`${NEW_API_URL}/demos/kz/${props.demo.demoMD5}/download`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const data = await fetch(await response.text());

        saveAs(await data.blob(), `${props.demo.map}_${props.demo.uploader!.displayName}_${formatMS(props.demo.time, false)}.zip`);
      }
    }).finally(() => {
      props.set_downloading(false);
    });
  }, [props]);

  return <Tooltip title={`${t("demo.download")}`}>
    <Link
      className={classes.link}
      onClick={() => onTimeLinkClick()}
      style={{
        color: props.useColor ? theme.palette.secondary.dark : "white",
        fontWeight: props.useColor ? 600 : "normal"
      }}
    >
      {formatMS(props.demo.time)}
    </Link>
  </Tooltip>;
};
