import { Accordion, AccordionDetails, AccordionSummary, Button, createStyles, Dialog, Link, makeStyles, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { NEW_API_URL } from "../Config";
import CustomPaper from "../CustomPaper";
import DemoNewsItemComp from "./DemoNewsItemComp";
import LJNewsItemComp from "./LJNewsItemComp";
import { ChallengeNewsItem, DemoReleaseNewsItem, LJReleaseNewsItem, MapsNewsItem, NewsItem } from "./NewsItem";
import RegularNewsItemComp from "./RegularNewsItemComp";
import User from "../user/User";
import { formatDate, isAdmin } from "../Util";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NewsCommentsWrapperComp from "./NewsCommentsWrapperComp";
import NewsType from "./NewsType";
import { useTranslation } from "react-i18next";
import ChallengeItemComp from "./ChallengeItemComp";
import MapNewsItemComp from "./MapNewsItemComp";
import AuthorLink from "../user/AuthorLink";
import { Link as RLink } from "react-router-dom";

export interface NewsItemCompProps {
  user: User | undefined,
  jwt: string | null,
  item: NewsItem,
  onDelete?: () => void,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      marginBottom: theme.spacing(4)
    },
    commentsLink: {
      marginLeft: "auto",
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(1)
    },
    sectionTitle: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(1)
    },
    sectionTitleDate: {
      padding: theme.spacing(2),
      paddingLeft: 0
    },
    authorLink: {
      color: "white",
      textDecoration: "none"
    },
    deleteLink: {
      marginLeft: "auto",
      padding: theme.spacing(2),
      color: "red"
    },
    confirmationWrapper: {
      margin: theme.spacing(3)
    },
    confirmationButtonWrapper: {
      paddingTop: theme.spacing(3),
      display: "flex"
    },
    accordion: {
      backgroundColor: "inherit",
    },
    accordionSummary: {
      display: "flex",
    },
    accordionSummaryContent: {
      display: "flex",
      justifyContent: "flex-end"
    }
  })
);

export default function NewsItemComp(props: NewsItemCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const { user, jwt, item, onDelete } = props;

  const [deleted, set_deleted] = React.useState(false);

  const [deleteConfirmationDialogOpen, set_deleteConfirmationDialogOpen] = React.useState(false);
  const [commentsExpanded, set_commentsExpanded] = React.useState(false);

  const closeConfirmationDialog = React.useCallback(() => {
    set_deleteConfirmationDialogOpen(false);
  }, []);

  const onDeleteNews = React.useCallback(() => {
    if (!user || !isAdmin(user)) {
      return;
    }

    fetch(`${NEW_API_URL}/news?newsId=${item.id}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_deleted(true);
        onDelete && onDelete();
      }
    }).finally(() => {
      closeConfirmationDialog();
    });
  }, [closeConfirmationDialog, item.id, jwt, onDelete, user]);

  const onDeleteClicked = React.useCallback(() => {
    if (!user || !isAdmin(user)) {
      return;
    }

    set_deleteConfirmationDialogOpen(true);
  }, [user]);

  const isDemoRelease = React.useCallback((item: NewsItem): item is DemoReleaseNewsItem => item.type === NewsType.Demo, []);
  const isLJRelease = React.useCallback((item: NewsItem): item is LJReleaseNewsItem => item.type === NewsType.LJ, []);
  const isChallenge = React.useCallback((item: NewsItem): item is ChallengeNewsItem => item.type === NewsType.Challenge || item.type === NewsType.ChallengeRoundEnd, []);
  const isMapRelease = React.useCallback((item: NewsItem): item is MapsNewsItem => item.type === NewsType.Map, []);

  if (deleted) {
    return null;
  }

  return <div className={classes.wrapper}>
    <CustomPaper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Tooltip title={formatDate(item.publishedDate)}>
          <Typography variant="overline" className={classes.sectionTitle}>
            <RLink
              style={{
                textDecoration: "none",
                color: theme.palette.text.primary
              }}
              to={`/news/${item.id}`}>
              {`#${item.id} - `}
              <b>{item.title}</b>
            </RLink>

            {t("news.posted_by")}
          </Typography>
        </Tooltip>

        <AuthorLink author={item.author} steam_id={item.author.steamID} avatar small />

        {user && isAdmin(user) &&
          <Link href="#" className={classes.deleteLink} onClick={onDeleteClicked}>
            <Typography variant="overline" color="inherit">
              {t("news.delete.label")}
            </Typography>
          </Link>
        }
      </div>

      <Dialog
        onClose={closeConfirmationDialog}
        open={deleteConfirmationDialogOpen}
      >
        <div className={classes.confirmationWrapper}>
          <Typography>
            {t("news.delete.confirmation_msg")} <i>'{item.title}'</i> ?
          </Typography>

          <div className={classes.confirmationButtonWrapper}>
            <Button variant="contained" color="secondary" onClick={onDeleteNews}>
              <Typography>
                {t("news.delete.yes")}
              </Typography>
            </Button>

            <Button variant="outlined" style={{ marginLeft: "auto" }} onClick={closeConfirmationDialog}>
              <Typography>
                {t("news.delete.no")}
              </Typography>
            </Button>
          </div>
        </div>
      </Dialog>

      {
        isDemoRelease(item) ?
          <DemoNewsItemComp item={item} jwt={jwt} /> :
          isLJRelease(item) ?
            <LJNewsItemComp item={item} jwt={jwt} /> :
            isChallenge(item) ?
              <ChallengeItemComp item={item} jwt={jwt} /> :
              isMapRelease(item) ?
                <MapNewsItemComp item={item} jwt={jwt} /> :
                <RegularNewsItemComp jwt={jwt} content={item.content} />
      }

      <div style={{ display: "flex" }}>
        <Accordion
          className={classes.accordion}
          style={{ width: "100%" }}
          expanded={commentsExpanded}
          onChange={() => set_commentsExpanded(!commentsExpanded)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.accordionSummary} />}
            classes={{
              content: classes.accordionSummaryContent
            }}
          >
            <Typography variant="overline" color="textPrimary">
              {item.commentsCount !== 1 ? t("news.comment.label_multiple", { count: item.commentsCount }) : t("news.comment.label")}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            {commentsExpanded &&
              <NewsCommentsWrapperComp
                newsId={item.id}
                jwt={jwt}
                user={user}
              />
            }
          </AccordionDetails>
        </Accordion>
      </div>
    </CustomPaper>
  </div>;
};
