enum Weapon
{
  USP_OR_KNIFE = -2,
  SCOUT = 3,
  FAMAS = 15,
  AWP = 18,
  USP = 16,
  M249 = 20,
  M4A1 = 22,
  KNIFE = 29,

  AK47,
  SG552,
  P90,

  SHIELD,
  UNKNOWN = -1,
};

export const RecordableWeapons = [
  Weapon.USP_OR_KNIFE,
  Weapon.AWP,
  Weapon.M249,
  Weapon.M4A1,
  Weapon.FAMAS,
  Weapon.SCOUT,
];

export const toWeapon = (id: number) => {
  if (id > Weapon.KNIFE) {

    return Weapon.UNKNOWN;
  }

  switch (id) {
    case 16:
    case 29:
    case -2: return Weapon.USP_OR_KNIFE;

    case 3: return Weapon.SCOUT;

    case 15: return Weapon.FAMAS;
    case 18: return Weapon.AWP;
    case 20: return Weapon.M249;
    case 22: return Weapon.M4A1;

    default:
      console.error(`Unknown weapon ${id} !`);
      return Weapon.UNKNOWN;

    case -1:
      return Weapon.UNKNOWN;
  }
};

export const wpnToString = (wpn: Weapon) => {
  switch (wpn) {
    case Weapon.KNIFE:
    case Weapon.USP:
    case Weapon.USP_OR_KNIFE: return "USP/Knife";

    case Weapon.AWP: return "AWP";

    case Weapon.SCOUT: return "Scout";

    case Weapon.FAMAS: return "Famas";

    case Weapon.M4A1: return "M4A1";

    case Weapon.M249: return "M249";


    case Weapon.AK47: return "AK47";
    case Weapon.SG552: return "SG552";
    case Weapon.P90: return "P90";

    case Weapon.SHIELD: return "Shield";

    default:
    case Weapon.UNKNOWN:
      return "UNKNOWN";
  }
};

export const strToWpn = (str: string) => {
  switch (str.toLowerCase()) {
    case "usp":
    case "knife":
    case "deagle":
      return Weapon.USP_OR_KNIFE;
    
    case "awp":
      return Weapon.AWP;

    case "scout":
      return Weapon.SCOUT;

    case "famas":
      return Weapon.FAMAS;

    case "m4a1":
      return Weapon.M4A1;

    case "m249":
      return Weapon.M249;

    case "ak47":
      return Weapon.AK47;

    case "sg552":
      return Weapon.SG552;

    case "p90":
      return Weapon.P90;

    case "shield":
      return Weapon.SHIELD;
  }

  return Weapon.UNKNOWN;
};

export default Weapon;
