import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Backdrop, Button, CircularProgress, createStyles, Dialog, Fade, FormControlLabel, lighten, Link, makeStyles, TextField, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BlockIcon from '@material-ui/icons/Block';
import CachedIcon from '@material-ui/icons/Cached';

import { useHistory } from "react-router-dom";

import { saveAs } from "file-saver";

import { API_URL, NEW_API_URL } from "../../Config";
import DemoWaitlistStatus from "../DemoWaitlistStatus";
import LJDemoData from "../LJDemoData";

import User from "../../user/User";
import UserType from "../../user/UserType";

import { formatDate, isAdmin } from "../../Util";
import { techniqueToStr } from "../LJTechnique";
import ViewDemoLink from "../../viewdemo/ViewDemoLink";
import { useTranslation } from "react-i18next";
import Weapon, { wpnToString } from "../Weapon";
import AuthorLink from "../../user/AuthorLink";

export interface LJDemosWaitlistCompProps {
  reloadWaitlistCount: () => void,
  jwt: string | null,
  user: User | undefined
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    releaseWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(4),
      paddingTop: theme.spacing(4),
      justifyContent: "center",
      alignItems: "center"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
  })
);

const useItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    statusIcon: {
      paddingLeft: "10px",
      paddingRight: "20px"
    },
    noUpperCase: {
      textTransform: "none"
    },
    confirmationWrapper: {
      margin: theme.spacing(3)
    },
    confirmationButtonWrapper: {
      paddingTop: theme.spacing(3),
      display: "flex"
    },
    wrapperDiv: {
      padding: theme.spacing(2)
    },
    rejectBtn: {
      marginLeft: "auto",
      marginRight: theme.spacing(1)
    },
    actionButtonsWrapper: {
      width: "100%",
      display: "flex",
    },
    viewDemoLinkWrapper: {
      paddingLeft: theme.spacing(1),
      gap: theme.spacing(1),
      display: "flex"
    }
  })
);

interface LJDemoWaitlistItemProps {
  jwt: string | null,
  user: User | undefined,
  demoData: LJDemoData,
  expandedName: string | undefined,
  set_expandedName: (name: string | undefined) => void,
  reload: () => void,
};

function LJDemoWaitlistItem(props: LJDemoWaitlistItemProps) {
  const classes = useItemStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const { jwt, user, demoData, expandedName, set_expandedName, reload } = props;

  const [localStatus, set_localStatus] = React.useState(demoData.status);
  const [deleteConfirmationDialogOpen, set_deleteConfirmationDialogOpen] = React.useState(false);
  const [rejectionMessage, set_rejectionMessage] = React.useState<string>(demoData.reason ? demoData.reason : "");

  const handleChange = React.useCallback((status: DemoWaitlistStatus) => {
    if (!user || !isAdmin(user)) {
      return;
    }

    fetch(`${NEW_API_URL}/waitlist/lj/${demoData.demoMD5}/status`, {
      method: "PATCH",
      body: JSON.stringify({
        status: status,
        reason: rejectionMessage
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_localStatus(status);

        if (status === DemoWaitlistStatus.Aproved) {
          set_rejectionMessage("");
        }

        set_expandedName(undefined);
        reload();
      }
    });
  }, [rejectionMessage, demoData.demoMD5, jwt, user, reload, set_expandedName]);

  const onDownload = React.useCallback(() => {
    fetch(`${NEW_API_URL}/demos/lj/${demoData.demoMD5}/download`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const url = await response.text();
        const data = await fetch(url);

        saveAs(await data.blob(), `${demoData.block}_${techniqueToStr(demoData.technique).toLowerCase()}_${demoData.uploader!.displayName}.zip`);
      }
    });
  }, [demoData.block, demoData.demoMD5, demoData.technique, demoData.uploader, jwt]);

  const onAproval = React.useCallback(() => {
    handleChange(DemoWaitlistStatus.Aproved);
  }, [handleChange]);

  const onReject = React.useCallback(() => {
    handleChange(DemoWaitlistStatus.Rejected);
  }, [handleChange]);

  const onDelete = React.useCallback(() => {
    if (!user || user.type !== UserType.Root) {
      return;
    }

    fetch(`${NEW_API_URL}/waitlist/lj?demoMD5=${demoData.demoMD5}`, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_expandedName(undefined);
        reload();
      }
    });
  }, [jwt, user, reload, demoData.demoMD5, set_expandedName]);

  const retryStatsExtraction = React.useCallback(() => {
    if (!user || !isAdmin(user)) {
      return;
    }

    fetch(`${API_URL}/demosWaitlist/lj/retryStats/${demoData.demoMD5}`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        reload();
      }
    });
  }, [demoData.demoMD5, jwt, reload, user]);

  return <Accordion
    style={{
      backgroundColor: localStatus === DemoWaitlistStatus.Rejected ? "rgb(100, 50, 50)" : theme.palette.background.default,
      marginTop: theme.spacing(3)
    }}
    square
    expanded={user && isAdmin(user) && demoData.demoMD5 === expandedName}
    onChange={() => user && isAdmin(user) && set_expandedName(expandedName !== demoData.demoMD5 ? demoData.demoMD5 : undefined)}
  >
    <AccordionSummary
      expandIcon={user && isAdmin(user) ? <ExpandMoreIcon /> : null}
    >
      <FormControlLabel
        onClick={(event) => event.stopPropagation()}
        onFocus={(event) => event.stopPropagation()}
        control={
          localStatus === DemoWaitlistStatus.Aproved ?
            <DoneIcon style={{ color: "rgb(0, 255, 0)" }} fontSize="large" className={classes.statusIcon} /> :
            localStatus === DemoWaitlistStatus.Rejected ?
              <BlockIcon style={{ color: "rgb(255, 0, 0)" }} fontSize="large" className={classes.statusIcon} /> :
              <HelpOutlineIcon fontSize="large" className={classes.statusIcon} />
        }
        label={
          <>
            <Tooltip title="Download demo">
              <div style={{ display: "flex", gap: theme.spacing(1) }}>
                <Typography>
                  <Link
                    href="#"
                    onClick={(e) => {
                      onDownload();

                      e.preventDefault();
                    }}
                    color="textPrimary"
                  >
                    <b>{demoData.block} {techniqueToStr(demoData.technique)}</b> - {demoData.stats ?
                      <>
                        <i>Distance {demoData.stats.distance} | Pre {demoData.stats.preStrafe} | Strafes {demoData.stats.strafes.length} | Sync {demoData.stats.sync ? demoData.stats.sync.toFixed(3) : "n/a"}</i>
                      </> : "Stats nu au putut fi extrase"}
                  </Link>
                </Typography>

                {` - `}

                <Typography
                  style={{
                    color: demoData.weapon === Weapon.USP_OR_KNIFE ?
                      theme.palette.text.primary :
                      lighten(theme.palette.secondary.dark, .4)
                  }}
                >
                  {wpnToString(demoData.weapon)}
                </Typography>
              </div>
            </Tooltip>

            <Typography
              variant="overline"
              className={classes.noUpperCase}
              style={{
                display: "flex",
                gap: theme.spacing(1),
                alignItems: "center"
              }}
            >
              {t("demo.uploaded_on")}{formatDate(demoData.uploadDate)}

              {props.user && isAdmin(props.user) && demoData.uploader && <> {t("demo.by")} {<AuthorLink avatar author={demoData.uploader} steam_id={demoData.uploader.steamID} />}</>}
            </Typography>

            <div className={classes.viewDemoLinkWrapper}>
              <ViewDemoLink
                jwt={jwt}
                demo={demoData}
                lj
                on_click={() => {
                  props.set_expandedName(props.demoData.demoMD5)
                }}
              />

              {props.user && isAdmin(props.user) &&
                <Tooltip title="Retry stats extraction">
                  <CachedIcon onClick={retryStatsExtraction} />
                </Tooltip>
              }
            </div>
          </>
        }
      />
    </AccordionSummary>

    {user && isAdmin(user) &&
      <AccordionDetails>
        <div className={classes.actionButtonsWrapper}>
          <Button
            variant="contained"
            color="primary"
            disabled={localStatus === DemoWaitlistStatus.Aproved}
            onClick={onAproval}
          >
            {t("demo.aprove")}
          </Button>

          {user && user.type === UserType.Root && <>
            <Button
              className={classes.rejectBtn}
              variant="outlined"
              color="secondary"
              onClick={() => set_deleteConfirmationDialogOpen(true)}
            >
              {t("demo.delete.button")}
            </Button>

            <Dialog
              onClose={() => set_deleteConfirmationDialogOpen(false)}
              open={deleteConfirmationDialogOpen}
            >
              <div className={classes.confirmationWrapper}>
                <Typography>
                  {t("demo.delete.confirmation_msg")} <i>'{demoData.demoMD5}'</i> ?
                </Typography>

                <div className={classes.confirmationButtonWrapper}>
                  <Button variant="contained" color="secondary" onClick={onDelete}>
                    <Typography>
                      {t("demo.delete.yes")}
                    </Typography>
                  </Button>

                  <Button variant="outlined" style={{ marginLeft: "auto" }} onClick={() => set_deleteConfirmationDialogOpen(false)}>
                    <Typography>
                      {t("demo.delete.no")}
                    </Typography>
                  </Button>
                </div>
              </div>
            </Dialog>
          </>
          }

          <Button
            className={classes.rejectBtn}
            variant="contained"
            color="secondary"
            disabled={localStatus === DemoWaitlistStatus.Rejected || rejectionMessage.length === 0}
            onClick={onReject}
          >
            {t("demo.reject")}
          </Button>
        </div>

        <TextField
          label="Motiv"
          variant="outlined"
          fullWidth
          value={rejectionMessage}
          onChange={(e) => set_rejectionMessage(e.target.value)}
        />
      </AccordionDetails>
    }
  </Accordion>;
}

export default function LJDemosWaitlist(props: LJDemosWaitlistCompProps) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { reloadWaitlistCount, jwt, user } = props;

  const [waitlistData, set_waitlistData] = React.useState<LJDemoData[]>([]);
  const [expanded, set_expanded] = React.useState<string>();
  const [releaseText, set_releaseText] = React.useState("");
  const [loading, set_loading] = React.useState(true);

  const reloadWaitList = React.useCallback(() => {
    fetch(`${NEW_API_URL}/waitlist/lj/list`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const data = await response.json() as LJDemoData[];

        set_waitlistData(data);
      }
    }).finally(() => {
      reloadWaitlistCount();
      set_loading(false);
    });
  }, [jwt, reloadWaitlistCount]);

  const onMakeRelease = React.useCallback(() => {
    if (!user || !isAdmin(user)) {
      return;
    }

    fetch(`${NEW_API_URL}/news/lj/release`, {
      method: "POST",
      body: JSON.stringify({
        demos: waitlistData,
        description: releaseText
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        history.push('/');
      }
    });
  }, [history, jwt, releaseText, user, waitlistData]);

  React.useEffect(() => {
    reloadWaitList();
  }, [reloadWaitList]);

  if (!jwt) {
    return <div className={classes.wrapperDiv}>
      {t("unauthorized")}
    </div>;
  }

  const allDemosChecked = () => {
    return waitlistData.length !== 0 && waitlistData.findIndex((demoWlData: LJDemoData) => demoWlData.status === DemoWaitlistStatus.None) === -1;
  };

  return <div className={classes.wrapperDiv}>
    <Typography variant="overline">
      {t("demo.waiting.content", { total: waitlistData.length })}
      {user && isAdmin(user) && <>{t("demo.accepted")} {waitlistData.filter(a => a.status === DemoWaitlistStatus.Aproved).length}</>}
    </Typography>

    {waitlistData.length === 0 &&
      <Fade in timeout={500} unmountOnExit>
        <Typography>
          0 demos :(
        </Typography>
      </Fade>
    }

    {waitlistData.sort((a, b) => a.status === DemoWaitlistStatus.None && b.status !== DemoWaitlistStatus.None ? -1 : 1).map((demo) =>
      <Fade in timeout={500} unmountOnExit key={demo.demoMD5}>
        <LJDemoWaitlistItem
          jwt={jwt}
          user={user}
          demoData={demo}
          expandedName={expanded}
          set_expandedName={set_expanded}
          reload={reloadWaitList}
        />
      </Fade>
    )}

    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress size="5rem" color="secondary" />
    </Backdrop>

    {user && isAdmin(user) && allDemosChecked() &&
      <Fade in timeout={500} unmountOnExit>
        <div className={classes.releaseWrapper}>
          <TextField
            label={`${t("demo.release.label")}`}
            fullWidth
            multiline
            minRows={5}
            variant="outlined"
            value={releaseText}
            onChange={(e) => set_releaseText(e.target.value)}
          />

          <Button color="primary" variant="contained" disabled={releaseText.length === 0} onClick={onMakeRelease}>
            Release !
          </Button>
        </div>
      </Fade>
    }
  </div>;
};
