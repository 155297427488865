import React from "react";
import { Accordion, AccordionSummary, Backdrop, CircularProgress, createStyles, Dialog, Fade, List, ListItem, ListItemText, makeStyles, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AuthorLink from "../user/AuthorLink";

import DemoWaitlistStatus from "../demo/DemoWaitlistStatus";
import LJBlockLinkComp from "../demo/LJBlockLinkComp";
import LJDemoData from "../demo/LJDemoData";
import { techniqueToStr } from "../demo/LJTechnique";

import { LJReleaseNewsItem } from "./NewsItem";
import RegularNewsItemComp from "./RegularNewsItemComp";

import { groupBy } from "../Util";

import ViewDemoLink from "../viewdemo/ViewDemoLink";
import { useTranslation } from "react-i18next";
import Weapon, { wpnToString } from "../demo/Weapon";
import User from "../user/User";

export interface LJNewsItemCompProps {
  jwt: string | null,
  item: LJReleaseNewsItem
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ljDemoReleaseBannerImg: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      pointerEvents: "none",
      height: "auto",
      width: "95%",
      maxWidth: "60vw"
    },
    ljWrapper: {
      display: "flex",
      gap: theme.spacing(1)
    },
    rejectedDemosWrapper: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
    dialogWrapper: {
      padding: theme.spacing(4)
    },
  })
);

export default function LJNewsItemComp(props: LJNewsItemCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const players = React.useMemo(() => {
    const players: User[] = [];

    for (const demo of props.item.demos) {
      if (!demo.uploader) {
        continue;
      }

      const uploader = demo.uploader;

      if (players.findIndex(item => item.steamID === uploader.steamID) === -1) {
        players.push(uploader);
      }
    }

    return players.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }, [props.item]);

  const demosGroupedByPlayer = groupBy(props.item.demos, (demo: LJDemoData) => demo.uploader!.displayName);
  const sortedByPlayer = Array.from(demosGroupedByPlayer.entries()).sort((a, b) => a[0].localeCompare(b[0]));
  const [downloading, set_downloading] = React.useState(false);
  const [mustLoginDialogVisible, set_mustLoginDialogVisible] = React.useState(false);
  const [expanded, set_expanded] = React.useState(true);

  const formatPreviousRec = React.useCallback((demo: LJDemoData, ljRelease: LJReleaseNewsItem) => {
    const prevRecord = ljRelease.beatDemos.find(d => d.technique === demo.technique && d.weapon === demo.weapon);

    if (!prevRecord) {
      return null;
    }

    return <i><Typography variant="caption">({prevRecord.block})</Typography></i>;
  }, []);

  return <div>
    <img src="/ljReleaseBanner2.jpg" alt="romanian jumpers lj demo release banner" className={classes.ljDemoReleaseBannerImg} />

    <Dialog
      onClose={() => set_mustLoginDialogVisible(false)}
      open={mustLoginDialogVisible}
    >
      <div className={classes.dialogWrapper}>
        <Typography>
          {t("news.demo.download_view_login_required")}
        </Typography>
      </div>
    </Dialog>

    <Backdrop className={classes.backdrop} open={downloading}>
      <CircularProgress size="5rem" color="secondary" />
    </Backdrop>

    <Accordion
      style={{
        backgroundColor: "transparent"
      }}

      square
      expanded={expanded}
      onChange={() => set_expanded(old => !old)}
    >
      <AccordionSummary expandIcon={
        <Tooltip title={`${t("news.demo.collapse_demos_list")}`}>
          <ExpandMoreIcon />
        </Tooltip>
      } />
      <List>
        {sortedByPlayer.map(([_, demos], index) => {
          const aprovedDemos = demos.filter(d => d.status === DemoWaitlistStatus.Released || d.status === DemoWaitlistStatus.Beat).sort((l, r) => l.map.localeCompare(r.map));

          if (aprovedDemos.length === 0) {
            return null;
          }

          const uploader = aprovedDemos[0].uploader;

          if (!uploader) {
            return null;
          }

          const currentPlayer = players.find(p => p.steamID === uploader.steamID);

          if (!currentPlayer) {
            return null;
          }

          return <Fade in timeout={500 + 200 * index} unmountOnExit key={index}>
            <ListItem>
              <ListItemText
                disableTypography
                primary={<>
                  <AuthorLink author={currentPlayer} steam_id={currentPlayer.steamID} avatar />
                </>}
                secondary={
                  <List>
                    {aprovedDemos.sort((a, b) => a.technique < b.technique ? -1 : 1).map((demo, index2) =>
                      <Fade in timeout={500 + 200 * index * index2} unmountOnExit key={`${index}_${index2}`}>
                        <ListItem>
                          <ListItemText primary={<div className={classes.ljWrapper}>
                            {techniqueToStr(demo.technique)} - <LJBlockLinkComp
                              jwt={props.jwt}
                              demo={demo}
                              set_downloading={set_downloading}
                              on_unauthorized={() => set_mustLoginDialogVisible(true)}
                            />

                            <i>
                              <div
                                style={{
                                  display: "flex",
                                  gap: theme.spacing(.5)
                                }}
                              >
                                - Distance {demo.stats?.distance.toFixed(3)} | Pre {demo.stats?.preStrafe.toFixed(3)} | {demo.stats?.strafes.length} strafes
                                {demo.weapon !== Weapon.USP_OR_KNIFE ? <> | <Typography style={{ color: "yellow" }}>{wpnToString(demo.weapon)}</Typography></> : null}
                              </div>
                            </i>

                            <ViewDemoLink jwt={props.jwt} demo={demo} lj />

                            {formatPreviousRec(demo, props.item)}
                          </div>} />
                        </ListItem>
                      </Fade>
                    )}
                  </List>
                } />
            </ListItem>
          </Fade>;
        })}
      </List>

      {props.item.demos.filter(d => d.status === DemoWaitlistStatus.Rejected).length !== 0 &&
        <div className={classes.rejectedDemosWrapper}>
          <Typography>
            {t("news.demo.rejected")}
          </Typography>

          <List dense>
            {sortedByPlayer.map(([_, demos], index) => {
              const rejectedDemos = demos.filter(d => d.status === DemoWaitlistStatus.Rejected).sort((l, r) => l.map.localeCompare(r.map));

              if (rejectedDemos.length === 0) {
                return null;
              }

              const uploader = rejectedDemos[0].uploader;

              if (!uploader) {
                return null;
              }

              const currentPlayer = players.find(p => p.steamID === uploader.steamID);

              if (!currentPlayer) {
                return null;
              }

              return rejectedDemos.map((demo, index2) =>
                <Fade in timeout={500 + 200 * (index * index2)} unmountOnExit key={`${index}_${index2}`}>
                  <ListItem>
                    <ListItemText
                      primary={<>
                        {techniqueToStr(demo.technique)} {demo.block} {currentPlayer.displayName} - <i>{demo.reason}</i>
                      </>}
                    />
                  </ListItem>
                </Fade>
              );
            })}
          </List>
        </div>
      }
    </Accordion>

    {props.item.content.length !== 0 && <RegularNewsItemComp jwt={props.jwt} content={props.item.content} />}
  </div>;
};
