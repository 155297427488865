import { Button, createStyles, makeStyles, Theme, Tooltip, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import User from "../user/User";
import AssessmentIcon from '@material-ui/icons/Assessment';
import { isAdmin } from "../Util";
import DemoWaitlistData from "../demo/DemoWaitlistData";
import React from "react";

export interface AnalyzeLinkProps {
  user: User | undefined,
  demo: DemoWaitlistData,
  button?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    analysisLink: {
      textDecoration: "none",
      cursor: "grabbing",
      transition: "color 0.5s",
      transitionTimingFunction: "ease-in-out",
    },
    analysisBtn: {
      height: "100%"
    },
  })
);

export default function AnalyzeLink(props: AnalyzeLinkProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [hovering, set_hovering] = React.useState(false);
  const theme = useTheme();

  if (!props.user || !isAdmin(props.user)) {
    return null;
  }

  return <Tooltip title={`${t("demo.analysis.title")}`}>
    <Link
      to={`/analysis/${props.demo.demoMD5}/${props.demo.map}/${props.demo.uploader?.displayName}/${props.demo.time}`}
      className={classes.analysisLink}
      style={{
        color: hovering ? theme.palette.error.dark : theme.palette.text.primary
      }}
      onMouseEnter={() => set_hovering(true)}
      onMouseLeave={() => set_hovering(false)}
    >
      {props.button
        ? <Button
          className={classes.analysisBtn}
          variant="outlined"
        >
          {t("demo.analyze.single")}
        </Button>
        : <AssessmentIcon />
      }
    </Link>
  </Tooltip>;
};
