import { createStyles, Link, List, ListItem, ListItemText, makeStyles, TextField, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import LJDemoData from "./LJDemoData";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { formatDate } from "../Util";
import { API_URL } from "../Config";
import { techniqueToStr } from "./LJTechnique";
import ViewDemoLink from "../viewdemo/ViewDemoLink";
import { useTranslation } from "react-i18next";
import { wpnToString } from "./Weapon";

import { saveAs } from "file-saver";

export interface LJTableCompProps {
  jwt: string | null,
  demos: LJDemoData[],
  set_downloading: (state: boolean) => void,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsWrapper: {
      display: "grid",
      alignItems: "baseline",
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr"
    },
    itemWrapper: {
      display: "flex",
      justifyContent: "center"
    },
    link: {
      color: "white",
      cursor: "grabbing"
    },
  })
);

export default function LJTableComp(props: LJTableCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const {t} = useTranslation();

  const {jwt, demos, set_downloading} = props;

  const [sortField, set_sortField] = React.useState("technique");
  const [sortAscending, set_sortAscending] = React.useState(true);
  const [filterText, set_filterText] = React.useState("");

  const sortFunc = React.useCallback((a: LJDemoData, b: LJDemoData) => {
    const factor = (sortAscending ? 1 : -1);

    if (sortField === "block") {
      return (a.block < b.block ? -1 : 1) * factor;
    }

    if (sortField === "distance") {
      return (a.stats && b.stats && a.stats.distance < b.stats.distance ? -1 : 1) * factor;
    }

    if (sortField === "maxSpeed") {
      return (a.stats && b.stats && a.stats.maxSpeed < b.stats.maxSpeed ? -1 : 1) * factor;
    }

    if (sortField === "strafes") {
      return (a.stats && b.stats && a.stats.strafes < b.stats.strafes ? -1 : 1) * factor;
    }

    if (sortField === "sync") {
      return (a.stats && b.stats && a.stats.sync < b.stats.sync ? -1 : 1) * factor;
    }

    if (sortField === "weapon") {
      return wpnToString(a.weapon).toLowerCase().localeCompare(wpnToString(b.weapon).toLowerCase()) * factor;
    }

    return techniqueToStr(a.technique).localeCompare(techniqueToStr(b.technique)) * factor;
  }, [sortField, sortAscending]);

  const getSortingIcon = React.useCallback((field: string) => {
    if (field !== sortField) {
      return null;
    }

    if (sortAscending) {
      return <ArrowUpwardIcon/>;
    }

    return <ArrowDownwardIcon/>;
  }, [sortAscending, sortField]);

  const filter = React.useCallback((a: LJDemoData) => {
    if (filterText.length === 0) {
      return true;
    }

    const filters = filterText.split(',');

    let matches = false;

    for (const localFilter of filters) {
      if (localFilter.length === 0) {
        continue;
      }

      if (techniqueToStr(a.technique).toLowerCase().indexOf(localFilter) !== -1) {
        matches = true;
        continue;
      } else if (a.block.toString().indexOf(localFilter) !== -1) {
        matches = true;
        continue;
      } else if (wpnToString(a.weapon).toLowerCase().indexOf(localFilter) !== -1) {
        matches = true;
        continue;
      } else {
        matches = false;
      }
    }

    return matches;
  }, [filterText]);

  const onLJLinkClick = React.useCallback((demo: LJDemoData) => {
    if (! jwt) {
      return;
    }

    set_downloading(true);

    fetch(`${API_URL}/demo/lj/${demo.demoMD5}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const data = await fetch(await response.text());

        saveAs(await data.blob(), `${demo.block}_${techniqueToStr(demo.technique).toLowerCase()}_${demo.uploader!.displayName}.zip`);
      }
    }).finally(() => {
      set_downloading(false);
    });
  }, [jwt, set_downloading]);

  const filteredDemos = demos.filter(filter);

  return <List>
    <TextField
      value={filterText}
      onChange={(e) => {
        set_filterText(e.target.value);
      }}
      autoComplete="off"
      variant="outlined"
      fullWidth
      label={`${t("demo.lj_table.filter")}`}
      style={{
        paddingBottom: theme.spacing(2)
      }}
    />

    {filteredDemos.length === 0 && t("demo.lj_table.no_demos")}

    {filteredDemos.length !== 0 &&
    <>
      <ListItem
        button
        style={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <ListItemText
          primary={
            <div
              className={classes.itemsWrapper}
            >
              {[
                ["technique", t("demo.lj_table.columns.technique")],
                ["block", t("demo.lj_table.columns.block")],
                ["distance", t("demo.lj_table.columns.distance")],
                ["maxSpeed", t("demo.lj_table.columns.maxSpeed")],
                ["strafes", t("demo.lj_table.columns.strafes")],
                ["sync", t("demo.lj_table.columns.sync")],
                ["weapon", t("demo.lj_table.columns.weapon")],
                ["date", t("demo.lj_table.columns.date")],
              ].map(([field, label], index) =>
                <div
                  key={index}
                  className={classes.itemWrapper}
                  onClick={() => {
                    set_sortField(field);
                    set_sortAscending(! sortAscending);
                  }}
                >
                  <Typography>
                    {label}
                  </Typography>

                  {getSortingIcon(field)}
                </div>
              )}
            </div>
          }
        />
      </ListItem>

      {filteredDemos.sort(sortFunc).map((demo, index) =>
        <ListItem
          button
          key={index}
          style={{
            height: theme.spacing(10)
          }}
        >
          <ListItemText
            disableTypography
            primary={
              <div
                className={classes.itemsWrapper}
              >
                <div className={classes.itemWrapper}>
                  <Typography>
                    {techniqueToStr(demo.technique)}
                  </Typography>
                </div>

                <div className={classes.itemWrapper} style={{display: "flex", gap: theme.spacing(2)}}>
                  <Link className={classes.link} onClick={() => onLJLinkClick(demo)}>
                    {demo.block}
                  </Link>

                  {<ViewDemoLink jwt={jwt} demo={demo} lj/>}
                </div>

                <div className={classes.itemWrapper}>
                  <Typography>
                    {demo.stats?.distance.toFixed(3)}
                  </Typography>
                </div>

                <div className={classes.itemWrapper}>
                  <Typography>
                    {demo.stats?.maxSpeed.toFixed(3)}
                  </Typography>
                </div>

                <div className={classes.itemWrapper}>
                  <Typography>
                    {demo.stats?.strafes.length}
                  </Typography>
                </div>

                <div className={classes.itemWrapper}>
                  <Typography>
                    {demo.stats?.sync.toFixed(3)}
                  </Typography>
                </div>

                <div className={classes.itemWrapper}>
                  <Typography>
                    {wpnToString(demo.weapon)}
                  </Typography>
                </div>

                <div className={classes.itemWrapper}>
                  <Typography>
                    {formatDate(demo.uploadDate)}
                  </Typography>
                </div>
              </div>
            }
          />
        </ListItem>
      )}
    </>
    }
  </List>;
};
