import React from "react";

import { Accordion, AccordionDetails, AccordionSummary, Button, createStyles, Fade, IconButton, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { useParams } from "react-router-dom";

import { NEW_API_URL } from "./Config";
import CustomPaper from "./CustomPaper";

import User from "./user/User";
import UserType from "./user/UserType";

import { NewsItem } from "./news/NewsItem";
import NewsItemComp from "./news/NewsItemComp";
import NewsType from "./news/NewsType";
import { useTranslation } from "react-i18next";
import LastServerRecordsComp from "./server/LastServerRecordsComp";
import { Skeleton } from "@material-ui/lab";

export interface HomeProps {
  reloadWaitlistCount: () => void,
  jwt: string | null,
  user: User | undefined
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddedWrapper: {
      padding: theme.spacing(2)
    },
    centered: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(3)
    },
    newNewsWrapper: {
      paddingBottom: theme.spacing(3)
    },
    accordion: {
      backgroundColor: "inherit"
    },
    newsNavWrapper: {
      paddingBottom: theme.spacing(3),
      display: "flex",
      justifyContent: "center"
    }
  })
);

export default function Home(props: HomeProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const params = useParams<{ id: string | undefined }>();

  const [newsItems, set_newsItems] = React.useState<NewsItem[]>([]);
  const [newsTitle, set_newsTitle] = React.useState("");
  const [newNewsContent, set_newNewsContent] = React.useState("");
  const [offset, set_offset] = React.useState(0);

  const NewsItemsPerPage = 1;

  const reloadNews = React.useCallback(() => {
    let headers = {};

    if (props.jwt) {
      headers = {
        "Authorization": `Bearer ${props.jwt}`
      };
    }

    if (params.id) {
      fetch(`${NEW_API_URL}/news?newsId=${Number.parseInt(params.id)}`, {
        method: "GET",
        headers: headers
      }).then(async (response) => {
        if (response.status !== 200) {
          // TODO error handling
        } else {
          set_newsItems([await response.json()]);
        }
      });
    } else {
      fetch(`${NEW_API_URL}/news?count=${NewsItemsPerPage}&offset=${offset}`, {
        method: "GET",
        headers: headers
      }).then(async (response) => {
        if (response.status !== 200) {
          // TODO error handling
        } else {
          set_newsItems((await response.json() as NewsItem[]).sort((a, b) => a.publishedDate > b.publishedDate ? -1 : 1));
        }
      });
    }
  }, [props.jwt, offset, params.id]);

  React.useEffect(() => {
    reloadNews();
  }, [reloadNews]);

  const onNewNewsSubmit = React.useCallback(() => {
    fetch(`${NEW_API_URL}/news`, {
      method: "POST",
      body: JSON.stringify({
        title: newsTitle,
        content: newNewsContent
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_newsItems(prev => {
          return [
            {
              content: newNewsContent,
              title: newsTitle,
              id: newsItems.length === 0 ? 1 : newsItems[0].id + 1,
              author: props.user as User,
              authorSteamID: props.user!.steamID,
              publishedDate: new Date(),
              type: NewsType.Regular,
              commentsCount: 0,
              challenge_id: null
            },
            ...prev
          ];
        });

        set_newNewsContent("");
        set_newsTitle("");
      }
    });
  }, [newNewsContent, newsItems, newsTitle, props.jwt, props.user]);

  return <Fade in timeout={500} unmountOnExit>
    <div>
      {props.jwt &&
        <LastServerRecordsComp
          jwt={props.jwt}
          set_loading={() => { }}
          user={props.user}
        />
      }

      {props.user && newsItems.length !== 0 && (props.user.type === UserType.Root || props.user?.type === UserType.Admin) &&
        <div className={classes.newNewsWrapper}>
          <CustomPaper>
            <div className={classes.paddedWrapper}>
              <Accordion className={classes.accordion}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {t("news.new.title")}
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <div className={classes.centered}>
                    <TextField
                      label={t("news.new.label")}
                      fullWidth
                      variant="outlined"
                      value={newsTitle}
                      onChange={(e) => set_newsTitle(e.target.value)}
                    />

                    <TextField
                      label={t("news.new.placeholder")}
                      fullWidth
                      multiline
                      minRows={5}
                      variant="outlined"
                      value={newNewsContent}
                      onChange={(e) => set_newNewsContent(e.target.value)}
                    />

                    <Button
                      color="primary"
                      variant="contained"
                      disabled={newNewsContent.length === 0 || newsTitle.length === 0}
                      onClick={onNewNewsSubmit}
                    >
                      {t("news.new.post")}
                    </Button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          </CustomPaper>
        </div>
      }

      <div>
        {newsItems.length === 0
          ? <CustomPaper>
            <Skeleton
              height="100vh"
              width="100%"
              animation="wave"
              variant="rect"
            />
          </CustomPaper>
          : newsItems.sort((a, b) => a.publishedDate > b.publishedDate ? -1 : 1).map((item) =>
            <NewsItemComp
              key={item.publishedDate.toString()}
              item={item}
              jwt={props.jwt}
              user={props.user}
              onDelete={() => props.reloadWaitlistCount()}
            />
          )
        }
      </div>

      {newsItems.length !== 0 &&
        <div className={classes.newsNavWrapper}>
          <IconButton
            disabled={offset === 0}
            onClick={() => set_offset(Math.max(0, offset - NewsItemsPerPage))}
          >
            <ArrowBackIosIcon />
          </IconButton>

          <IconButton
            disabled={newsItems.length !== NewsItemsPerPage}
            onClick={() => set_offset(offset + newsItems.length)}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      }
    </div>
  </Fade>;
};
