import {
  Accordion,
  AccordionSummary,
  Backdrop,
  Chip,
  CircularProgress,
  createStyles,
  List,
  ListItem,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import { MapsNewsItem } from "./NewsItem";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";
import MapLinkComp from "../demo/MapLinkComp";
import ViewMapLinkComp from "../demo/page/ViewMapLinkComp";
import AuthorLink from "../user/AuthorLink";
import RegularNewsItemComp from "./RegularNewsItemComp";
import MapDownloadLinkComp from "../demo/MapDownloadLinkComp";
import { typesT, typeToColor, typeToTextColor } from "../server/page/Types";
import {
  difficultiesT,
  difficultyToColor,
  difficultyToTextColor,
} from "../server/page/Difficulties";

export interface MapNewsItemCompProps {
  item: MapsNewsItem;
  jwt: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fffffff",
    },
    mapReleaseBannerImg: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      pointerEvents: "none",
      height: "auto",
      width: "95%",
      maxWidth: "60vw",
    },
  })
);

export default function MapNewsItemComp(props: MapNewsItemCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [expanded, set_expanded] = React.useState(true);
  const [downloading, set_downloading] = React.useState(false);

  return (
    <div>
      {/* TODO */}
      <img
        src="/mapReleaseBanner.jpg"
        alt="romanian jumpers map release banner"
        className={classes.mapReleaseBannerImg}
      />

      <Backdrop className={classes.backdrop} open={downloading}>
        <CircularProgress size="5rem" color="secondary" />
      </Backdrop>

      <Accordion
        style={{
          backgroundColor: "transparent",
        }}
        square
        expanded={expanded}
        onChange={() => set_expanded((old) => !old)}
      >
        <AccordionSummary
          expandIcon={
            <Tooltip title={`${t("news.maps.collapse_maps_list")}`}>
              <ExpandMoreIcon />
            </Tooltip>
          }
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {props.item && (
            <List>
              {props.item.acceptedMaps
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((map, index) => (
                  <ListItem key={map.name} style={{ justifyContent: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: theme.spacing(1),
                        }}
                      >
                        <Typography variant="h4">
                          <MapLinkComp map={map.name} />
                        </Typography>

                        <ViewMapLinkComp
                          map={map.name}
                          jwt={props.jwt}
                          set_downloading={set_downloading}
                          on_unauthorized={() => {
                            alert("You must be logged in.");
                          }}
                        />

                        <MapDownloadLinkComp
                          hideText
                          icon
                          map={map.name}
                          jwt={props.jwt}
                          set_downloading={set_downloading}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: theme.spacing(1),
                        }}
                      >
                        {t("maps.created_by")}

                        <AuthorLink
                          author={map.author}
                          steam_id={map.author.steamID}
                          avatar
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          paddingTop: theme.spacing(4),
                          justifyContent: "center",
                          alignItems: "center",
                          gap: theme.spacing(2),
                        }}
                      >
                        <div>
                          {(map.types.split(",") as typesT[]).map((type) => (
                            <Chip
                              key={`${map.name}_${type}`}
                              label={type}
                              style={{
                                margin: 2,
                                backgroundColor: typeToColor(type),
                                color: typeToTextColor(type),
                              }}
                            />
                          ))}
                        </div>

                        <div>|</div>

                        <div>
                          {(map.difficulties.split(",") as difficultiesT[]).map(
                            (diff) => (
                              <Chip
                                key={`${map.name}_${diff}`}
                                label={diff}
                                style={{
                                  margin: 2,
                                  backgroundColor: difficultyToColor(diff),
                                  color: difficultyToTextColor(diff),
                                }}
                              />
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </ListItem>
                ))}
            </List>
          )}
        </div>
      </Accordion>

      {props.item.content.length !== 0 && (
        <RegularNewsItemComp jwt={props.jwt} content={props.item.content} />
      )}
    </div>
  );
}
