import { Backdrop, CircularProgress, createStyles, makeStyles, Tab, Tabs, Theme, Typography } from "@material-ui/core";
import React from "react";
import CustomPaper from "../../CustomPaper";
import TabPanel from "../../TabPanelComp";
import User from "../../user/User";
import SiteRecords from "./SiteRecords";
import { Record } from "../DemoWaitlistData";
import { NEW_API_URL } from "../../Config";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ServerRecordingsV2 from "./ServerRecordingsV2";

export interface RecordsProps {
  jwt: string | null,
  user: User | undefined
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
  })
);

export default function Records(props: RecordsProps) {
  const params = useParams<{ type: string }>();
  const classes = useStyles();
  const { t } = useTranslation();

  const [tabIndex, set_tabIndex] = React.useState(params.type === "site" ? 0 : 1);
  const [userBanned, set_userBanned] = React.useState(false);
  const [records, set_records] = React.useState<Record[]>([]);
  const [loading, set_loading] = React.useState(false);

  const badMaps = React.useMemo(() => [
    "kz_fairy_island",
    "1",
    "1de_dust",
    "de_dust2",
    "fu_camera1",
    "fu_plainhop2_beta",
    "j2s_westbl0ck2",
    "kz_concise"
  ], []);

  React.useEffect(() => {
    if (!props.jwt) {
      return;
    }

    if (records.length !== 0) {
      return;
    }

    if (tabIndex !== 0) {
      return;
    }

    set_loading(true);

    fetch(`${NEW_API_URL}/server/records/all`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        if (response.status === 423) {
          set_userBanned(true);
        }
      } else {
        const data = (await response.json()) as Record[];

        set_records(data.filter(rec => !badMaps.includes(rec.map.toLowerCase())));
      }
    }).finally(() => {
      set_loading(false);
    });
  }, [props.jwt, records.length, tabIndex, badMaps]);

  if (!props.jwt) {
    return <CustomPaper>
      <div className={classes.wrapperDiv}>
        {t("unauthorized")}
      </div>
    </CustomPaper>;
  }

  if (userBanned) {
    return <CustomPaper>
      <div className={classes.wrapperDiv}>
        {t("banned")}
      </div>
    </CustomPaper>;
  }

  return <div className={classes.wrapperDiv}>
    <Typography variant="overline">
      {t("menu.demos")}
    </Typography>

    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress size="5rem" color="secondary" />
    </Backdrop>

    <Tabs
      value={tabIndex}
      onChange={(_, value) => set_tabIndex(value)}
      indicatorColor="primary"
      centered
    >
      <Tab label="Site" />
      <Tab label="Server" />
    </Tabs>

    <TabPanel value={tabIndex} index={0}>
      {tabIndex === 0 && <SiteRecords {...props} records={records} set_loading={set_loading} />}
    </TabPanel>

    <TabPanel value={tabIndex} index={1}>
      {tabIndex === 1 && <ServerRecordingsV2 jwt={props.jwt} />}
    </TabPanel>
  </div>;
};
