import React from "react";

import { Button, createStyles, Fade, IconButton, Link, makeStyles, TextField, Theme, Typography } from "@material-ui/core";

import AuthorLink from "../user/AuthorLink";
import User from "../user/User";

import ChatMsg from "./ChatMsg";
import { API_URL } from "../Config";
import CustomPaper from "../CustomPaper";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";

export interface ChatCompProps {
  jwt: string | null,
  user: User | undefined
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    },
    chatItems: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3)
    },
    submitMsgWrapper: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    navButtonsWrapper: {
      display: "flex",
      justifyContent: "center"
    }
  })
);

const useChatItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatItemWrapper: {
      padding: theme.spacing(2),
    },
    noUpperCase: {
      textTransform: "none",
      display: "flex",
      alignItems: "center",
    },
    link: {
      color: "white",
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1)
    },
    mdWrapper: {
      wordWrap: "break-word",
      wordBreak: "break-word"
    },
  }),
);

interface ChatItemProps {
  msg: ChatMsg
};

function ChatItem(props: ChatItemProps) {
  const classes = useChatItemStyles();

  const markdownify = (text: string) => {
    return text.replaceAll(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g, "[-link-]($&)");
  };

  return <CustomPaper>
    <div className={classes.chatItemWrapper}>
      <AuthorLink
        avatar
        author={props.msg.authorUser}
        steam_id={props.msg.authorUser.steamID}
        date={props.msg.date}
      />

      <br/>

      <ReactMarkdown
        className={classes.mdWrapper}
        components={{
          img: ({node, ...componentProps}) => {
            return <span style={{display: "flex", justifyContent: "center"}}><img {...componentProps} alt="img"/></span>;
          },
          a: ({node, ...componentProps}) => {
            return <Link
              children={componentProps.children}
              href={componentProps.href}
              color="secondary"
              target="_blank"
            />;
          }
        }}
      >
        {markdownify(props.msg.text)}
      </ReactMarkdown>
    </div>
  </CustomPaper>;
}

export default function ChatComp(props: ChatCompProps) {
  const classes = useStyles();
  const {t} = useTranslation();

  const [messages, set_messages] = React.useState<ChatMsg[]>([]);
  const [offset, set_offset] = React.useState(0);

  const [message, set_message] = React.useState("");
  const [userBanned, set_userBanned] = React.useState(false);

  React.useEffect(() => {
    fetch(`${API_URL}/chat?offset=${offset}`, {
      method: "GET"
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_messages(await response.json() as ChatMsg[]);
      }
    }).finally(() => {

    });
  }, [offset]);

  const onSendMsg = React.useCallback(() => {
    if (userBanned || message.length === 0 || ! props.user) {
      return;
    }

    fetch(`${API_URL}/chat`, {
      method: "POST",
      body: JSON.stringify({
        msg: message
      }),
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        if (response.status === 423) {
          set_userBanned(true);
          set_message(t("banned"));
        }
      } else {
        set_messages(prev => {
          return [
            {
              id: -1,
              authorUser: props.user as User,
              date: new Date(),
              text: message
            },
            ...prev
          ];
        });

        set_message("");
      }
    });
  }, [userBanned, message, props.jwt, props.user, t]);

  return <div className={classes.wrapper}>
    <Typography variant="overline">
      Chat
    </Typography>

    {props.jwt &&
      <div className={classes.submitMsgWrapper}>
        <TextField
          label={t("chat.message")}
          value={message}
          onChange={(e) => {
            set_message(e.target.value);
          }}
          autoComplete="off"
          variant="outlined"
          multiline
          fullWidth
          disabled={userBanned}
          onKeyPress={(e) => {
            if (e.ctrlKey === true && e.key === "Enter") {
              onSendMsg();
              e.preventDefault();
            }
          }}
        />

        <Button
          fullWidth
          onClick={onSendMsg}
          disabled={userBanned || message.length === 0}
        >
          {t("chat.send")}
        </Button>
      </div>
    }

    <Fade in timeout={500} unmountOnExit>
      <div className={classes.chatItems}>
          {messages.map((msg, index) =>
            <ChatItem key={index} msg={msg}/>
          )}
      </div>
    </Fade>

    {props.jwt && <div className={classes.navButtonsWrapper}>
      <IconButton
        disabled={offset === 0}
        onClick={() => set_offset(Math.max(0, offset - 10))}
      >
        <ArrowBackIosIcon/>
      </IconButton>

      <IconButton
        disabled={messages.length !== 10}
        onClick={() => set_offset(offset + messages.length)}
      >
        <ArrowForwardIosIcon/>
      </IconButton>
    </div>}
  </div>;
};
