export const types = [
  "AXN",
  "Bhop",
  "Climb",
  "Deathrun",
  "EdgeBug",
  "Falldown",
  "GroundStrafe",
  "JumpBug",
  "Ladder",
  "LongJump",
  "NoSlow",
  "Slide",
  "Stairs",
  "Surf",
  "Water",
  "WeirdJump",
] as const;

export type typesT = typeof types[number];

export const typeToColor = (type: typesT) => {
  switch (type) {
    case "Climb": return "green";
    case "Bhop": return "orange";
    case "Slide": return "lightblue";
    case "LongJump": return "white";
    case "Ladder": return "red";
    case "EdgeBug": return "purple";
    case "JumpBug": return "purple";
    case "WeirdJump": return "white";
    case "Water": return "blue";
    case "Falldown": return "cyan";
    case "AXN": return "yellow";
    case "NoSlow": return "yellow";
    case "Stairs": return "cyan";
  }

  return "white";
};

export const typeToTextColor = (type: typesT) => {
  switch (type) {
    case "Climb": return "white";
    case "Bhop": return "black";
    case "Slide": return "black";
    case "LongJump": return "black";
    case "Ladder": return "white";
    case "EdgeBug": return "white";
    case "JumpBug": return "white";
    case "WeirdJump": return "black";
    case "Water": return "white";
    case "Falldown": return "black";
    case "AXN": return "black";
    case "NoSlow": return "black";
    case "Stairs": return "black";
  }

  return "black";
};
