import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { API_URL } from "../../Config";
import MapDownloadLinkComp from "../../demo/MapDownloadLinkComp";
import MapLinkComp from "../../demo/MapLinkComp";
import ViewMapLinkComp from "../../demo/page/ViewMapLinkComp";
import { MapInfo } from "../../map/MapDataDTO";
import { difficulties, difficultiesT } from "./Difficulties";
import ServerAdminMapDifficultyComp from "./ServerAdminMapDifficultyComp";
import ServerAdminMapTypeComp from "./ServerAdminMapTypeComp";
import { types, typesT } from "./Types";

import YouTubeIcon from "@material-ui/icons/YouTube";

export interface ServerAdminMapRowProps {
  jwt: string | null;
  index: number;
  mapInfo: MapInfo;
  forceCup: boolean;
  set_loading: (state: boolean) => void;
}

export default function ServerAdminMapRow(props: ServerAdminMapRowProps) {
  const theme = useTheme();

  const [onServers, set_onServers] = React.useState(
    false /* props.mapInfo.onServers */
  );

  const [originalMapTypes] = React.useState<number[]>(
    props.mapInfo.t
      ? props.mapInfo.t.split(",").map((name) => types.indexOf(name as typesT))
      : []
  );
  const [mapTypes, set_mapTypes] = React.useState<number[]>(originalMapTypes);

  const [originalMapDifficulties] = React.useState<number[]>(
    props.mapInfo.d
      ? props.mapInfo.d
          .split(",")
          .map((diff) => difficulties.indexOf(diff as difficultiesT))
      : []
  );
  const [mapDifficulties, set_mapDifficulties] = React.useState<number[]>(
    originalMapDifficulties
  );

  type statusT = "ADDING" | "NOTADDED" | "ADDED" | "ERROR" | "REMOVING";
  const [status, set_status] = React.useState<statusT>("NOTADDED");

  const onAddToServer = React.useCallback(() => {
    set_status("ADDING");

    fetch(`${API_URL}/server_admin/maps/add/${props.mapInfo.n}`, {
      method: "POST",
      body: JSON.stringify({
        addToCupSrv: props.forceCup,
        types: mapTypes.map((value) => types[value]),
        difficulties: mapDifficulties.map((value) => difficulties[value]),
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.jwt}`,
      },
    })
      .then(async (response) => {
        if (response.status !== 200) {
          set_status("ERROR");
        } else {
          set_status("ADDED");
          set_onServers(true);
        }
      })
      .catch((reason) => {
        set_status("ERROR");

        console.error(reason);
      });
  }, [props.jwt, props.mapInfo, mapDifficulties, mapTypes, props.forceCup]);

  const onRemove = React.useCallback(() => {
    set_status("REMOVING");

    fetch(`${API_URL}/server_admin/maps/${props.mapInfo.n}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.jwt}`,
      },
    })
      .then(async (response) => {
        if (response.status !== 200) {
          set_status("ERROR");
        } else {
          set_status("NOTADDED");
          set_onServers(false);
        }
      })
      .catch((reason) => {
        set_status("ERROR");

        console.error(reason);
      });
  }, [props.jwt, props.mapInfo.n]);

  return (
    <div
      style={{
        display: "grid",
        alignItems: "center",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(1),
        }}
      >
        <Typography>#{props.index + 1}. </Typography>

        <MapLinkComp map={props.mapInfo.n} />

        <ViewMapLinkComp
          jwt={props.jwt}
          map={props.mapInfo.n}
          set_downloading={(state) => props.set_loading(state)}
          on_unauthorized={() => {}}
        />

        <MapDownloadLinkComp
          hideText
          icon
          map={props.mapInfo.n}
          jwt={props.jwt}
          set_downloading={(state) => props.set_loading(state)}
        />

        <IconButton
          href={`https://www.youtube.com/results?search_query=${props.mapInfo.n}`}
          target="_blank"
        >
          <YouTubeIcon />
        </IconButton>
      </div>

      <ServerAdminMapTypeComp types={mapTypes} set_types={set_mapTypes} />

      <ServerAdminMapDifficultyComp
        difficulties={mapDifficulties}
        set_difficulties={set_mapDifficulties}
      />

      {onServers && !props.forceCup ? (
        <Button variant="contained" color="secondary" onClick={onRemove}>
          {status === "NOTADDED" && <>Remove</>}
          {status === "ERROR" && <>Error. Try again?</>}
          {status === "REMOVING" && (
            <CircularProgress size="2rem" color="secondary" />
          )}
        </Button>
      ) : (
        <Button
          variant="contained"
          color="primary"
          disabled={
            mapTypes.length === 0 ||
            mapDifficulties.length === 0 ||
            status === "ADDED" ||
            status === "ADDING"
          }
          onClick={onAddToServer}
        >
          {status === "NOTADDED" && <>Add to server!</>}
          {status === "ADDED" && <>Added!</>}
          {status === "ERROR" && <>Error. Try again?</>}
          {status === "ADDING" && (
            <CircularProgress size="2rem" color="secondary" />
          )}
        </Button>
      )}
    </div>
  );
}
