import {
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import ReactMarkdown from "react-markdown";
import ViewDemoLink from "../viewdemo/ViewDemoLink";
import MapLinkComp from "../demo/MapLinkComp";
import { useTranslation } from "react-i18next";

export interface RegularNewsItemCompProps {
  jwt: string | null;
  content: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mdWrapper: {
      padding: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
  })
);

export default function RegularNewsItemComp(props: RegularNewsItemCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <ReactMarkdown
      className={classes.mdWrapper}
      components={{
        img: ({ node, ...componentProps }) => {
          return (
            <span style={{ display: "flex", justifyContent: "center" }}>
              <img {...componentProps} alt="img" />
            </span>
          );
        },
        a: ({ node, href, children, ...componentProps }) => {
          if (href && href.startsWith("https://md5:")) {
            const dataStr = href.substring(12);
            const data = dataStr.split(":");

            if (data.length !== 5) {
              return (
                <Typography color="error">Invalid custom MD tag</Typography>
              );
            }

            const player1Name = data[0];
            const player1Md5 = data[1];
            const player2Name = data[2];
            const player2Md5 = data[3];
            const map = data[4];

            return (
              <div
                style={{
                  display: "flex",
                  gap: theme.spacing(1),
                }}
              >
                <Typography>{player1Name}</Typography>

                {player1Md5 === "ff" ? (
                  <Typography>FF</Typography>
                ) : (
                  <ViewDemoLink
                    jwt={props.jwt}
                    serverRecord={true}
                    demo={{
                      demoMD5: player1Md5,
                    }}
                  />
                )}

                {t("news.regular.vs")}

                <Typography>{player2Name}</Typography>

                {player2Md5 === "ff" ? (
                  <Typography>FF</Typography>
                ) : (
                  <ViewDemoLink
                    jwt={props.jwt}
                    serverRecord={true}
                    demo={{
                      demoMD5: player2Md5,
                    }}
                  />
                )}

                {t("news.regular.on")}

                <MapLinkComp map={map} useColor={true} />
              </div>
            );
          } else {
            return (
              <Link
                // {...componentProps}
                color="error"
                href={href}
              >
                {children}
              </Link>
            );
          }
        },
      }}
    >
      {props.content}
    </ReactMarkdown>
  );
}
