import { Backdrop, Button, CircularProgress, Divider, Paper, Typography, useTheme } from '@material-ui/core';
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import React from 'react';
import { FormEvent } from 'react';
import { API_URL } from '../Config';

import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Redirect, useLocation } from 'react-router-dom';
import { PaymentIntent, StripeCardElementOptions } from '@stripe/stripe-js';
import StripePrice from '../shop/StripePrice';
import { useTranslation } from 'react-i18next';
import Image from 'material-ui-image';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "none",
      color: theme.palette.text.disabled
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
  })
);

export default function CardForm() {
  interface LocationState {
    item: StripePrice,
    jwt: string,
    subscriptionData: {
      subscriptionId: string,
      clientSecret: string,
    }
  };

  const location = useLocation<LocationState>();

  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const cardStyle: StripeCardElementOptions = {
    hidePostalCode: true,
    iconStyle: "solid",
    style: {
      base: {
        color: theme.palette.text.primary,
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: theme.palette.text.primary,
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const [error, set_error] = React.useState('');
  const [paymentIntent, set_paymentIntent] = React.useState<PaymentIntent>();
  const [hoveringBuy, set_hoveringBuy] = React.useState(false);
  const [hoveringImg, set_hoveringImg] = React.useState(false);
  const [processingPayment, set_processingPayment] = React.useState(false);

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  if (!stripe || !elements) {
    // Stripe.js has not loaded yet. Make sure to disable
    // form submission until Stripe.js has loaded.
    return null;
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      console.error("Card element not found !");
      return;
    }

    set_error("");
    set_processingPayment(true);

    // Use card Element to tokenize payment details
    const { error, paymentIntent } = await stripe.confirmCardPayment(location.state.subscriptionData.clientSecret, {
      payment_method: {
        card: cardElement,
      }
    });

    if (error) {
      if (error.message) {
        set_error(error.message);
      }

      set_processingPayment(false);
      return;
    }

    set_paymentIntent(paymentIntent);
    set_processingPayment(false);
  };

  if (location.state === undefined) {
    return <Redirect to={{
      pathname: '/shop',
    }} />;
  }

  if (paymentIntent && paymentIntent.status === 'succeeded') {
    fetch(`${API_URL}/shop/activate_item`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${location.state.jwt}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        item_price_id: location.state.item.id,
        item_name: location.state.item.item.metadata.name,
        for_weapon: location.state.item.item.metadata.weapon
      })
    });

    return <div style={{
      padding: theme.spacing(2)
    }}>
      <Typography variant="body1">
        {t("shop.payment_successful")}
      </Typography>

      <Typography>
        {t("shop.item_activated")}
      </Typography>
    </div>;
  }

  return <div style={{
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(6)
  }}>
    {processingPayment && <Backdrop className={classes.backdrop} open={processingPayment}>
      <CircularProgress size="5rem" color="secondary" />
    </Backdrop>}

    <Paper
      elevation={15}
      style={{
        backgroundColor: theme.palette.background.default,
        width: "min(400px, 50%)",
        height: "min(400px, 50%)",
        padding: theme.spacing(4),
        borderRadius: theme.spacing(2),
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography style={{ display: "flex", justifyContent: "center" }}>
        {t("shop.buying_for", {
          what: location.state.item.item.name,
          amount: location.state.item.price / 100,
          currency: location.state.item.currency.toUpperCase(),
          recurrency: t(`shop.${location.state.item.recurring}`)
        })}
      </Typography>

      <Divider style={{
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        backgroundColor: theme.palette.text.disabled
      }} />

      <Image
        src={location.state.item.item.images[0]}
        alt={location.state.item.item.name}
        imageStyle={{
          width: "100%",
          height: "100%",
          transition: "all 0.5s",
          transitionTimingFunction: "ease-in-out",
          filter: hoveringImg ? "none" : "grayscale(100%)",
          borderRadius: hoveringImg ? 0 : theme.spacing(3)
        }}
        color={theme.palette.background.default}
        onMouseEnter={() => set_hoveringImg(true)}
        onMouseLeave={() => set_hoveringImg(false)}
      />

      <Divider style={{
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(5),
        backgroundColor: theme.palette.text.disabled
      }} />

      <CardElement options={cardStyle} />

      <Button
        onClick={handleSubmit}
        variant={"outlined"}
        color='primary'
        fullWidth
        disabled={processingPayment}
        style={{
          color: hoveringBuy ? theme.palette.text.primary : "green",
          borderColor: "green",
          transition: "all 0.5s",
          transitionTimingFunction: "ease-in-out",
          backgroundColor: hoveringBuy ? "green" : undefined,
          borderWidth: theme.spacing(.25),
          marginTop: theme.spacing(3)
        }}
        onMouseEnter={() => set_hoveringBuy(true)}
        onMouseLeave={() => set_hoveringBuy(false)}
      >
        <Typography>{t("shop.buy_label")}</Typography>
      </Button>

      {error.length !== 0 && <Typography>{error}</Typography>}

      <div style={{
        color: theme.palette.text.disabled,
        display: "flex",
        justifyContent: "flex-end",
        gap: theme.spacing(0.5),
        paddingTop: theme.spacing(3)
      }}>
        {t("shop.powered_by")}<a href="https://stripe.com" className={classes.link}>Stripe ©</a>
      </div>
    </Paper>
  </div>;
}
