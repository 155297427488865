import React from "react";

import { Avatar, Backdrop, CircularProgress, createStyles, darken, Fade, List, ListItem, ListItemText, makeStyles, Tab, Tabs, Theme, Typography, useTheme } from "@material-ui/core";

import AuthorLink from "../../user/AuthorLink";

import { API_URL } from "../../Config";
import LJBlockLinkComp from "../LJBlockLinkComp";
import LJDemoData from "../LJDemoData";
import LJTechnique, { techniqueToStr } from "../LJTechnique";
import TabPanel from "../../TabPanelComp";

import ViewDemoLink from "../../viewdemo/ViewDemoLink";
import { useTranslation } from "react-i18next";
import Weapon, { RecordableWeapons, wpnToString } from "../Weapon";

export interface LJTopProps {
  jwt: string | null
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    },
    outerTabsWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 8fr'
    },
    innerTabsWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(4)
    }
  })
);

const useRecordPanelStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    link: {
      color: theme.palette.text.primary,
      cursor: "grabbing"
    },
    list: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    blockLink: {
      display: "flex",
      color: theme.palette.text.primary,
      cursor: "grabbing",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
    topElementFirstRowWrapper: {
      display: "flex",
      gap: theme.spacing(5),
      alignItems: "center",
      justifyContent: "center"
    },
    listItem: {
      width: "45vw",
      textAlign: "center",
      gap: theme.spacing(2),
      alignItems: "flex-end"
    }
  })
);


interface RecordPanelProps {
  jwt: string | null,
  tabIndex: number,
  index: number,
  technique: LJTechnique,
  weapon: Weapon,
  set_userBanned: (value: boolean) => void
};

export const getBadge = (index: number) => {
  if (index > 2) {
    return null;
  }

  let url = '';

  switch (index) {
    case 0: url = '/gold.png'; break;
    case 1: url = '/silver.png'; break;
    case 2: url = '/bronze.png'; break;
  }

  return <Avatar src={url}/>
};

function RecordPanel(props: RecordPanelProps) {
  const classes = useRecordPanelStyles();
  const theme = useTheme();
  const {t} = useTranslation();

  const [data, set_data] = React.useState<LJDemoData[]>([]);
  const [downloading, set_downloading] = React.useState(false);

  React.useEffect(() => {
    if (props.tabIndex !== props.index) {
      return;
    }

    fetch(`${API_URL}/ljdemos/technique/${props.technique}/weapon/${props.weapon}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        if (response.status === 423) {
          props.set_userBanned(true);
        }
      } else {
        set_data(await response.json());
      }
    });
  }, [props]);

  const isMax = React.useCallback((current: LJDemoData, field: string) => {
    for (const d of data) {
      if (field === "distance") {
        if (d.stats && current.stats && d.stats.distance > current.stats.distance) {
          return false;
        }
      } else if (field === "maxSpeed") {
        if (d.stats && current.stats && d.stats.maxSpeed > current.stats.maxSpeed) {
          return false;
        }
      } else if (field === "preStrafe") {
        if (d.stats && current.stats && d.stats.preStrafe > current.stats.preStrafe) {
          return false;
        }
      } else if (field === "jumpOff") {
        if ((current.stats && current.stats.jumpOff === 0) || (d.stats && current.stats && d.stats.jumpOff < current.stats.jumpOff)) {
          return false;
        }
      } else if (field === "landing") {
        if ((current.stats && current.stats.landing === 0) || (d.stats && current.stats && d.stats.landing > current.stats.landing)) {
          return false;
        }
      } else if (field === "sync") {
        if (d.stats && current.stats && d.stats.sync > current.stats.sync) {
          return false;
        }
      } else if (field === "strafes") {
        if (d.stats && current.stats && d.stats.strafes.length > current.stats.strafes.length) {
          return false;
        }
      }
    }
    
    return true;
  }, [data]);

  const isMin = React.useCallback((current: LJDemoData, field: string) => {
    for (const d of data) {
      if (field === "distance") {
        if (d.stats && current.stats && d.stats.distance < current.stats.distance) {
          return false;
        }
      } else if (field === "maxSpeed") {
        if (d.stats && current.stats && d.stats.maxSpeed < current.stats.maxSpeed) {
          return false;
        }
      } else if (field === "preStrafe") {
        if (d.stats && current.stats && d.stats.preStrafe < current.stats.preStrafe) {
          return false;
        }
      } else if (field === "jumpOff") {
        if ((current.stats && current.stats.jumpOff === 0) || (d.stats && current.stats && d.stats.jumpOff > current.stats.jumpOff)) {
          return false;
        }
      } else if (field === "landing") {
        if ((current.stats && current.stats.landing === 0) || (d.stats && current.stats && d.stats.landing < current.stats.landing)) {
          return false;
        }
      } else if (field === "sync") {
        if (d.stats && current.stats && d.stats.sync < current.stats.sync) {
          return false;
        }
      } else if (field === "strafes") {
        if (d.stats && current.stats && d.stats.strafes.length < current.stats.strafes.length) {
          return false;
        }
      }
    }
    
    return true;
  }, [data]);

  const sorter = React.useCallback((a: LJDemoData, b: LJDemoData) => {
    if (a.block > b.block) {
      return -1;
    }

    if (a.block < b.block) {
      return 1;
    }

    return a.stats &&b.stats && a.stats.distance > b.stats.distance ? -1 : 1;
  }, []);

  if (! props.jwt) {
    return <div className={classes.wrapperDiv}>
      {t("unauthorized")}
    </div>;
  }

  return <TabPanel value={props.tabIndex} index={props.index} noMarginTop>
    <List className={classes.list}>
      {data.length === 0 &&
        <Fade in timeout={500} unmountOnExit>
          <Typography>
            {t("demo.lj.empty_section")}
          </Typography>
        </Fade>
      }

      <Backdrop className={classes.backdrop} open={downloading}>
        <CircularProgress size="5rem" color="secondary"/>
      </Backdrop>

      {data.sort(sorter).map((demo, index) =>
        <Fade in timeout={500 + index * 100} unmountOnExit key={`${index}_${demo.demoMD5}`}>
          <ListItem className={classes.listItem}>
            <ListItemText
              disableTypography
              primary={
                <div className={classes.topElementFirstRowWrapper}>
                  <LJBlockLinkComp
                    jwt={props.jwt}
                    demo={demo}
                    className={classes.blockLink}
                    set_downloading={set_downloading}
                    on_unauthorized={() => {}}
                  >
                    <>
                      {getBadge(index)}

                      <Typography
                        variant={index === 0 ? 'h3' : index === 1 ? 'h4' : index === 2 ? 'h6' : 'body1'}
                        style={{
                          color: darken(theme.palette.text.primary, Math.min(index / 10, .5))
                        }}
                      >
                        {index > 2 && <>#{index + 1} </>}{demo.block}
                      </Typography>
                    </>
                  </LJBlockLinkComp>

                  {demo.uploader && <AuthorLink author={demo.uploader} steam_id={demo.uploader.steamID} avatar/>}

                  <ViewDemoLink jwt={props.jwt} demo={demo} lj/>
                </div>
              }
              secondary={
                <div>
                  {demo.stats &&
                    <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr"}}>
                      <div>
                        <Typography
                          variant="overline"
                        >
                          {`Distance `}
                        </Typography>

                        <Typography
                          variant="overline"
                          style={{
                            color: isMax(demo, "distance") ? "lightgreen" : (isMin(demo, "sync") ? "red" :  undefined)
                          }}
                        >
                          {demo.stats.distance.toFixed(3)}
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          variant="overline"
                        >
                          {`Pre `}
                        </Typography>

                        <Typography
                          variant="overline"
                          style={{
                            color: isMax(demo, "preStrafe") ? "lightgreen" : (isMin(demo, "sync") ? "red" : undefined)
                          }}
                        >
                          {demo.stats.preStrafe.toFixed(3)}
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          variant="overline"
                        >
                          {`JumpOff `}
                        </Typography>

                        <Typography
                          variant="overline"
                          style={{
                            color: isMax(demo, "jumpOff") ? "lightgreen" : (isMin(demo, "sync") ? "red" : undefined)
                          }}
                        >
                          {demo.stats.jumpOff !== 0 ? demo.stats.jumpOff.toFixed(3) : "n/a"}
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          variant="overline"
                        >
                          {`Landing `}
                        </Typography>

                        <Typography
                          variant="overline"
                          style={{
                            color: isMax(demo, "landing") ? "lightgreen" : (isMin(demo, "sync") ? "red" : undefined)
                          }}
                        >
                          {demo.stats.landing !== 0 ? demo.stats.landing.toFixed(3) : "n/a"}
                        </Typography>
                      </div>

                      <div>
                        <Typography
                          variant="overline"
                        >
                          {`Strafes `}
                        </Typography>

                        <Typography
                          variant="overline"
                          style={{
                            color: isMax(demo, "strafes") ? "lightgreen" : (isMin(demo, "sync") ? "red" : undefined)
                          }}
                        >
                          {demo.stats.strafes.length !== 0 ? demo.stats.strafes.length : "n/a"}
                        </Typography>
                      </div>

                      <div>
                      <Typography
                          variant="overline"
                        >
                          {`Sync `}
                        </Typography>

                        <Typography
                          variant="overline"
                          style={{
                            color: isMax(demo, "sync") ? "lightgreen" : (isMin(demo, "sync") ? "red" : undefined)
                          }}
                        >
                          {demo.stats.sync ? demo.stats.sync.toFixed(3) : "n/a"}
                        </Typography>
                      </div>
                    </div>
                  }
                </div>
              }
            />
          </ListItem>
        </Fade>
      )}
    </List>
  </TabPanel>;
}

export default function LJTop(props: LJTopProps) {
  const classes = useStyles();
  const {t} = useTranslation();

  const [tabIndex, set_tabIndex] = React.useState(0);
  const [weaponTabIndex, set_weaponTabIndex] = React.useState(0);
  const [userBanned, set_userBanned] = React.useState(false);

  const techniques = [
    LJTechnique.LJ,
    LJTechnique.CJ,
    LJTechnique.MCJ,
    LJTechnique.HJ,
    LJTechnique.SBJ,
    LJTechnique.BJ,
    LJTechnique.WJ,
    LJTechnique.LDJ,
    LJTechnique.SLJ
  ];

  if (! props.jwt) {
    return <div className={classes.wrapper}>
      {t("unauthorized")}
    </div>;
  }

  if (userBanned) {
    return <div className={classes.wrapper}>
      {t("banned")}
    </div>;
  }

  return <div className={classes.wrapper}>
    <Typography variant="overline">
      Top
    </Typography>

    <div className={classes.innerTabsWrapper}>
      <Tabs
        value={weaponTabIndex}
        onChange={(_, value) => set_weaponTabIndex(value)}
        indicatorColor="primary"
        style={{
          marginTop: 0,
          paddingTop: 0,
        }}
      >
        {RecordableWeapons.map((wpn, index) => <Tab key={index} label={wpnToString(wpn)}/>)}
      </Tabs>
    </div>

    <div className={classes.outerTabsWrapper}>
      <Tabs
        orientation="vertical"
        value={tabIndex}
        onChange={(_, value) => set_tabIndex(value)}
        indicatorColor="primary"
      >
        {techniques.map((t, index) =>
          <Fade in timeout={500 + 100 * index} unmountOnExit key={index}>
            <Tab label={techniqueToStr(t)}/>
          </Fade>
        )}
      </Tabs>

      {techniques.map((t, index) => <RecordPanel
        key={index}
        jwt={props.jwt}
        tabIndex={tabIndex}
        index={index}
        technique={t}
        weapon={RecordableWeapons[weaponTabIndex]}
        set_userBanned={set_userBanned}
      />)}
    </div>
  </div>;
};
