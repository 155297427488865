import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Slider,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Draggable, DropResult } from "react-smooth-dnd";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { arrayMoveImmutable } from "array-move";
import RemoveIcon from "@material-ui/icons/Remove";
import HelpIcon from "@material-ui/icons/Help";

import CustomPaper from "../../CustomPaper";
import User from "../../user/User";
import {
  ChallengeType,
  challengeTypeToStr,
  challengeTypeToTranslationKey,
} from "../Challenge";
import { API_URL } from "../../Config";
import { useHistory } from "react-router-dom";
import { formatMS, isAdmin } from "../../Util";
import { Autocomplete } from "@material-ui/lab";
import WorldRecord from "../../demo/WorldRecord";
import MapLinkComp from "../../demo/MapLinkComp";

export interface CreateChallengeProps {
  jwt: string | null;
  user: User | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fffffff",
    },
  })
);

export default function CreateChallenge(props: CreateChallengeProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();

  const [challengeType, set_challengeType] = React.useState<ChallengeType>(
    ChallengeType.Unknown
  );
  const [mapCycle, set_mapCycle] = React.useState<string[]>([]);
  const [allWRs, set_allWRs] = React.useState<WorldRecord[]>([]);

  const [loading, set_loading] = React.useState(true);

  const [description, set_description] = React.useState<string>("");
  const [cycleDuration, set_cycleDuration] = React.useState<number>(2);

  const challengeTypes = Array.from(Array(ChallengeType.LastItem).keys());

  const onDrop = (dropResult: DropResult) => {
    set_mapCycle((items) =>
      arrayMoveImmutable(
        items,
        dropResult.removedIndex as number,
        dropResult.addedIndex as number
      )
    );
  };

  const removeFromMapCycle = (map: string) => {
    set_mapCycle((items) => items.filter((mp) => mp !== map));
  };

  const createChallenge = React.useCallback(() => {
    if (!props.jwt) {
      return;
    }

    fetch(`${API_URL}/challenges`, {
      method: "POST",
      body: JSON.stringify({
        type: challengeType,
        cycle: mapCycle,
        cycleDuration: cycleDuration,
        description: description,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${props.jwt}`,
      },
    })
      .then(async (response) => {
        if (response.status !== 200) {
        } else {
          history.push("/");
        }
      })
      .catch((reason) => {
        console.error(reason);
      });
  }, [mapCycle, description, challengeType, cycleDuration, history, props.jwt]);

  React.useEffect(() => {
    fetch(`${API_URL}/wrs`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${props.jwt}`,
      },
    })
      .then(async (response) => {
        if (response.status !== 200) {
        } else {
          set_allWRs(await response.json());
        }
      })
      .finally(() => {
        set_loading(false);
      });
  }, [props.jwt]);

  if (!props.jwt || !props.user || !isAdmin(props.user)) {
    return (
      <CustomPaper>
        <div className={classes.wrapperDiv}>{t("unauthorized")}</div>
      </CustomPaper>
    );
  }

  return (
    <CustomPaper>
      <div className={classes.wrapperDiv}>
        <Typography variant="overline">
          {t("challenge.create.title")}
        </Typography>

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress size="5rem" color="secondary" />
        </Backdrop>

        <div
          style={{
            width: "95%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            gap: theme.spacing(6),
          }}
        >
          <div>
            <InputLabel>{t("challenge.create.type")}</InputLabel>

            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl className={classes.formControl}>
                <Select
                  value={challengeType}
                  onChange={(e) => set_challengeType(e.target.value as number)}
                >
                  {challengeTypes.map((chlType, index) => (
                    <MenuItem
                      key={index}
                      value={chlType}
                      disabled={chlType === ChallengeType.Unknown}
                    >
                      {challengeTypeToStr(chlType)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {challengeType !== ChallengeType.Unknown && (
                <Tooltip
                  title={
                    <Typography>
                      {t(challengeTypeToTranslationKey(challengeType)).substr(
                        2
                      )}
                    </Typography>
                  }
                >
                  <HelpIcon />
                </Tooltip>
              )}
            </div>
          </div>

          <div>
            <InputLabel>{t("challenge.create.map_cycle")}</InputLabel>

            <Autocomplete
              options={allWRs}
              getOptionLabel={(wr) => wr.mapOriginalCase}
              value={null}
              onChange={(_, newValue) => {
                if (newValue) {
                  set_mapCycle((old) => old.concat(newValue.mapOriginalCase));
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("challenge.create.add_map")}
                  variant="outlined"
                  fullWidth
                />
              )}
              renderOption={(worldRecord) => (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: theme.spacing(3),
                  }}
                >
                  <b>{worldRecord.mapOriginalCase}</b>{" "}
                  <i>WR: {formatMS(worldRecord.time)}</i>
                </div>
              )}
            />

            <List>
              <Container
                dragHandleSelector=".drag-handle"
                lockAxis="y"
                onDrop={onDrop}
              >
                {mapCycle.map((map, index) => (
                  <Draggable key={index}>
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            {`${index + 1}. `}
                            <MapLinkComp map={map} />
                          </>
                        }
                      />

                      <ListItemSecondaryAction>
                        <ListItemIcon className="drag-handle">
                          <DragHandleIcon />
                        </ListItemIcon>

                        <IconButton
                          size="small"
                          onClick={() => removeFromMapCycle(map)}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Draggable>
                ))}
              </Container>
            </List>
          </div>

          <div>
            <InputLabel>{t("challenge.create.map_cycle_duration")}</InputLabel>

            <Slider
              style={{
                paddingTop: theme.spacing(7),
              }}
              valueLabelDisplay="on"
              step={1}
              marks
              min={1}
              max={7}
              value={cycleDuration}
              onChange={(e, newValue) => set_cycleDuration(newValue as number)}
            />
          </div>

          <div>
            <InputLabel>{t("challenge.create.description")}</InputLabel>

            <TextField
              style={{
                marginTop: theme.spacing(2),
              }}
              label={`${t("challenge.create.description_info")}`}
              fullWidth
              multiline
              minRows={5}
              variant="outlined"
              value={description}
              onChange={(e) => set_description(e.target.value)}
            />
          </div>

          <div>
            <Button
              variant="contained"
              color="primary"
              disabled={
                description.length === 0 ||
                mapCycle.length === 0 ||
                challengeType === ChallengeType.Unknown
              }
              onClick={createChallenge}
            >
              {t("challenge.create.start")}
            </Button>
          </div>
        </div>
      </div>
    </CustomPaper>
  );
}
