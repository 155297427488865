import { CircularProgress, createStyles, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CustomPaper from "../../CustomPaper";
import User from "../../user/User";
import { ActivateItem } from "./ShopItemComp";

export interface SuccessProps {
  jwt: string | null,
  user: User
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      alignItems: "center",
      paddingTop: theme.spacing(4)
    }
  })
);

export default function Success(props: SuccessProps) {
  const params = useParams<{ priceID: string, name: string, wpn: string }>();
  const theme = useTheme();

  const classes = useStyles();
  const { t } = useTranslation();

  const [loading, set_loading] = React.useState(true);
  const [error, set_error] = React.useState("");

  React.useEffect(() => {
    if (!props.jwt) {
      return;
    }

    ActivateItem(props.jwt, decodeURI(params.priceID), decodeURI(params.name), decodeURI(params.wpn))
      .then((response) => {
        if (response.status === 200) {
          set_error("");
        } else {
          set_error(response.statusText);
        }
      }).finally(() => {
        set_loading(false);
      });
  }, [params.name, params.priceID, params.wpn, props.jwt]);

  return <CustomPaper>
    <div className={classes.wrapper}>
      <Typography variant="body1">
        {t("shop.payment_successful")}
      </Typography>

      {loading && <CircularProgress size={theme.spacing(3)} color="secondary" />}

      {(!loading && !error) && <Typography>
        {t("shop.item_activated")}
      </Typography>}

      {error.length !== 0 && <Typography>
        {error}
      </Typography>}
    </div>
  </CustomPaper>;
};
