import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";

import AuthorLink from "../user/AuthorLink";

import NewsComment from "./NewsComment";

export interface NewsCommentCompProps {
  comment: NewsComment
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    twoColumnsWrapper: {
      display: "grid",
      gridTemplateColumns: "1fr 4fr"
    },
    notUppercase: {
      textTransform: "none",
      wordWrap: "break-word",
      wordBreak: "break-word",
      whiteSpace: "pre-wrap",
    },
  })
);

export default function NewsCommentComp(props: NewsCommentCompProps) {
  const classes = useStyles();

  return <div className={classes.twoColumnsWrapper}>
    <AuthorLink
      avatar
      author={props.comment.authorUser}
      steam_id={props.comment.authorUser.steamID}
      date={props.comment.datePosted}
    />

    <pre style={{ fontFamily: 'inherit' }}>
      <Typography noWrap={false} paragraph={true} className={classes.notUppercase}>
        {props.comment.message}
      </Typography>
    </pre>
  </div>;
};
