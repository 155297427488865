import { useTranslation } from "react-i18next";
import User from "../../user/User"
import { Tab, Tabs, TextField, Theme, Tooltip, Typography, createStyles, makeStyles, useTheme } from "@material-ui/core";
import React, { CSSProperties } from "react";
import { NEW_API_URL } from "../../Config";
import { UnregisteredUser } from "../ServerRecord";
import CustomPaper from "../../CustomPaper";
import TabPanel from "../../TabPanelComp";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as RWList } from 'react-window';
import AuthorLink from "../../user/AuthorLink";
import InfoIcon from '@material-ui/icons/Info';
import { Skeleton } from "@material-ui/lab";
import LinearProgress from '@material-ui/core/LinearProgress';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

interface RatingsProps {
    jwt: string | null,
    user: User | undefined,
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            padding: theme.spacing(2)
        },
        itemsWrapper: {
            display: "grid",
            alignItems: "center",
        },
        itemWrapper: {
            display: "flex",
            justifyContent: "center",
            gap: theme.spacing(1)
        },
    })
);

interface RankedPlayerRating {
    skill: number,
    uncertainty: number,
    user: User | undefined,
};

interface RankedPlayerDTO {
    sid: string,
    player: UnregisteredUser,
    overall: RankedPlayerRating,
    pro?: RankedPlayerRating,
    nub?: RankedPlayerRating
};

interface RatingsDataProps {
    user: User | undefined,
    data: RankedPlayerDTO[],
    pro?: boolean,
    nub?: boolean,
};

const MinHeight = 1800;

function RatingsData(props: RatingsDataProps) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const list = React.createRef<RWList>();

    const RowHeight = 75;
    const UncertaintyCutOff = 1.0;

    const sortedData = props.data.filter(d => {
        if (props.pro && props.nub) {
            return d.overall.uncertainty < UncertaintyCutOff;
        }

        if (props.pro) {
            if (!d.pro) {
                return false;
            }

            return d.pro.uncertainty < UncertaintyCutOff;
        }

        if (!d.nub) {
            return false;
        }

        return d.nub.uncertainty < UncertaintyCutOff;
    }).sort((a, b) => {
        if (props.pro && props.nub) {
            return a.overall.skill > b.overall.skill ? -1 : 1;
        }

        if (props.pro) {
            if (a.pro && b.pro) {
                return a.pro.skill > b.pro.skill ? -1 : 1;
            } else {
                return 0;
            }
        }

        if (a.nub && b.nub) {
            return a.nub.skill > b.nub.skill ? -1 : 1;
        } else {
            return 0;
        }
    });

    const visualSkillOffset = React.useMemo(() => {
        if (sortedData.length === 0) {
            return 0.0;
        }

        const elem = sortedData[sortedData.length - 1];

        if (props.pro && props.nub) {
            return Math.abs(elem.overall.skill);
        }

        if (props.pro) {
            if (elem.pro) {
                return Math.abs(elem.pro.skill);
            } else {
                return 0.0;
            }
        }

        if (elem.nub) {
            return Math.abs(elem.nub.skill);
        }

        return 0.0;
    }, [sortedData, props.pro, props.nub]);

    const myUncertainty = React.useMemo(() => {
        if (!props.user) {
            return UncertaintyCutOff + 1.0;
        }

        const user = props.user;
        const myData = sortedData.find(data => data.sid === user.steamID);

        if (!myData) {
            return UncertaintyCutOff + 1.0;
        }

        if (props.pro && props.nub) {
            return myData.overall.uncertainty;
        }

        if (props.pro) {
            if (myData.pro) {
                return myData.pro.uncertainty;
            } else {
                return UncertaintyCutOff + 1.0;
            }
        }

        if (myData.nub) {
            return myData.nub.uncertainty;
        }

        return UncertaintyCutOff + 1.0;
    }, [props, sortedData]);

    const highestSkill = React.useMemo(() => {
        if (sortedData.length === 0) {
            return 0.0;
        }

        if (props.pro && props.nub) {
            return sortedData[0].overall.skill + visualSkillOffset;
        }

        if (props.pro) {
            if (sortedData[0].pro) {
                return sortedData[0].pro.skill + visualSkillOffset;
            } else {
                return 0.0;
            }
        }

        if (sortedData[0].nub) {
            return sortedData[0].nub.skill + visualSkillOffset;
        }

        return 0.0;
    }, [sortedData, props.nub, props.pro, visualSkillOffset]);

    const Row = React.useCallback((input: { index: number, style: CSSProperties }) => {
        const data = sortedData[input.index];

        if (!props.user) {
            return null;
        }

        let skill = 0.0;
        let uncertainty = 1.0;

        if (props.pro && props.nub) {
            skill = data.overall.skill + visualSkillOffset;
            uncertainty = data.overall.uncertainty;
        } else if (props.pro) {
            if (data.pro) {
                skill = data.pro.skill + visualSkillOffset;
                uncertainty = data.pro.uncertainty;
            } else {
                console.error(`no pro data for ${data.player.displayName}`);
            }
        } else if (props.nub) {
            if (data.nub) {
                skill = data.nub.skill + visualSkillOffset;
                uncertainty = data.nub.uncertainty;
            } else {
                console.error(`no nub data for ${data.player.displayName}`);
            }
        }

        const isMyRecord = data.sid === props.user.steamID;

        return <div
            className={classes.itemsWrapper}
            style={{
                gridTemplateColumns: "1fr 7fr 7fr 7fr",
                height: `${RowHeight}px`,
                ...input.style,
                backgroundColor: isMyRecord ? theme.palette.grey[800] : "default"
            }}
        >
            <div className={classes.itemWrapper}>
                <Typography>
                    {input.index + 1}.
                </Typography>
            </div>

            <div className={classes.itemWrapper}>
                <AuthorLink author={data.player} steam_id={data.sid} avatar dontClampName />
            </div>

            <div
                className={classes.itemWrapper}
                style={{
                    display: "flex",
                    width: "100%"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "100%"
                    }}
                >
                    <Tooltip title={`${skill.toFixed(6)} ${t("ratings.points")}`}>
                        <LinearProgress
                            value={skill / highestSkill * 100.0}
                            variant="determinate"
                            style={{
                                height: theme.spacing(2),
                                width: "90%"
                            }}
                        />
                    </Tooltip>
                </div>
            </div>

            <div
                className={classes.itemWrapper}
                style={{
                    display: "flex",
                    width: "100%"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        width: "100%"
                    }}
                >
                    <Tooltip title={`${((1 - uncertainty) * 100.0).toFixed(2)}%`}>
                        <LinearProgress
                            value={(1 - uncertainty) * 100.0}
                            variant="determinate"
                            style={{
                                height: theme.spacing(2),
                                width: "90%"
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
        </div>
    }, [theme, sortedData, props.pro, props.nub, classes.itemWrapper, classes.itemsWrapper, visualSkillOffset, highestSkill, props.user, t]);

    return <div>
        {myUncertainty >= UncertaintyCutOff
            ? <div
                style={{
                    display: "flex",
                    gap: theme.spacing(1),
                    justifyContent: "center",
                    paddingTop: theme.spacing(2),
                    paddingBottom: theme.spacing(6),
                }}
            >
                <ReportProblemIcon />
                <Typography>{t("ratings.not_enough_maps")}</Typography>
                <ReportProblemIcon />
            </div>
            : null
        }

        <div>
            <TextField
                onChange={(e) => {
                    const text = e.target.value.toLowerCase();

                    if (text.length === 0) {
                        list.current?.scrollToItem(0, "start");
                    } else {
                        const index = sortedData.findIndex(item => item.player.displayName.toLowerCase().includes(text));

                        if (index !== -1) {
                            list.current?.scrollToItem(index, "start");
                        }
                    }
                }}
                autoComplete="off"
                variant="outlined"
                fullWidth
                label={t("ratings.table.filter.player")}
                style={{
                    paddingBottom: theme.spacing(6)
                }}
            />
        </div>

        <div
            className={classes.itemsWrapper}
            style={{
                gridTemplateColumns: "1fr 7fr 7fr 7fr",
                paddingBottom: theme.spacing(4)
            }}
        >
            {[
                { label: t("ratings.table.columns.index") },
                { label: t("ratings.table.columns.player") },
                { label: t("ratings.table.columns.skill"), tooltip: t("ratings.table.tooltips.skill") },
                { label: t("ratings.table.columns.certainty"), tooltip: t("ratings.table.tooltips.certainty") }
            ].map(({ label, tooltip }, index) =>
                <div
                    key={index}
                    className={classes.itemWrapper}
                >
                    <Typography>
                        {label}
                    </Typography>

                    {tooltip && <Tooltip title={tooltip}>
                        <InfoIcon />
                    </Tooltip>}
                </div>
            )}
        </div>

        <div
            style={{
                minHeight: MinHeight
            }}
        >
            <AutoSizer>
                {(param: { width: number }) => (
                    <RWList
                        height={MinHeight}
                        width={param.width}
                        itemSize={RowHeight}
                        itemCount={sortedData.length}
                        ref={list}
                    >
                        {Row}
                    </RWList>
                )}
            </AutoSizer>
        </div>
    </div>;
}

export default function Ratings(props: RatingsProps) {
    const classes = useStyles();
    const { t } = useTranslation();

    const [loading, set_loading] = React.useState(true);
    const [ratings, set_ratings] = React.useState<RankedPlayerDTO[]>([]);
    const [tabIndex, set_tabIndex] = React.useState(0);

    React.useEffect(() => {
        if (!props.jwt) {
            return;
        }

        set_loading(true);

        fetch(`${NEW_API_URL}/server/ratings`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${props.jwt}`
            }
        }).then(async (response) => {
            if (response.status !== 200) {
                // TODO error handling
            } else {
                const inRatings = await response.json() as RankedPlayerDTO[];

                set_ratings(inRatings.filter(r => r.player.steamID.length !== 0));
            }
        }).finally(() => {
            set_loading(false);
        });
    }, [props.jwt]);

    if (!props.jwt || !props.user) {
        return <div className={classes.wrapper}>
            {t("unauthorized")}
        </div>;
    }

    if (loading) {
        return <CustomPaper>
            <Skeleton
                height={`${MinHeight}px`}
                width="100%"
                animation="wave"
                variant="rect"
            >
                <div className={classes.wrapper}>
                    <Typography variant="overline">
                        {t("ratings.title")}
                    </Typography>
                </div>
            </Skeleton>
        </CustomPaper>;
    }

    return <CustomPaper>
        <div className={classes.wrapper}>
            <Typography variant="overline">
                {t("ratings.title")}
            </Typography>

            <Tabs
                value={tabIndex}
                onChange={(_, value) => set_tabIndex(value)}
                indicatorColor="primary"
                centered
            >
                <Tab label={t("ratings.tabs.combined")} />
                <Tab label={t("ratings.tabs.pro")} />
                <Tab label={t("ratings.tabs.nub")} />
            </Tabs>

            <TabPanel value={tabIndex} index={0}>
                <RatingsData
                    data={ratings}
                    user={props.user}
                    pro
                    nub
                />
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>
                <RatingsData
                    data={ratings}
                    user={props.user}
                    pro
                />
            </TabPanel>

            <TabPanel value={tabIndex} index={2}>
                <RatingsData
                    data={ratings}
                    user={props.user}
                    nub
                />
            </TabPanel>
        </div>
    </CustomPaper>;
}
