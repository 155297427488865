import React, { SVGAttributes } from "react";

import { Backdrop, CircularProgress, createStyles, makeStyles, Theme, Typography, useTheme, Tooltip as MTooltip, Button } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Bar, BarChart, Label, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import ReactApexChart from "react-apexcharts";

import { API_URL } from "../Config";
import CustomPaper from "../CustomPaper";
import { formatMS, isAdmin } from "../Util";
import User from "../user/User";
import { useTranslation } from "react-i18next";
import Cheats, { Duration } from "./Cheats";

export interface DemoAnalysisProps {
  jwt: string | null;
  user: User | undefined
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2)
    },
    cmdDistributionWrapper: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "50%",
      paddingBottom: theme.spacing(2)
    },
    dogWrapper: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      paddingBottom: theme.spacing(2)
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
  })
);

interface CustomTickProps extends SVGAttributes<SVGTextElement> {
  customXoffset?: number,
  customYoffset?: number,
  labelFormatter: (value: any) => string,
  payload?: any
};

const CustomTick = (props: CustomTickProps) => {
  return <g transform={`translate(${props.customXoffset || 0},${props.customYoffset || 0})`}>
    <text
      style={props.style}
      fill={props.fill}
      height={props.height}
      orientation={props.orientation}
      stroke={props.stroke}
      textAnchor={props.textAnchor}
      type={props.type}
      width={props.width}
      x={props.x}
      y={props.y}
    >
      {props.labelFormatter(props.payload.value)}
    </text>
  </g>;
};

const CustomTooltip = (p: any) => {
  const props = p as { active: boolean, payload: any[], label: string, unit?: string};

  if (props.payload && props.payload.length) {
    return (
      <div style={{ backgroundColor: "black"}}>
        <p>
          {`Distance ${props.payload[0].payload.name} ${props.unit ? props.unit : "units"}`}

          <br/>

          {`Count ${props.payload[0].payload.count}`}
        </p>
      </div>
    );
  }

  return null;
};

const CustomLabel = (props: any) => {
  const { x, y, width, value } = props;
  const radius = 20;

  return (
    <g>
      <circle cx={x + width / 2} cy={y - radius} r={radius} fill="#8884d8" />
      <text x={x + width / 2} y={y - radius} fill="#fff" textAnchor="middle" dominantBaseline="middle">
        {value.split ? value.split(' ')[1] : value}
      </text>
    </g>
  );
};

export default function DemoAnalysis(props: DemoAnalysisProps) {
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams<{ demoMD5: string, map: string, player: string, time: string }>();
  const {t} = useTranslation();

  const [data, set_data] = React.useState<Cheats>();
  const [loading, set_loading] = React.useState(true);
  const [frameLenOffset, set_frameLenOffset] = React.useState(0);

  const maxFramesAtATime = 1000;

  React.useEffect(() => {
    if (! data) {
      return;
    }

    ApexCharts.exec('frameLengthChartId', 'updateSeries', [{
      data: data.frameLengths.sort((a, b) => a.frame < b.frame ? -1 : 1).slice(frameLenOffset * maxFramesAtATime, (frameLenOffset + 1) * maxFramesAtATime).map(fl => fl.length)
    }])
  }, [data, frameLenOffset]);

  React.useEffect(() => {
    fetch(`${API_URL}/demo/check/${params.demoMD5}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
      } else {
        const data = await response.json();
        set_data(data);
      }
    }).catch((reason) => {
      console.error(reason);
    }).finally(() => {
      set_loading(false)
    });
  }, [props.jwt, params.demoMD5]);

  if (! props.jwt || ! props.user || ! isAdmin(props.user)) {
    return <div className={classes.wrapper}>
      {t("unauthorized")}
    </div>;
  }

  if (! data) {
    return <CustomPaper>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size="5rem" color="secondary"/>
      </Backdrop>
    </CustomPaper>;
  }

  interface AdjustedData {
    name: string,
    jump: number | undefined,
  };

  const adjustedJumpData = data.jumpDurations.map((j): AdjustedData => {
    return {
      name: j.duration.toString(),
      jump: j.count,
    };
  });

  interface AdjustedFOGData {
    name: string,
    fog: number
  };

  const adjustedFogData = data.fogs.map((fog): AdjustedFOGData => {
    return {
      name: fog.fog.toString(),
      fog: fog.count,
    };
  });

  interface AdjustedDOGData {
    name: string,
    dog?: number,
    jump?: number
  };

  const adjustedJumpDogData = data.dogs.filter(d => d.jumpInFrame).map((dog): AdjustedDOGData => {
    return {
      name: "",
      dog: dog.distance,
      jump: dog.distance + (dog.jumpInFrame ? 1 : 0)
    };
  });

  const strafesDeltaDistribution = new Map<number, number>();

  for (const strafe of data.strafes) {
    const prevDistrib = strafesDeltaDistribution.get(strafe.delta);

    if (prevDistrib) {
      strafesDeltaDistribution.set(strafe.delta, prevDistrib + 1);
    } else {
      strafesDeltaDistribution.set(strafe.delta, 1);
    }
  }

  const adjustedStrafesDeltaData = Array.from(strafesDeltaDistribution).map(([delta, count]) => {
    return {
      name: delta.toString(),
      count: count,
    };
  }).sort((a, b) => a.count < b.count ? -1 : 1);

  // const addKillZone = React.useCallback();

  const movementCommandsTimelineChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'rangeBar',
      foreColor: theme.palette.text.primary,
      toolbar: {
        tools: {
          download: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '100%'
      }
    },
    xaxis: {
      type: 'datetime',
      title: {
        text: "Frame",
      },
      labels: {
        formatter: (value) => {
          return value.toString();
        }
      }
    },
    yaxis: {
      labels: {
        show: false
      },
    },
    tooltip: {
      theme: "dark",
      x: {
        formatter: (value) => {
          return value.toString();
        },
      },
      y: {
        title: {
          formatter: () => {
            return "";
          }
        }
      }
    },
    title: {
      text: "Movement timeline",
      align: "left"
    },
    stroke: {
      width: 1
    },
    fill: {
      type: 'solid',
      opacity: 0.6
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    grid: {
      padding: {
        bottom: 0,
        top: 0
      }
    },
    annotations: {
      xaxis: [
        {
          x: data.startPressFrame,
          borderColor: 'red',
          label: {
            borderColor: 'red',
            style: {
              color: theme.palette.text.primary,
              background: "transparent",
              fontSize: (theme.typography.fontSize + 4).toString()
            },
            text: "Timer start"
          }
        },
        {
          x: data.endPressFrame,
          borderColor: 'red',
          label: {
            borderColor: 'red',
            style: {
              color: theme.palette.text.primary,
              background: "transparent",
              fontSize: (theme.typography.fontSize + 4).toString()
            },
            text: "Timer end",
          }
        },
        {
          x: data.killFrame,
          borderColor: 'red',
          label: {
            borderColor: 'red',
            style: {
              color: theme.palette.text.primary,
              background: "transparent",
              fontSize: (theme.typography.fontSize + 4).toString()
            },
            text: "Kill",
          }
        }
      ]
    }
  };

  interface DataPoint {
    x: string,
    y: [number, number]
  };

  const movementCommands = [
    {
      name: "Jump",
      data: data.jumps.map((j): DataPoint => {
        return {
          x: "Start - end",
          y: [
            j.startFrame,
            j.endFrame
          ]
        };
      })
    },

    {
      name: "Duck",
      data: data.ducks.map((d): DataPoint => {
        return {
          x: "Start - end",
          y: [
            d.startFrame,
            d.endFrame
          ]
        };
      })
    },

    {
      name: "W",
      data: data.keys.filter(k => k.key === "W").map((k): DataPoint => {
        return {
          x: "Start - end",
          y: [
            k.fromFrame,
            k.toFrame
          ]
        };
      })
    },

    {
      name: "S",
      data: data.keys.filter(k => k.key === "S").map((k): DataPoint => {
        return {
          x: "Start - end",
          y: [
            k.fromFrame,
            k.toFrame
          ]
        };
      })
    },

    {
      name: "A",
      data: data.keys.filter(k => k.key === "A").map((k): DataPoint => {
        return {
          x: "Start - end",
          y: [
            k.fromFrame,
            k.toFrame
          ]
        };
      })
    },

    {
      name: "D",
      data: data.keys.filter(k => k.key === "D").map((k): DataPoint => {
        return {
          x: "Start - end",
          y: [
            k.fromFrame,
            k.toFrame
          ]
        };
      })
    },

    {
      name: "OnGround",
      data: data.groundFlag.map((gf): DataPoint => {
        return {
          x: "Start - end",
          y: [
            gf.frameStart,
            gf.frameEnd
          ]
        };
      })
    }
  ];

  const deltaDistribSorter = (a: Duration, b: Duration) => {
    return a.duration < b.duration ? -1 : 1;
  };

  const jumpDuckDeltaDistributionChartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      foreColor: theme.palette.text.primary,
      toolbar: {
        tools: {
          download: false
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: data.jumpDuckDeltaDistribution.sort(deltaDistribSorter).map(data => data.duration.toString()),
      title: {
        text: 'frames betwee +jump and -duck'
      }
    },
    yaxis: {
      title: {
        text: 'count'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      theme: "dark",
      y: {
        formatter: (val)=> {
          return val + " times"
        }
      }
    },
    grid: {
      show: false
    }
  };

  const jumpDuckDeltaDistribution = [
    {
      name: "Jump/duck delta count",
      data: data.jumpDuckDeltaDistribution.sort(deltaDistribSorter).map(data => data.count)
    }
  ];

  const kpsChartOptions: ApexCharts.ApexOptions = {
    chart: {
      foreColor: theme.palette.text.primary,
      height: 450,
      type: 'line',
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom',
        tools: {
          download: false
        }
      }
    },
    stroke: {
      curve: 'smooth'
    },
    title: {
      text: 'Keys per second',
      align: 'center'
    },
    xaxis: {
      axisTicks: {
        show: false,
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      title: {
        text: 'KPS'
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    tooltip: {
      theme: "dark"
    }
  };

  const kpsData = [
    {
      name: "KPS",
      data: data.kps
    }
  ];

  const frameLenChartOptions: ApexCharts.ApexOptions = {
    chart: {
      foreColor: theme.palette.text.primary,
      height: 450,
      type: 'line',
      id: 'frameLengthChartId',
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000
        }
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom',
        tools: {
          download: false
        }
      }
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Frame length',
      align: 'center'
    },
    xaxis: {
      title: {
        text: "Frame"
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false
      }
    },
    yaxis: {
      title: {
        text: 'Frame length'
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    },
    tooltip: {
      theme: "dark"
    }
  };

  const frameLenData = [
    {
      name: "Frame length",
      data: data.frameLengths.sort((a, b) => a.frame < b.frame ? -1 : 1).slice(frameLenOffset * maxFramesAtATime, (frameLenOffset + 1) * maxFramesAtATime).map(fl => fl.length)
    }
  ];

  return <CustomPaper>
    <div className={classes.wrapper}>
      <Typography variant="overline">
        {t("demo.analysis.title")}
      </Typography>

      <div style={{display: "flex", gap: theme.spacing(1)}}>
        <Typography variant="h5">
          {t("demo.analysis.subttitle", {
            map: params.map,
            player: params.player,
            time: formatMS(Number.parseInt(params.time))
          })}
        </Typography>

        <Typography style={{ color: data.probablyCheated ? "red" : "green" }} variant="h5">
          {data.probablyCheated ? t("demo.analysis.probably_cheat2", { cheat: data.cheatType }) : t("demo.analysis.probably_clean2")}
        </Typography>
      </div>

      <Typography>
        Bhops: {data.totalBhops}
      </Typography>

      <Typography>
        Perfect bhops: {data.totalPerfectBhops} ({(data.totalPerfectBhops / data.totalBhops * 100).toFixed(2)}%)
      </Typography>

      <Typography>
        Strafes: {data.totalStrafes}
      </Typography>

      <MTooltip title="-moveleft and +moveright (and reverse) in the same frame">
        <Typography>
          Perfect KB strafes: {data.totalPerfectStrafes} ({(data.totalPerfectStrafes / data.totalStrafes * 100).toFixed(2)}%)
        </Typography>
      </MTooltip>

      <MTooltip title="player in air due to jumps or ducks in % (<90% is possibly a hack)">
        <Typography>
          OffGround due to +jump and +duck: {((data.jumpsThatCauseOffGround + data.ducksThatCauseOffGround) / data.offGroundCount * 100).toFixed(2)}%
        </Typography>
      </MTooltip>

      <div className={classes.dogWrapper} style={{
        paddingTop: theme.spacing(4)
      }}>
        <ReactApexChart options={movementCommandsTimelineChartOptions} series={movementCommands} type="rangeBar" height={450}/>
      </div>


      <div className={classes.dogWrapper} style={{
        paddingTop: theme.spacing(4)
      }}>
        <ReactApexChart options={jumpDuckDeltaDistributionChartOptions} series={jumpDuckDeltaDistribution} type="bar" height={450} />
      </div>

      <div className={classes.dogWrapper} style={{
        paddingTop: theme.spacing(4)
      }}>
        <ReactApexChart options={kpsChartOptions} series={kpsData} type="line" height={450} />
      </div>

      <div className={classes.dogWrapper} style={{
        paddingTop: theme.spacing(4)
      }}>
        <ReactApexChart options={frameLenChartOptions} series={frameLenData} type="line" height={450} />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: theme.spacing(4)
          }}
        >
          <Button
            disabled={frameLenOffset === 0}
            variant="contained"
            color="primary"
            onClick={() => {
              set_frameLenOffset(old => old - 1);
            }}
          >
            {`Shift left by ${maxFramesAtATime} frames`}
          </Button>

          <Button
            disabled={(frameLenOffset + 1) * maxFramesAtATime > data.frameLengths.length}
            variant="contained"
            color="primary"
            onClick={() => {
              set_frameLenOffset(old => old + 1);
            }}
          >
            {`Shift right by ${maxFramesAtATime} frames`}
          </Button>
        </div>
      </div>

      <div className={classes.cmdDistributionWrapper}>
        <ResponsiveContainer width="100%" height="100%" minHeight={600}>
          <BarChart
            width={200}
            height={600}
            data={adjustedJumpData}
            margin={{
              top: 30,
              right: 30,
              bottom: 15
            }}
            barSize={20}
          >
            <XAxis
              dataKey={(obj: any) => obj.name}
              scale="auto"
              padding={{ left: 20, right: 10 }}
              tick={<CustomTick
                customYoffset={15}
                style={{
                  fill: theme.palette.text.primary,
                }}
                labelFormatter={(value: any) => {
                  return value;
                }}
              />}
            >
              <Label
                value={`${t("demo.analysis.graph.duration")}`}
                offset={-10}
                position="insideBottom"
                style={{
                  fill: theme.palette.text.primary
                }}
              />
            </XAxis>

            <YAxis
              label={{
                value: t("demo.analysis.graph.occurences"),
                angle: -90,
                position: 'insideLeft',
                style: {
                  fill: theme.palette.text.primary
                }
              }}
              tick={<CustomTick
                customYoffset={15}
                style={{
                  fill: theme.palette.text.primary,
                }}
                labelFormatter={(value: any) => {
                  return value;
                }}
              />}
            />

            <Tooltip
              contentStyle={{ backgroundColor: theme.palette.background.paper}}
              labelFormatter={(_, payload: any) => {
                if (! payload || payload.length === 0) {
                  return "";
                }

                return t("demo.analysis.graph.tooltip", { count: payload[0].payload.name });
              }}
            />

            <Bar dataKey="jump" fill={theme.palette.primary.light}/>
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className={classes.cmdDistributionWrapper}>
        <ResponsiveContainer width="100%" height="100%" minHeight={600}>
          <BarChart
            width={200}
            height={600}
            data={adjustedFogData}
            margin={{
              top: 30,
              right: 30,
              bottom: 15
            }}
            barSize={20}
          >
            <XAxis
              dataKey={(obj: any) => obj.name}
              scale="auto"
              padding={{ left: 20, right: 10 }}
              tick={<CustomTick
                customYoffset={15}
                style={{
                  fill: theme.palette.text.primary,
                }}
                labelFormatter={(value: any) => {
                  return value;
                }}
              />}
            >
              <Label
                value="FOG"
                offset={-10}
                position="insideBottom"
                style={{
                  fill: theme.palette.text.primary
                }}
              />
            </XAxis>

            <YAxis
              label={{
                value: t("demo.analysis.graph.occurences"),
                angle: -90,
                position: 'insideLeft',
                style: {
                  fill: theme.palette.text.primary
                }
              }}
              tick={<CustomTick
                customYoffset={15}
                style={{
                  fill: theme.palette.text.primary,
                }}
                labelFormatter={(value: any) => {
                  return value;
                }}
              />}
            />

            <Tooltip
              contentStyle={{ backgroundColor: theme.palette.background.paper}}
              labelFormatter={(_, payload: any) => {
                if (! payload || payload.length === 0) {
                  return "";
                }

                return t("demo.analysis.graph.tooltip", { count: payload[0].payload.name });
              }}
            />

            <Bar dataKey="fog" fill={theme.palette.primary.light}/>
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className={classes.dogWrapper}>
        <ResponsiveContainer width="100%" height="100%" minHeight={1000}>
          <BarChart
            width={600}
            height={1000}
            data={adjustedJumpDogData}
            margin={{
              top: 30,
              right: 30,
              bottom: 15
            }}
            barSize={20}
          >
            <XAxis
              dataKey={(obj: any) => obj.frame}
              scale="auto"
              padding={{ left: 20, right: 10 }}
              tick={<CustomTick
                customYoffset={15}
                style={{
                  fill: theme.palette.text.primary,
                }}
                labelFormatter={(value: any) => {
                  return value;
                }}
              />}
            >
              <Label
                value={""}
                offset={-10}
                position="insideBottom"
                style={{
                  fill: theme.palette.text.primary
                }}
              />
            </XAxis>

            <YAxis
              label={{
                value: "Distance",
                angle: -90,
                position: 'insideLeft',
                style: {
                  fill: theme.palette.text.primary
                }
              }}
              tick={<CustomTick
                customYoffset={15}
                style={{
                  fill: theme.palette.text.primary,
                }}
                labelFormatter={(value: any) => {
                  return value;
                }}
              />}
            />

            <Tooltip content={<CustomTooltip/>}/>

            <Bar dataKey="dog" fill={theme.palette.primary.light} minPointSize={5}>
              <LabelList dataKey="jump" content={CustomLabel}/>
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className={classes.dogWrapper}>
        <ResponsiveContainer width="100%" height="100%" minHeight={1000}>
          <BarChart
            width={600}
            height={1000}
            data={adjustedStrafesDeltaData}
            margin={{
              top: 30,
              right: 30,
              bottom: 15
            }}
            barSize={20}
          >
            <XAxis
              dataKey={(obj: any) => obj.name}
              scale="auto"
              padding={{ left: 20, right: 10 }}
              tick={<CustomTick
                customYoffset={15}
                style={{
                  fill: theme.palette.text.primary,
                }}
                labelFormatter={(value: any) => {
                  return value;
                }}
              />}
            >
              <Label
                value={""}
                offset={-10}
                position="insideBottom"
                style={{
                  fill: theme.palette.text.primary
                }}
              />
            </XAxis>

            <YAxis
              label={{
                value: "Delta (frames)",
                angle: -90,
                position: 'insideLeft',
                style: {
                  fill: theme.palette.text.primary
                }
              }}
              tick={<CustomTick
                customYoffset={15}
                style={{
                  fill: theme.palette.text.primary,
                }}
                labelFormatter={(value: any) => {
                  return value;
                }}
              />}
            />

            <Tooltip content={<CustomTooltip unit="frames"/>}/>

            <Bar dataKey="count" fill={theme.palette.primary.light}/>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  </CustomPaper>;
};
