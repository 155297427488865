import React from "react";

import {
  Accordion,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  createStyles,
  Dialog,
  Fade,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import AuthorLink from "../user/AuthorLink";

import DemoTimeLinkComp from "../demo/DemoTimeLinkComp";
import DemoWaitlistData from "../demo/DemoWaitlistData";
import DemoWaitlistStatus from "../demo/DemoWaitlistStatus";
import MapLinkComp from "../demo/MapLinkComp";

import { DemoReleaseNewsItem } from "./NewsItem";
import RegularNewsItemComp from "./RegularNewsItemComp";

import { formatMS, groupBy } from "../Util";

import ViewDemoLink from "../viewdemo/ViewDemoLink";
import { useTranslation } from "react-i18next";
import Weapon, { wpnToString } from "../demo/Weapon";
import WorldRecord from "../demo/WorldRecord";
import ViewMapLinkComp from "../demo/page/ViewMapLinkComp";
import User from "../user/User";

export interface DemoNewsItemCompProps {
  jwt: string | null;
  item: DemoReleaseNewsItem;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    demoReleaseBannerImg: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      pointerEvents: "none",
      height: "auto",
      width: "95%",
      maxWidth: "60vw",
    },
    mapTimeWrWrapper: {
      display: "flex",
      gap: theme.spacing(1),
    },
    rejectedDemosWrapper: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
    dialogWrapper: {
      padding: theme.spacing(4),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fffffff",
    },
    prevRecordHolderAvatarWrapper: {
      paddingLeft: theme.spacing(1),
      position: "relative",
      top: -theme.spacing(0.3),
    },
  })
);

export default function DemoNewsItemComp(props: DemoNewsItemCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const [expanded, set_expanded] = React.useState(true);

  const players = React.useMemo(() => {
    const players: User[] = [];

    for (const demo of props.item.demos) {
      if (!demo.uploader) {
        continue;
      }

      const uploader = demo.uploader;

      if (
        players.findIndex((item) => item.steamID === uploader.steamID) === -1
      ) {
        players.push(uploader);
      }
    }

    return players.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }, [props.item]);

  const demosGroupedByPlayer = groupBy(
    props.item.demos,
    (demo: DemoWaitlistData) => demo.uploader!.displayName
  );
  const sortedByPlayer = Array.from(demosGroupedByPlayer.entries()).sort(
    (a, b) => a[0].localeCompare(b[0])
  );
  const [mustLoginDialogVisible, set_mustLoginDialogVisible] =
    React.useState(false);
  const [downloading, set_downloading] = React.useState(false);

  const formatPreviousRec = React.useCallback(
    (demo: DemoWaitlistData, newsItem: DemoReleaseNewsItem) => {
      const prevRecord = newsItem.beatDemos.find(
        (d) => d.map === demo.map && d.weapon === demo.weapon
      );

      if (prevRecord && prevRecord.uploader) {
        return (
          <i>
            <Typography variant="caption" style={{ display: "flex" }}>
              ({formatMS(prevRecord.time)}{" "}
              {
                <div className={classes.prevRecordHolderAvatarWrapper}>
                  <AuthorLink
                    author={prevRecord.uploader}
                    steam_id={prevRecord.uploader.steamID}
                    avatar
                    small
                  />
                </div>
              }
              )
            </Typography>
          </i>
        );
      }

      return null;
    },
    [classes.prevRecordHolderAvatarWrapper]
  );

  const formatWR = React.useCallback(
    (demo: DemoWaitlistData, wrs: WorldRecord[]) => {
      const wr = wrs.find((wr) => wr.mapLowerCase === demo.map.toLowerCase());

      if (wr) {
        const wrTime = wr.time;
        const demoTime = Math.floor(demo.time / 10) * 10;

        if (demoTime <= wrTime) {
          return (
            <Typography style={{ color: "red" }}>
              {t("news.demo.wr")}
            </Typography>
          );
        }

        return (
          <Typography
            variant="caption"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {t("news.demo.delta_wr.1", { time: formatMS(demoTime - wrTime) })}

            <div
              style={{
                display: "flex",
                marginLeft: theme.spacing(
                  !["XJ", "COSY"].includes(wr.source) ? 1 : 0
                ),
              }}
            >
              <ViewDemoLink
                jwt={props.jwt}
                demo={{
                  map: wr.mapOriginalCase,
                  player: wr.player,
                  time: wr.time,
                }}
                xj={wr.source === "XJ"}
                cosy={wr.source === "COSY"}
              />
            </div>

            {t("news.demo.delta_wr.2")}
          </Typography>
        );
      }

      return null;
    },
    [t, props.jwt, theme]
  );

  const formatWeapon = React.useCallback((demo: DemoWaitlistData) => {
    if (demo.weapon !== Weapon.USP_OR_KNIFE) {
      return (
        <Typography
          style={{
            color: "yellow",
          }}
        >
          {wpnToString(demo.weapon)}
        </Typography>
      );
    }

    return null;
  }, []);

  return (
    <div>
      <img
        src="/demoReleaseBanner2.jpg"
        alt="romanian jumpers demo release banner"
        className={classes.demoReleaseBannerImg}
      />

      <Dialog
        onClose={() => set_mustLoginDialogVisible(false)}
        open={mustLoginDialogVisible}
      >
        <div className={classes.dialogWrapper}>
          <Typography>{t("news.demo.download_view_login_required")}</Typography>
        </div>
      </Dialog>

      <Backdrop className={classes.backdrop} open={downloading}>
        <CircularProgress size="5rem" color="secondary" />
      </Backdrop>

      <Accordion
        style={{
          backgroundColor: "transparent",
        }}
        square
        expanded={expanded}
        onChange={() => set_expanded((old) => !old)}
      >
        <AccordionSummary
          expandIcon={
            <Tooltip title={`${t("news.demo.collapse_demos_list")}`}>
              <ExpandMoreIcon />
            </Tooltip>
          }
        />

        <List>
          {sortedByPlayer.map(([_, demos], index) => {
            const aprovedDemos = demos
              .filter(
                (d) =>
                  d.status === DemoWaitlistStatus.Released ||
                  d.status === DemoWaitlistStatus.Beat
              )
              .sort((l, r) => l.map.localeCompare(r.map));

            if (aprovedDemos.length === 0) {
              return null;
            }

            const uploader = aprovedDemos[0].uploader;

            if (!uploader) {
              return null;
            }

            const currentPlayer = players.find(
              (p) => p.steamID === uploader.steamID
            );

            if (!currentPlayer) {
              return null;
            }

            return (
              <Fade in timeout={500 + 200 * index} unmountOnExit key={index}>
                <ListItem>
                  <ListItemText
                    disableTypography
                    primary={
                      <>
                        <AuthorLink
                          author={currentPlayer}
                          steam_id={currentPlayer.steamID}
                          avatar
                        />
                      </>
                    }
                    secondary={
                      <List>
                        {aprovedDemos.map((demo, index2) => (
                          <Fade
                            in
                            timeout={500 + 200 * index * index2}
                            unmountOnExit
                            key={`${index}_${index2}`}
                          >
                            <ListItem>
                              <ListItemText
                                disableTypography
                                primary={
                                  <div className={classes.mapTimeWrWrapper}>
                                    {index2 + 1}.
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: theme.spacing(1),
                                      }}
                                    >
                                      <MapLinkComp map={demo.map} useColor />

                                      <ViewMapLinkComp
                                        map={demo.map}
                                        jwt={props.jwt}
                                        on_unauthorized={() => {}}
                                        set_downloading={(state) => {
                                          set_downloading(state);
                                        }}
                                      />
                                    </div>
                                    <Typography>
                                      {t("news.demo.done_in")}{" "}
                                      <DemoTimeLinkComp
                                        jwt={props.jwt}
                                        demo={demo}
                                        set_downloading={set_downloading}
                                        on_unauthorized={() =>
                                          set_mustLoginDialogVisible(true)
                                        }
                                        useColor
                                      />
                                    </Typography>
                                    {formatWeapon(demo)}
                                    <ViewDemoLink
                                      jwt={props.jwt}
                                      demo={demo}
                                      on_unauthorized={() =>
                                        set_mustLoginDialogVisible(true)
                                      }
                                    />
                                    {formatPreviousRec(demo, props.item)}
                                    {formatWR(demo, props.item.wrs)}
                                  </div>
                                }
                              />
                            </ListItem>
                          </Fade>
                        ))}
                      </List>
                    }
                  />
                </ListItem>
              </Fade>
            );
          })}
        </List>

        {props.item.demos.filter(
          (d) => d.status === DemoWaitlistStatus.Rejected
        ).length !== 0 && (
          <div className={classes.rejectedDemosWrapper}>
            <Typography>{t("news.demo.rejected")}</Typography>

            <List dense>
              {sortedByPlayer.map(([_, demos], index) => {
                const rejectedDemos = demos
                  .filter((d) => d.status === DemoWaitlistStatus.Rejected)
                  .sort((l, r) => l.map.localeCompare(r.map));

                if (rejectedDemos.length === 0) {
                  return null;
                }

                const uploader = rejectedDemos[0].uploader;

                if (!uploader) {
                  return null;
                }

                const currentPlayer = players.find(
                  (p) => p.steamID === uploader.steamID
                );

                if (!currentPlayer) {
                  return null;
                }

                return rejectedDemos.map((demo, index2) => (
                  <Fade
                    in
                    timeout={500 + 200 * (index * index2)}
                    unmountOnExit
                    key={`${index}_${index2}`}
                  >
                    <ListItem>
                      <ListItemText
                        primary={
                          <>
                            {demo.map} {currentPlayer.displayName}{" "}
                            {formatMS(demo.time)} - <i>{demo.reason}</i>
                          </>
                        }
                      />
                    </ListItem>
                  </Fade>
                ));
              })}
            </List>
          </div>
        )}
      </Accordion>

      {props.item.content.length !== 0 && (
        <RegularNewsItemComp jwt={props.jwt} content={props.item.content} />
      )}
    </div>
  );
}
