import { Backdrop, Button, CircularProgress, createStyles, FormControlLabel, makeStyles, Switch, TextField, Theme, Typography, useTheme } from "@material-ui/core";
import React, { CSSProperties } from "react";
import { API_URL } from "../../Config";
import CustomPaper from "../../CustomPaper";

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as RWList } from 'react-window';
import { MapInfo } from "../../map/MapDataDTO";
import ServerAdminMapRow from "./ServerAdminMapRow";
import ServerAdminMapTypeComp from "./ServerAdminMapTypeComp";
import { types, typesT } from "./Types";
import { difficulties, difficultiesT } from "./Difficulties";
import ServerAdminMapDifficultyComp from "./ServerAdminMapDifficultyComp";
import ReactApexChart from "react-apexcharts";

export interface ServerAdminProps {
  jwt: string | null
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
  })
);

export default function ServerAdmin(props: ServerAdminProps) {
  const classes = useStyles();
  const theme = useTheme();

  const [communityMapsUnsorted, set_communityMapsUnsorted] = React.useState<MapInfo[]>([]);
  const [loading, set_loading] = React.useState(true);
  const [filterText, set_filterText] = React.useState("");

  const [communityMaps, set_communityMaps] = React.useState<MapInfo[]>([]);
  const [selectedMapTypes, set_selectedMapTypes] = React.useState<number[]>(types.map((_, i) => i));
  const [selectedMapDifficulties, set_selectedMapDifficulties] = React.useState<number[]>(difficulties.map((_, i) => i));
  const [includeNoType, set_includeNoType] = React.useState(false);
  const [includeNoDifficulty, set_includeNoDifficulty] = React.useState(false);
  const [includeAlreadyOnServers, set_includeAlreadyOnServers] = React.useState(false);

  const [forceCupSrv, set_forceCupSrv] = React.useState(false);

  const [isCupSrvUp, set_isCupSrvUp] = React.useState(false);

  interface SpaceInfo {
    percentUsed: number,
  };

  interface ServersSpaceInfo {
    easy?: SpaceInfo,
    hard?: SpaceInfo,
    cups?: SpaceInfo,
  };

  const [serversSpaceInfo, set_serversSpaceInfo] = React.useState<ServersSpaceInfo>();

  const toTypeArr = (str: string) => {
    return str.split(",").map(diff => diff as typesT);
  };

  const toDifficultyArr = (str: string | null) => {
    if (!str) {
      return [];
    }

    return str.split(",").map(diff => diff as difficultiesT);
  };

  const selectedMapTypesStrs = React.useMemo(() => selectedMapTypes.map(mt => types[mt]), [selectedMapTypes]);
  const selectedMapDifficultiesStrs = React.useMemo(() => selectedMapDifficulties.map(md => difficulties[md]), [selectedMapDifficulties]);

  React.useEffect(() => {
    set_communityMaps(communityMapsUnsorted
      .filter(r => filterText.length === 0 ? true : r.n.toLowerCase().includes(filterText.toLowerCase()))
      // .filter(r => {
      //   if (includeAlreadyOnServers) {
      //     return true;
      //   }

      //   return !r.onServers;
      // })
      .filter(r => {
        if (r.t && selectedMapTypesStrs.length !== 0) {
          const mapTypes = toTypeArr(r.t);

          for (const type of mapTypes) {
            if (!selectedMapTypesStrs.includes(type)) {
              return false;
            }
          }

          return true;
        } else {
          if (!r.t) {
            return includeNoType;
          }

          return false;
        }
      })
      .filter(r => {
        if (r.d && selectedMapDifficultiesStrs.length !== 0) {
          const mapDifficulties = toDifficultyArr(r.d);

          for (const diff of mapDifficulties) {
            if (!selectedMapDifficultiesStrs.includes(diff)) {
              return false;
            }
          }

          return true;
        } else {
          if (!r.d) {
            return includeNoDifficulty;
          }

          return false;
        }
      })
      .sort((a, b) => a.n.localeCompare(b.n)));
  }, [communityMapsUnsorted, filterText, selectedMapTypesStrs, selectedMapDifficultiesStrs, includeNoType, includeNoDifficulty, includeAlreadyOnServers]);

  const updateSpaceStats = React.useCallback(() => {
    if (isCupSrvUp) {
      fetch(`${API_URL}/server_admin/space?forceCup=1`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${props.jwt}`
        }
      }).then(async (response) => {
        if (response.status !== 200) {
          // TODO error handling
        } else {
          const data = await response.json();

          set_serversSpaceInfo(old => {
            return {
              ...old,
              cups: data.cups,
            };
          });
        }
      });
    }

    return fetch(`${API_URL}/server_admin/space`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const data = await response.json();

        set_serversSpaceInfo(old => {
          return {
            ...old,
            easy: data.easy,
            hard: data.hard
          };
        });
      }
    });
  }, [props.jwt, isCupSrvUp]);

  React.useEffect(() => {
    set_loading(true);

    fetch(`${API_URL}/server_admin/maps`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_communityMapsUnsorted(await response.json());
      }
    }).finally(() => {
      updateSpaceStats().finally(() => set_loading(false));
    });
  }, [props.jwt, updateSpaceStats, forceCupSrv]);

  React.useEffect(() => {
    set_loading(true);

    fetch(`${API_URL}/server_admin/server_status/cups`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const reply = await response.json();

        set_isCupSrvUp(reply.status === "up");
      }
    });
  }, [props.jwt]);

  const startCupsServer = React.useCallback(() => {
    set_loading(true);

    fetch(`${API_URL}/server_admin/start_server/cups`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {

      }
    }).finally(() => {
      set_loading(false);
    });
  }, [props.jwt]);

  const stopCupsServer = React.useCallback(() => {
    set_loading(true);

    fetch(`${API_URL}/server_admin/stop_server/cups`, {
      method: "POST",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {

      }
    }).finally(() => {
      set_loading(false);
    });
  }, [props.jwt]);

  const RowHeight = 75;

  const Row = (input: { index: number, style: CSSProperties }) => {
    const map = communityMaps[input.index];

    return <div
      style={{
        height: `${RowHeight}px`,
        ...input.style,
      }}
    >
      <ServerAdminMapRow
        forceCup={forceCupSrv}
        jwt={props.jwt}
        index={input.index}
        mapInfo={map}
        set_loading={(state: boolean) => {
          if (!state) {
            updateSpaceStats().finally(() => set_loading(false));
          } else {
            set_loading(state);
          }
        }}
      />
    </div>;
  };

  const makeChartOptions = (server: "Easy" | "Hard" | "Cups"): ApexCharts.ApexOptions => {
    return {
      chart: {
        height: 350,
        type: 'radialBar',
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            background: theme.palette.background.default,
            margin: 0,
            size: '70%',
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: 'front',
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24
            }
          },
          track: {
            background: theme.palette.background.default,
            strokeWidth: '70%',
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35
            }
          },
          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: '#888',
              fontSize: '17px'
            },
            value: {
              formatter: (val: number) => {
                return val.toFixed(2);
              },
              color: theme.palette.text.primary,
              fontSize: '36px',
              show: true,
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'horizontal',
          shadeIntensity: 0.5,
          gradientToColors: ['#ABE5A1'],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100]
        }
      },
      stroke: {
        lineCap: 'round'
      },
      labels: ['% full'],
      title: {
        text: `${server} server % of disk used`,
        style: {
          color: theme.palette.text.secondary
        }
      }
    };
  };

  const easySeries = [(serversSpaceInfo && serversSpaceInfo.easy) ? serversSpaceInfo.easy.percentUsed : 0];
  const hardSeries = [(serversSpaceInfo && serversSpaceInfo.hard) ? serversSpaceInfo.hard.percentUsed : 0];
  const cupSeries = [(isCupSrvUp && serversSpaceInfo && serversSpaceInfo.cups) ? serversSpaceInfo.cups.percentUsed : 0];

  return <CustomPaper>
    <div style={{
      padding: theme.spacing(2),
      minHeight: 1000,
    }}>
      <Typography variant="overline">
        Community maps
      </Typography>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size="5rem" color="secondary" />
      </Backdrop>

      {serversSpaceInfo &&
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: theme.spacing(6)
          }}
        >
          <ReactApexChart options={makeChartOptions("Easy")} series={easySeries} type="radialBar" height={350} />
          <ReactApexChart options={makeChartOptions("Hard")} series={hardSeries} type="radialBar" height={350} />
          {(isCupSrvUp && serversSpaceInfo.cups) && <ReactApexChart options={makeChartOptions("Cups")} series={cupSeries} type="radialBar" height={350} />}
        </div>
      }

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: theme.spacing(4),
          paddingBottom: theme.spacing(4),
          gap: theme.spacing(6)
        }}
      >
        {!isCupSrvUp
          ? <Button
            variant="contained"
            color="primary"
            onClick={startCupsServer}
          >
            Start CUPS server
          </Button>
          : <Button
            variant="contained"
            color="primary"
            onClick={stopCupsServer}
          >
            Stop CUPS server
          </Button>
        }
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: theme.spacing(2)
        }}
      >
        <TextField
          value={filterText}
          onChange={(e) => {
            set_filterText(e.target.value.toLowerCase());
          }}
          autoComplete="off"
          variant="outlined"
          fullWidth
          label={"Filter by map name"}
          style={{
            paddingBottom: theme.spacing(2)
          }}
        />

        <ServerAdminMapTypeComp
          types={selectedMapTypes}
          set_types={set_selectedMapTypes}
          fullWidth
        />

        <ServerAdminMapDifficultyComp
          difficulties={selectedMapDifficulties}
          set_difficulties={set_selectedMapDifficulties}
          fullWidth
        />

        <div>
          <FormControlLabel
            control={
              <Switch
                checked={includeNoType}
                onChange={(e) => set_includeNoType(e.target.checked)}
              />
            }
            label="Include no type maps"
          />

          <FormControlLabel
            control={
              <Switch
                checked={includeNoDifficulty}
                onChange={(e) => set_includeNoDifficulty(e.target.checked)}
              />
            }
            label="Include no difficulty maps"
          />

          <FormControlLabel
            control={
              <Switch
                checked={includeAlreadyOnServers}
                onChange={(e) => set_includeAlreadyOnServers(e.target.checked)}
              />
            }
            label="Include maps already on servers"
          />

          {(serversSpaceInfo && serversSpaceInfo.cups) && <FormControlLabel
            control={
              <Switch
                checked={forceCupSrv}
                onChange={(e) => set_forceCupSrv(e.target.checked)}
              />
            }
            label="Force cup server"
          />
          }
        </div>
      </div>

      <AutoSizer>
        {(param: { width: number }) => (
          <RWList
            height={1000}
            width={param.width}
            itemSize={RowHeight}
            itemCount={communityMaps.length}
          >
            {Row}
          </RWList>
        )}
      </AutoSizer>
    </div>
  </CustomPaper>;
};
