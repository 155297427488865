import { Avatar, Backdrop, Badge, CircularProgress, createStyles, Fade, FormControl, Input, InputLabel, ListSubheader, makeStyles, MenuItem, Select, Theme, useTheme } from "@material-ui/core";
import React from "react";
import { API_URL } from "../Config";
import jszip from 'jszip';
import RecordFileData from "../viewdemo/RecordFileData";
import { formatMS } from "../Util";

import { UnregisteredUser } from "../server/ServerRecord";
import { useTranslation } from "react-i18next";
import GraphsComp, { FogData, IndexedRecording } from "./GraphsComp";
import ReactCountryFlag from "react-country-flag";
import User from "../user/User";

export interface MapGraphsCompProps {
  user: User | undefined,
  jwt: string | null,
  map: string,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartWrapper: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "100%",
      paddingBottom: theme.spacing(2)
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 150,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
  })
);

export default function MapGraphsComp(props: MapGraphsCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  interface RunData {
    url: string,
    data: RecordFileData,
    user: UnregisteredUser
  };

  const [loading, set_loading] = React.useState(true);
  const [runLinks, set_runLinks] = React.useState<RunData[]>([]);

  const [recordings, set_recordings] = React.useState<IndexedRecording[]>([]);

  const [availablePlayers, set_availablePlayers] = React.useState<UnregisteredUser[]>([]);

  const [selectedPlayer, set_selectedPlayer] = React.useState<string>("");

  const playerWeaponsRecordings = React.useMemo(() => {
    const sorter = (a: IndexedRecording, b: IndexedRecording) => {
      if (a.data.time <= b.data.time) {
        return -1;
      }

      return 1;
    };

    return {
      usp: recordings.filter(rec => "usp" === rec.data.weapon || "knife" === rec.data.weapon).sort(sorter),
      scout: recordings.filter(rec => rec.data.weapon === "scout").sort(sorter),
      p90: recordings.filter(rec => rec.data.weapon === "p90").sort(sorter),
      famas: recordings.filter(rec => rec.data.weapon === "famas").sort(sorter),
      sg552: recordings.filter(rec => rec.data.weapon === "sg552").sort(sorter),
      m4a1: recordings.filter(rec => rec.data.weapon === "m4a1").sort(sorter),
      ak47: recordings.filter(rec => rec.data.weapon === "ak47").sort(sorter),
      m249: recordings.filter(rec => rec.data.weapon === "m249").sort(sorter),
      awp: recordings.filter(rec => rec.data.weapon === "awp").sort(sorter),
      shield: recordings.filter(rec => rec.data.weapon === "shield").sort(sorter),
    };
  }, [recordings]);

  const getSelectItems = React.useCallback(() => {
    const getGroup = (wpn: keyof typeof playerWeaponsRecordings, label: string): JSX.Element[] => {
      let result: JSX.Element[] = [];

      if (playerWeaponsRecordings[wpn].length !== 0) {
        result.push(<ListSubheader key={label}>{label} ({playerWeaponsRecordings[wpn].length})</ListSubheader>);

        for (let index = 0; index < playerWeaponsRecordings[wpn].length; ++index) {
          const data = playerWeaponsRecordings[wpn][index];

          if (data.data.server === "cups") {
            result.push(<MenuItem key={`${label}_${index}`} value={data.index}>
              <Badge overlap="rectangular" badgeContent="Cup" color="error">
                {formatMS(data.data.time)}
              </Badge>
            </MenuItem>);
          } else {
            result.push(<MenuItem key={`${label}_${index}`} value={data.index}>
              {formatMS(data.data.time)}
            </MenuItem>);
          }
        }
      }

      return result;
    };

    let results: JSX.Element[] = [];

    results.push(...getGroup("usp", "UPS/Knife"));
    results.push(...getGroup("scout", "Scout"));
    results.push(...getGroup("p90", "P90"));
    results.push(...getGroup("famas", "Famas"));
    results.push(...getGroup("sg552", "SG552"));
    results.push(...getGroup("m4a1", "M4A1"));
    results.push(...getGroup("ak47", "AK47"));
    results.push(...getGroup("m249", "M249"));
    results.push(...getGroup("awp", "AWP"));
    results.push(...getGroup("shield", "Shield"));

    return results;
  }, [playerWeaponsRecordings]);

  const handleSelectedPlayerChange = React.useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    // prevent group header from being "selected"
    if (event.target.value === undefined) {
      return;
    }

    set_selectedPlayer(event.target.value as string);
    set_selectedRunsIndices([]);
  }, []);

  const [selectedRunsIndices, set_selectedRunsIndices] = React.useState<number[]>([]);

  const playerRecordings = React.useMemo(() => {

    return recordings.filter(run => selectedRunsIndices.includes(run.index));
  }, [recordings, selectedRunsIndices]);

  const handleSelectedRunsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const data = event.target.value as (number | undefined)[];

    // prevent group header from being "selected"
    if (data.length !== 0 && data[0] === undefined) {
      return;
    }

    set_selectedRunsIndices(() => {
      return data as number[];
    });
  };

  React.useEffect(() => {
    fetch(`${API_URL}/recordings/map/${props.map}/players`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
      } else {
        const data = (await response.json()) as UnregisteredUser[];

        set_availablePlayers(data.filter(p => p !== null));
      }
    }).catch((reason) => {
      console.error(reason);
    }).finally(() => {
      set_loading(false);
    });
  }, [props.jwt, props.map]);

  React.useEffect(() => {
    if (selectedPlayer.length === 0) {
      set_recordings([]);

      return;
    }

    const player = availablePlayers.find((player) => player.steamID === selectedPlayer);

    if (!player) {
      return;
    }

    set_loading(true);

    fetch(`${API_URL}/recordings/map/${props.map}/player/${player.steamID}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
      } else {
        const data = await response.json();

        set_runLinks(data);
      }
    }).catch((reason) => {
      console.error(reason);
    }).finally(() => {
      set_loading(false);
    });
  }, [availablePlayers, selectedPlayer, props.jwt, props.map]);

  React.useEffect(() => {
    if (runLinks.length === 0) {
      return;
    }

    Promise.all(runLinks.map(async (rd, index): Promise<IndexedRecording | null> => {
      try {
        const rlZipResponse = await fetch(rd.url);

        if (rlZipResponse.status !== 200 && rlZipResponse.status !== 0) {
          console.error(`Could not download ${rd.url}`);
          return null;
        }

        const rlZip = await jszip.loadAsync(await rlZipResponse.blob());
        const files = rlZip.files;

        let fogData: FogData[] | null = null;
        // let ljData: LjData[] | null = null;

        for (const file in files) {
          const fname = files[file].name;
          const isFogFile = fname.endsWith(".fog.json");
          // const isLjFile = fname.endsWith(".lj.json");

          if (isFogFile) {
            const fileContent = await files[file].async("string");

            const adjustedContent = fileContent[0] === ',' ? '[' + fileContent.substring(1) : fileContent;

            fogData = JSON.parse(adjustedContent.replaceAll("}{", "},{"));
            // } else if (isLjFile) {
            //   ljData = JSON.parse(await files[file].async("string"));
          }
        }


        if (!fogData/*  || !ljData */) {
          console.error("no fogdata");

          return null;
        }

        return {
          index: index,
          fog: fogData,
          lj: [],/* ljData, */
          data: rd.data,
          user: rd.user
        };
      } catch (e) {
        console.error(e);
        return null;
      }
    })).then((results) => {
      const data = (results.filter(r => r !== null) as IndexedRecording[]);

      set_recordings(data);
      set_loading(false);
    });
  }, [runLinks]);

  interface ByCountry {
    [key: string]: UnregisteredUser[],
    unknownCountry: UnregisteredUser[]
  };

  const [playersByCountry, set_playersByCountry] = React.useState<ByCountry>({
    unknownCountry: []
  });

  React.useEffect(() => {
    set_playersByCountry(() => {
      const result: ByCountry = {
        unknownCountry: []
      };

      for (const player of availablePlayers) {
        if (!player.countryCode || player.countryCode.length === 0) {
          result.unknownCountry.push(player);
        } else {
          const cc = player.countryCode.toUpperCase();

          if (!result[cc]) {
            result[cc] = [];
          }

          result[cc].push(player);
        }
      }

      return result;
    });
  }, [availablePlayers]);

  const getGroupedPlayers = React.useCallback(() => {
    const countrySorter = (a: [string, UnregisteredUser[]], b: [string, UnregisteredUser[]]) => {
      if (a[0] === "unknownCountry") {
        return 1;
      }

      if (b[0] === "unknownCountry") {
        return -1;
      }

      return a[0].localeCompare(b[0]);
    };

    return Object.entries(playersByCountry).sort(countrySorter).map(([country, players], index) => {
      let results: JSX.Element[] = [];

      if (country !== "unknownCountry") {
        results.push(<ListSubheader key={`groupedplayerheader_${index}`}>
          <ReactCountryFlag style={{ fontSize: "1.5em" }} countryCode={country} />
        </ListSubheader>
        );
      } else {
        results.push(<ListSubheader key={`groupedplayerheader_${index}`}>
          N/A
        </ListSubheader>
        );
      }

      players.forEach(player => {
        results.push(<MenuItem key={`groupedplayer_${player.steamID}`} value={player.steamID}>
          <div
            style={{
              color: "white",
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "center"
            }}
          >
            <Avatar
              alt="avatar"
              src={player.avatar.large}
              style={{
                width: theme.spacing(3),
                height: theme.spacing(3),
              }}
            />

            {player.displayName}
          </div>
        </MenuItem>);
      });

      return results;
    }).reduce((prev, cur) => {
      prev.push(...cur);

      return prev;
    });
  }, [playersByCountry, theme]);

  return <div>
    <div className={classes.chartWrapper}>
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Fade in timeout={500} unmountOnExit>
          <div
            style={{
              display: "flex",
              flexDirection: "column"
            }}
          >
            {availablePlayers.length !== 0 &&
              <FormControl className={classes.formControl}>
                <InputLabel id="playerSelectLabel">{t("map.graph.player")}</InputLabel>

                <Select
                  labelId="playerSelectLabel"
                  value={selectedPlayer}
                  onChange={handleSelectedPlayerChange}
                  autoWidth
                >
                  <MenuItem value={-1}>
                    <em>
                      {t("map.graph.none")}
                    </em>
                  </MenuItem>

                  {getGroupedPlayers()}
                </Select>
              </FormControl>
            }

            {recordings.length !== 0 && <FormControl className={classes.formControl}>
              <InputLabel htmlFor="runsSelectInput">{t("map.graph.runs")}</InputLabel>

              <Select
                multiple
                onChange={handleSelectedRunsChange}
                value={selectedRunsIndices}
                id="runsSelectInput"
                input={<Input id="select-multiple-chip" />}
              >
                {getSelectItems()}
              </Select>
            </FormControl>
            }
          </div>
        </Fade>
      </div>
    </div>

    {
      loading &&
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size="5rem" color="secondary" />
      </Backdrop>
    }

    {
      playerRecordings.length !== 0 &&
      <GraphsComp playerRecordings={playerRecordings} user={props.user} />
    }
  </div >;
};
