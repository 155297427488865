export const difficulties = [
  "Easy",
  "Average",
  "Hard",
  "Extreme",
  "Death",
  "God"
] as const;

export type difficultiesT = typeof difficulties[number];

export const difficultyToColor = (difficulty: difficultiesT) => {
  switch (difficulty) {
    case "Easy": return "lightgreen";
    case "Average": return "darkgreen";
    case "Hard": return "orange";
    case "Extreme": return "red";
    case "Death": return "darkred";
  }

  return "white";
};

export const difficultyToTextColor = (difficulty: difficultiesT) => {
  switch (difficulty) {
    case "Easy": return "black";
    case "Average": return "white";
    case "Hard": return "black";
    case "Extreme": return "white";
    case "Death": return "white";
  }

  return "black";
};
