export interface CVARCheck {
    cvar: string,
    expected: number,
    actual: number,
};

export interface Strafe {
    number: number,

    gain: number,
    loss: number,
    duration: number,
    sync: number,
};

export interface LJStats {
    technique: string,

    distance: number,
    maxSpeed: number,
    preStrafe: number,
    jumpOff: number,
    landing: number,
    block: number,
    sync: number,

    strafes: Strafe[]
};

export interface CheckDemoGeneral {
    map: string,
    time: number | null,
    startFrame: number | null,
    endFrame: number | null,
    player: {
        name: string,
        steamID: string
        ljstats: LJStats | null
    }
};

export interface CheckDemosStatusResponse {
    status: string,
    message?: string,
    general: CheckDemoGeneral
};

export interface CheckDemoDiffResponse {
    diffs: CVARCheck[],
    general: CheckDemoGeneral
};

export default interface CheckDemosResponse {
    demo: string,
    checks: CheckDemosStatusResponse | CheckDemoDiffResponse
    extraChecks: string[]
};

export const isStatusResponse = (input: object): input is CheckDemosStatusResponse => {
    return (input as CheckDemosStatusResponse).status !== undefined;
};
