import React from "react";

import { createStyles, Fade, List, ListItem, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";

import AuthorLink from "../AuthorLink";
import User from "../User";
import { userTypeToStr } from "../UserType";

import { API_URL } from "../../Config";
import CustomPaper from "../../CustomPaper";
import { useTranslation } from "react-i18next";
import { groupBy } from "../../Util";

export interface UsersProps {
  jwt: string | null,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr"
    },
  })
);

export default function Users(props: UsersProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [usersData, set_usersData] = React.useState<User[]>([]);
  const [userBanned, set_userBanned] = React.useState(false);

  React.useEffect(() => {
    if (!props.jwt) {
      return;
    }

    fetch(`${API_URL}/users`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        if (response.status === 423) {
          set_userBanned(true);
        }
      } else {
        set_usersData(await response.json());
      }
    });
  }, [props.jwt]);

  if (!props.jwt) {
    return <CustomPaper>
      <div className={classes.wrapper}>
        {t("unauthorized")}
      </div>
    </CustomPaper>;
  }

  if (userBanned) {
    return <CustomPaper>
      <div className={classes.wrapper}>
        {t("banned")}
      </div>
    </CustomPaper>;
  }

  const usersByType = groupBy(usersData, user => userTypeToStr(user.type));
  const userGroups = Array.from(usersByType.entries())
    .sort((a: [string, User[]], b: [string, User[]]) => a[1][0].type > b[1][0].type ? -1 : 1);

  return <CustomPaper>
    <div className={classes.wrapper}>
      <Typography variant="overline">
        {t("users.title")}
      </Typography>

      <List>
        {userGroups.map(([type, users], index1) => {
          return <Fade in timeout={500 + 100 * index1} unmountOnExit key={index1}>
            <div>
              <Typography variant="overline">
                {type}
              </Typography>

              {users.sort((a, b) => a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase()))
                .map((user, index2) =>
                  <ListItem
                    key={`${index1}_${index2}`}
                    button
                  >
                    <ListItemText
                      disableTypography
                      primary={<AuthorLink author={user} steam_id={user.steamID} avatar />}
                    />
                  </ListItem>
                )}
            </div>
          </Fade>
        })
        }
      </List>
    </div>
  </CustomPaper>;
};
