import { Backdrop, CircularProgress, createStyles, FormControl, FormControlLabel, InputLabel, makeStyles, MenuItem, Select, Switch, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../Config";
import CustomPaper from "../../CustomPaper";
import User from "../../user/User";
import StripePrice from "../StripePrice";
import ShopItemComp from "./ShopItemComp";

export interface ShopProps {
  jwt: string | null,
  user: User
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(3),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
    itemsGrid: {
      display: "grid",
      // gridTemplateColumns: `repeat(auto-fit, ${theme.spacing(45.5)}px)`,
      gridTemplateColumns: `1fr`,
      gap: theme.spacing(3),
      justifyContent: "center"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export default function Shop(props: ShopProps) {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const [loading, set_loading] = React.useState(true);
  const [items, set_items] = React.useState<StripePrice[]>([]);
  const [onlyBought, set_onlyBought] = React.useState(false);

  const [typeFilter, setTypeFilter] = React.useState("");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTypeFilter(event.target.value as string);
  };

  const loadShopItems = React.useCallback(() => {
    set_loading(true);

    fetch(`${API_URL}/shop`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
      } else {
        set_items(await response.json());
      }
    }).finally(() => {
      set_loading(false);
    });
  }, [props.jwt]);

  const filteredItems = React.useMemo(() => {
    if (onlyBought) {
      return items.filter(a => a.owned).filter(a => typeFilter.length === 0 ? true : a.item.metadata.weapon === typeFilter);
    }

    return items.filter(a => typeFilter.length === 0 ? true : a.item.metadata.weapon === typeFilter);
  }, [items, onlyBought, typeFilter]);

  React.useEffect(() => {
    loadShopItems();
  }, [loadShopItems]);

  if (!props.jwt) {
    return <CustomPaper>
      <div className={classes.wrapperDiv}>
        {t("unauthorized")}
      </div>
    </CustomPaper>;
  }

  return <CustomPaper>
    <div className={classes.wrapperDiv}>
      <Typography variant="overline">
        {t("shop.title")}
      </Typography>

      {loading && <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size="5rem" color="secondary" />
      </Backdrop>}

      {(!loading && items.length !== 0) &&
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(4),
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={onlyBought}
                onChange={(e) => {
                  set_onlyBought(e.target.checked);
                }}
              />
            }
            label={t("shop.only_bought")}
          />

          <FormControl className={classes.formControl}>
            <InputLabel id="filter-label">Filter</InputLabel>

            <Select
              labelId="filter-label"
              id="filter-select"
              value={typeFilter}
              onChange={handleChange}
            >
              {['', 'Player', 'USP', 'Knife', 'Deagle', 'Famas', 'Scout', 'AWP', 'M249', 'AK47', 'M4A1', 'SG552', 'P90'].map(filter =>
                <MenuItem value={filter.toLowerCase()} key={`menu_item${filter}`}>
                  {filter}
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
      }

      {(!loading && items.length === 0) &&
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {t("shop.bought_everything")}
        </div>
      }

      {filteredItems.length !== 0 &&
        <div className={classes.itemsGrid}>
          {filteredItems.filter(item => onlyBought ? item.owned : true).map((item) =>
            <ShopItemComp
              key={item.id}
              item={item}
              jwt={props.jwt}
              user={props.user}
              reloadShopItems={loadShopItems}
            />)}
        </div>
      }
    </div>
  </CustomPaper>;
};
