import { Button, createStyles, Fade, makeStyles, TextField, Theme } from "@material-ui/core";
import React from "react";
import { NEW_API_URL } from "../Config";
import CustomPaper from "../CustomPaper";
import NewsComment from "./NewsComment";
import NewsCommentComp from "./NewsCommentComp";
import User from "../user/User";
import { useTranslation } from "react-i18next";

export interface NewsCommentsWrapperCompProps {
  user: User | undefined,
  jwt: string | null,
  newsId: number,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddedWrapper: {
      padding: theme.spacing(2),
    },
    paddedGridWrapper: {
      padding: theme.spacing(2),
      display: "grid",
      gridAutoRows: "min-content",
      gap: theme.spacing(4)
    },
    postCommentWrapper: {
      paddingTop: theme.spacing(2)
    },
    centeredCommentTextInput: {
      width: "50%",
      marginLeft: "auto",
      marginRight: "auto",
      display: "flex",
      flexDirection: "column"
    },
    commentBtn: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: "auto",
      marginRight: "auto"
    }
  })
);

export default function NewsCommentsWrapperComp(props: NewsCommentsWrapperCompProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [comments, set_comments] = React.useState<NewsComment[]>([]);
  const [newCommentText, set_newCommentText] = React.useState("");

  const reloadComments = React.useCallback(() => {
    fetch(`${NEW_API_URL}/news/${props.newsId}/comments`, {
      method: "GET",
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_comments(await response.json());
      }
    });
  }, [props.newsId]);

  React.useEffect(() => {
    reloadComments();
  }, [reloadComments]);

  const onComment = React.useCallback(() => {
    if (newCommentText.length === 0) {
      return;
    }

    fetch(`${NEW_API_URL}/news/${props.newsId}/comments`, {
      method: "POST",
      body: newCommentText,
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        reloadComments();
        set_newCommentText("");
      }
    });
  }, [newCommentText, props.jwt, props.newsId, reloadComments]);

  return <div style={{ width: "100%" }}>
    {comments.length !== 0 &&
      <CustomPaper>
        <Fade in timeout={500} unmountOnExit>
          <div className={classes.paddedGridWrapper}>
            {comments.sort((a, b) => a.datePosted < b.datePosted ? -1 : 1).map((comment) => <NewsCommentComp comment={comment} key={comment.commentId} />)}
          </div>
        </Fade>
      </CustomPaper>
    }

    {props.jwt &&
      <div className={classes.postCommentWrapper}>
        <CustomPaper>
          <div className={classes.paddedWrapper}>
            <div className={classes.centeredCommentTextInput}>
              <TextField
                label={`${t("news.comment.add")}`}
                fullWidth
                multiline
                minRows={5}
                variant="outlined"
                value={newCommentText}
                onChange={(e) => set_newCommentText(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && e.shiftKey) {
                    onComment();

                    e.preventDefault();
                  }
                }}
              />

              <Button
                className={classes.commentBtn}
                color="primary"
                variant="contained"
                disabled={newCommentText.length === 0}
                onClick={onComment}
              >
                {t("news.comment.button")}
              </Button>
            </div>
          </div>
        </CustomPaper>
      </div>
    }
  </div>;
};
