import React from "react";

import { Backdrop, Box, Chip, CircularProgress, createStyles, lighten, List, ListItem, ListItemText, makeStyles, Tab, Tabs, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@material-ui/lab";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { NEW_API_URL } from "../../Config";

import CustomPaper from "../../CustomPaper";
import TabPanel from "../../TabPanelComp";

import MapDownloadLinkComp from "../../demo/MapDownloadLinkComp";
import ViewMapLinkComp from "../../demo/page/ViewMapLinkComp";
import AuthorLink from "../../user/AuthorLink";
import User from "../../user/User";
import MapDataDTO from "../MapDataDTO";
import { formatDate, formatMS } from "../../Util";
import DemoWaitlistStatus from "../../demo/DemoWaitlistStatus";
import DemoTimeLinkComp from "../../demo/DemoTimeLinkComp";
import ViewDemoLink from "../../viewdemo/ViewDemoLink";
import ServerRecordsComp from "../../server/ServerRecordsComp";
import MapGraphsComp from "../MapGraphsComp";
import { typesT, typeToColor, typeToTextColor } from "../../server/page/Types";
import { difficultiesT, difficultyToColor, difficultyToTextColor } from "../../server/page/Difficulties";

export interface MapDataProps {
  jwt: string | null,
  user: User
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    tabsWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(4)
    }
  })
);

export default function MapData(props: MapDataProps) {
  const classes = useStyles();
  const theme = useTheme();
  const params = useParams<{ map: string }>();
  const { t } = useTranslation();

  const [loading, set_loading] = React.useState(false);
  const [userBanned, set_userBanned] = React.useState(false);

  const [mapDataDTO, set_mapDataDTO] = React.useState<MapDataDTO>();
  // const [authorUser, set_authorUser] = React.useState<User>();

  const [tabIndex, set_tabIndex] = React.useState(0);

  // React.useEffect(() => {
  //   if (!mapDataDTO || !mapDataDTO.generalInfo || !mapDataDTO.generalInfo.author_steam_id) {
  //     return;
  //   }

  //   fetch(`${API_URL}/profile/${mapDataDTO.generalInfo.author_steam_id}`, {
  //     method: "GET",
  //   }).then(async (response) => {
  //     if (response.status !== 200) {
  //       if (response.status === 423) {
  //         set_userBanned(true);
  //       }
  //     } else {
  //       set_authorUser(await response.json());
  //     }
  //   }).catch((reason) => {
  //     console.error(reason);
  //   });
  // }, [mapDataDTO, props.jwt]);

  React.useEffect(() => {
    set_loading(true);

    fetch(`${NEW_API_URL}/maps/${params.map}/data`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        if (response.status === 423) {
          set_userBanned(true);
        }
      } else {
        const result = (await response.json()) as MapDataDTO;

        set_mapDataDTO(() => {
          return {
            // @ts-ignore
            generalInfo: {
              n: params.map,
              d: null,
              t: null
            },
            ...result
          };
        });
      }
    }).finally(() => {
      set_loading(false);
    });
  }, [props.jwt, params.map]);

  // const formatNullable = (value: string | null | undefined) => {
  //   if (value) {
  //     return value;
  //   }

  //   return "n/a";
  // };

  // const formatCountryCode = (value: string | null | undefined) => {
  //   if (value) {
  //     return value.toUpperCase();
  //   }

  //   return "n/a";
  // };

  if (loading || !mapDataDTO || !mapDataDTO.generalInfo) {
    return <CustomPaper>
      <Backdrop className={classes.backdrop} open={true}>
        <CircularProgress size="5rem" color="secondary" />
      </Backdrop>
    </CustomPaper>;
  }

  if (userBanned) {
    return <CustomPaper>
      <div className={classes.wrapperDiv}>
        {t("banned")}
      </div>
    </CustomPaper>;
  }

  return <CustomPaper>
    <div className={classes.wrapperDiv}>
      <Typography variant="overline">
        {t("map.title")}
      </Typography>

      <div
        style={{
          display: "block",
        }}
      >
        <div
          style={{
            display: "flex",
            paddingTop: theme.spacing(3),
            justifyContent: "center",
            gap: theme.spacing(2)
          }}
        >
          <Box sx={{
            bgcolor: lighten(theme.palette.background.paper, 0.1),
            height: theme.spacing(35),
            width: theme.spacing(35),
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}>
            <ViewMapLinkComp
              big
              map={params.map}
              jwt={props.jwt}
              set_downloading={() => { }}
              on_unauthorized={() => { }}
            />
          </Box>

          <List>
            <ListItem>
              <ListItemText disableTypography secondary={<div style={{
                display: "flex"
              }}>
                <MapDownloadLinkComp
                  icon
                  map={mapDataDTO.generalInfo.n}
                  jwt={props.jwt}
                  set_downloading={() => { }}
                />
              </div>} primary={t("map.name")} />
            </ListItem>

            <ListItem>
              <ListItemText secondary={
                ((!mapDataDTO.generalInfo.t || mapDataDTO.generalInfo.t.length === 0) ? "n/a" : (mapDataDTO.generalInfo.t.split(',') as typesT[]).map(type =>
                  <Chip
                    key={`${mapDataDTO.generalInfo!.n}_${type}`}
                    label={type}
                    style={{
                      margin: 2,
                      backgroundColor: typeToColor(type),
                      color: typeToTextColor(type)
                    }}
                  />
                ))
              } primary={t("map.type")} />
            </ListItem>

            <ListItem>
              <ListItemText secondary={
                ((!mapDataDTO.generalInfo.d || mapDataDTO.generalInfo.d.length === 0) ? "n/a" : (mapDataDTO.generalInfo.d.split(',') as difficultiesT[]).map(difficulty =>
                  <Chip
                    key={`${mapDataDTO.generalInfo!.n}_${difficulty}`}
                    label={difficulty}
                    style={{
                      margin: 2,
                      backgroundColor: difficultyToColor(difficulty),
                      color: difficultyToTextColor(difficulty)
                    }}
                  />
                ))
              } primary={t("map.difficulty")} />
            </ListItem>

            {/* {(mapDataDTO.generalInfo.author_steam_id && authorUser) ?
              <ListItem>
                <ListItemText disableTypography secondary={<AuthorLink author={authorUser} steam_id={authorUser.steamID} avatar />} primary={t("map.mapper")} />
              </ListItem>
              :
              <ListItem>
                <Tooltip title={formatCountryCode(mapDataDTO.generalInfo.author_country)}>
                  <ListItemText secondary={formatNullable(mapDataDTO.generalInfo.author)} primary={t("map.mapper")} />
                </Tooltip>
              </ListItem>
            } */}

            {mapDataDTO.wr ?
              <ListItem>
                <ListItemText disableTypography secondary={<div
                  style={{
                    display: "flex",
                    gap: theme.spacing(1),
                    alignItems: "baseline"
                  }}
                >
                  {mapDataDTO.wr.source} - {mapDataDTO.wr.player} - {formatMS(mapDataDTO.wr.time)}

                  <ViewDemoLink
                    jwt={props.jwt}
                    demo={{
                      map: mapDataDTO.wr.mapOriginalCase,
                      player: mapDataDTO.wr.player,
                      time: mapDataDTO.wr.time
                    }}
                    xj={mapDataDTO.wr.source === "XJ"}
                    cosy={mapDataDTO.wr.source === "COSY"}
                  />
                </div>} primary="WR" />
              </ListItem>
              :
              <ListItem>
                <ListItemText secondary="n/a" primary="WR" />
              </ListItem>
            }
          </List>
        </div>

        <div className={classes.tabsWrapper}>
          <Tabs
            value={tabIndex}
            onChange={(_, value) => set_tabIndex(value)}
            indicatorColor="primary"
          >
            <Tab label={t("map.history")} />
            <Tab label={t("server_records.label")} />
            <Tab label={t("map.graphs")} />
          </Tabs>
        </div>

        <TabPanel value={tabIndex} index={0} noMarginTop>
          {mapDataDTO.demos.length !== 0 &&
            <div>
              <Timeline align="alternate">
                {mapDataDTO.demos
                  .filter(d => d.status === DemoWaitlistStatus.Released || d.status === DemoWaitlistStatus.Beat)
                  .sort((a, b) => a.uploadDate < b.uploadDate ? 1 : -1)
                  .map((demo, index, arr) => <TimelineItem key={index}>
                    <TimelineSeparator>

                      <Tooltip title={formatDate(demo.uploadDate, true)}>
                        <TimelineDot />
                      </Tooltip>

                      {index !== arr.length - 1 && <TimelineConnector />}
                    </TimelineSeparator>

                    <TimelineContent
                      style={index % 2 === 0 ? undefined : {
                        display: "flex",
                        alignItems: "baseline",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: theme.spacing(1.5)
                        }}
                      >
                        {index % 2 !== 0 && <AuthorLink
                          author={demo.uploader as User}
                          steam_id={demo.uploader!.steamID}
                          avatar
                          small
                        />}

                        {index % 2 === 0 && <ViewDemoLink
                          jwt={props.jwt}
                          demo={demo}
                        />}

                        <DemoTimeLinkComp
                          jwt={props.jwt}
                          demo={demo}
                          set_downloading={() => { }}
                          on_unauthorized={() => { }}
                        />

                        {index % 2 !== 0 && <ViewDemoLink
                          jwt={props.jwt}
                          demo={demo}
                        />}

                        {index % 2 === 0 && <AuthorLink
                          author={demo.uploader as User}
                          steam_id={demo.uploader!.steamID}
                          avatar
                          small
                        />}
                      </div>
                    </TimelineContent>
                  </TimelineItem>)}
              </Timeline>
            </div>
          }
        </TabPanel>

        <TabPanel value={tabIndex} index={1} noMarginTop>
          <ServerRecordsComp
            jwt={props.jwt}
            map={params.map}
            set_loading={set_loading}
            disableSorting
          />
        </TabPanel>

        <TabPanel value={tabIndex} index={2} noMarginTop>
          <MapGraphsComp
            jwt={props.jwt}
            map={params.map}
            user={props.user}
          />
        </TabPanel>
      </div>
    </div>
  </CustomPaper>;
};
