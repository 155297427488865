import React from "react";
import { formatMS, isAdmin } from "../Util";
import RecordFileData from "../viewdemo/RecordFileData";
import { UnregisteredUser } from "../server/ServerRecord";
import { Fade, Theme, Typography, createStyles, makeStyles, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    ChartOptions,
    Title,
    SubTitle,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import User from "../user/User";

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Title,
    SubTitle,
);

export interface FogData {
    type: "bhop" | "duck",
    perfect: boolean,
    fog: number,
    pre: number,
    time: number,
};

export interface LjData {
    type: string,
    distance: number,
    pre: number,
    strafes: number,
    sync: number,
    time: number
};

export interface IndexedRecording {
    index: number,
    data: RecordFileData,
    fog: FogData[],
    lj: LjData[],
    user: UnregisteredUser
};

interface GraphsCompProps {
    user: User | undefined,
    playerRecordings: IndexedRecording[]
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chartWrapper: {
            marginLeft: "auto",
            marginRight: "auto",
            width: "100%",
            paddingBottom: theme.spacing(2)
        },
    })
);

export default function GraphsComp(props: GraphsCompProps) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    React.useEffect(() => {
        ChartJS.defaults.color = theme.palette.common.white;
    }, [theme]);

    const { playerRecordings } = props;

    const fogChartData: ApexAxisChartSeries = React.useMemo(() => playerRecordings
        .map(data => {
            return {
                name: `${formatMS(data.data.time)} ${data.data.weapon}`,
                type: "line",
                data: data.fog.filter(fog => fog.fog !== 0).map((fog) => {
                    return {
                        x: fog.time,
                        y: fog.fog,
                        pre: fog.pre,
                        type: fog.type
                    };
                })
            };
        }), [playerRecordings]);

    const chartType = "line";

    const fogChartOptions = React.useMemo((): ApexCharts.ApexOptions => {
        return {
            chart: {
                stacked: false,
                type: chartType,
                foreColor: theme.palette.text.primary,
                toolbar: {
                    tools: {
                        download: false
                    }
                },
                zoom: {
                    type: 'x'
                },
            },
            stroke: {
                curve: 'straight',
            },
            title: {
                text: t("map.graph.run_fogs_title")
            },
            subtitle: {
                text: t("map.graph.lower_better")
            },
            legend: {
                show: true,
            },
            dataLabels: {
                enabled: true,
                background: {
                    enabled: true,
                    foreColor: theme.palette.text.primary,
                    dropShadow: {},
                }
            },
            tooltip: {
                onDatasetHover: {
                    highlightDataSeries: true
                },
                shared: true,
                followCursor: true,
                intersect: false,
                theme: "dark",
                custom: ({ _, seriesIndex, dataPointIndex }: any) => {
                    if (!fogChartData[seriesIndex] || !fogChartData[seriesIndex].data || !fogChartData[seriesIndex].data[dataPointIndex]) {
                        return null;
                    }

                    const seriesName = fogChartData[seriesIndex].name;

                    const data = fogChartData[seriesIndex].data[dataPointIndex] as { x: number, y: number, pre: number, type: "bhop" | "duck" };

                    const titleCase = (str: string) => {
                        return `${str[0].toUpperCase()}${str.substring(1)}`;
                    };

                    let quality: string = "";
                    let color = "lightgreen";

                    // TODO: max weapon speed
                    if ((data.y === 1 && data.pre < 300) || (data.y === 2 && data.pre >= 300)) {
                        quality = "Perfect";
                    } else if (data.y === 2) {
                        quality = t("map.graph.good");
                        color = "cyan";
                    } else {
                        quality = t("map.graph.bad");
                        color = "red";
                    }

                    return `<div style="font-size: 2em">
            <table>
              <tr>
                <td align="left">Run</td>
                <td>${seriesName}</td>
              </tr>

              <tr>
                <td align="left">${t("map.graph.time")}</td>
                <td>${data.x.toFixed(2)}</td>
              </tr>

              <tr>
                <td align="left">FOG:</td>
                <td>${data.y}</td>
              </tr>

              <tr>
                <td align="left">Pre:</td>
                <td>${data.pre.toFixed(3)}</td>
              </tr>
              <tr>
                <td align="left">${titleCase(data.type)}:</td>
                <td style="color: ${color}">${quality}</td>
              </tr>
            </table>
          </div>`;
                }
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
                borderColor: theme.palette.action.disabledBackground
            },
            xaxis: {
                type: 'numeric',
                axisTicks: {
                    show: true
                },
                title: {
                    text: t("map.graph.time")
                },
                labels: {
                    show: true,
                    formatter: (value) => {
                        return Number.parseFloat(value).toFixed(2);
                    },
                }
            },
            yaxis: {
                tickAmount: 10,
                max: 10,
                min: 0,
                floating: false,
                title: {
                    text: "FOG"
                },
            },
        };
    }, [fogChartData, theme.palette.text.primary, theme.palette.action.disabledBackground, t]);


    const averageFogChartData: ApexAxisChartSeries = React.useMemo(() => {
        return [{
            name: "",
            data: playerRecordings.map(pr => {
                const relevantFogs = pr.fog.filter(fog => fog.fog !== 0);
                const count = relevantFogs.reduce((p, c) => p + c.fog, 0);

                return {
                    x: new Date(pr.data.upload_date).getTime(),
                    y: count !== 0 ? count / relevantFogs.length : 0
                };
            })
        }];
    }, [playerRecordings]);

    const averageFogChartType = "area";

    const averageFogChartOptions = React.useMemo((): ApexCharts.ApexOptions => {
        return {
            chart: {
                stacked: false,
                type: averageFogChartType,
                foreColor: theme.palette.text.primary,
                toolbar: {
                    tools: {
                        download: false
                    }
                },
                zoom: {
                    type: 'x'
                },
            },
            title: {
                text: t("map.graph.run_average_fog_title"),
            },
            subtitle: {
                text: t("map.graph.lower_better")
            },
            legend: {
                show: true,
            },
            dataLabels: {
                enabled: true,
                background: {
                    enabled: true,
                    foreColor: theme.palette.text.primary,
                    dropShadow: {},
                },
                formatter: (value: string) => {
                    return Number.parseFloat(value).toFixed(2);
                }
            },
            tooltip: {
                onDatasetHover: {
                    highlightDataSeries: true
                },
                shared: true,
                followCursor: true,
                intersect: false,
                theme: "dark",
            },
            grid: {
                xaxis: {
                    lines: {
                        show: false
                    }
                },
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
                borderColor: theme.palette.action.disabledBackground
            },
            xaxis: {
                type: 'datetime',
                axisTicks: {
                    show: true
                },
                title: {
                    text: "Time"
                },
                labels: {
                    show: true,
                },
            },
            yaxis: {
                tickAmount: 10,
                max: 10,
                min: 0,
                floating: false,
                title: {
                    text: t("map.graph.run_average_fog_title")
                },
                labels: {
                    formatter: (value) => {
                        return value.toFixed(2);
                    }
                }
            },
        };
    }, [theme.palette.action.disabledBackground, theme.palette.text.primary, t]);

    const duckAndBhopDistributionFogChartData = React.useMemo(() => {
        const getFogNcount = (n: number, type: "bhop" | "duck") => {
            return playerRecordings.reduce((p, c) => p + c.fog.filter(f => f.type === type).filter(f => f.fog === n).length, 0);
        };

        const getPerfectFogNcount = (n: number, type: "bhop" | "duck") => {
            return playerRecordings.reduce((p, c) => p + c.fog.filter(f => f.type === type).filter(f => f.perfect && f.fog === n).length, 0);
        };

        const fogs = {
            bhop: {
                fog1: getFogNcount(1, "bhop"),
                fog2: getFogNcount(2, "bhop"),
                fog3: getFogNcount(3, "bhop"),
                fog4: getFogNcount(4, "bhop"),
                fog5: getFogNcount(5, "bhop"),
                fog6: getFogNcount(6, "bhop"),
                fog7: getFogNcount(7, "bhop"),
                fog8: getFogNcount(8, "bhop"),
                fog9: getFogNcount(9, "bhop"),
                fog10: getFogNcount(10, "bhop"),
            },
            bhopPerfect: {
                fog1: getPerfectFogNcount(1, "bhop"),
                fog2: getPerfectFogNcount(2, "bhop"),
                fog3: getPerfectFogNcount(3, "bhop"),
                fog4: getPerfectFogNcount(4, "bhop"),
                fog5: getPerfectFogNcount(5, "bhop"),
                fog6: getPerfectFogNcount(6, "bhop"),
                fog7: getPerfectFogNcount(7, "bhop"),
                fog8: getPerfectFogNcount(8, "bhop"),
                fog9: getPerfectFogNcount(9, "bhop"),
                fog10: getPerfectFogNcount(10, "bhop"),
            },
            duck: {
                fog2: getFogNcount(2, "duck"),
                fog3: getFogNcount(3, "duck"),
                fog4: getFogNcount(4, "duck"),
                fog5: getFogNcount(5, "duck"),
                fog6: getFogNcount(6, "duck"),
                fog7: getFogNcount(7, "duck"),
                fog8: getFogNcount(8, "duck"),
                fog9: getFogNcount(9, "duck"),
                fog10: getFogNcount(10, "duck"),
                fog11: getFogNcount(11, "duck"),
            },
            duckPerfect: {
                fog2: getPerfectFogNcount(2, "duck"),
                fog3: getPerfectFogNcount(3, "duck"),
                fog4: getPerfectFogNcount(4, "duck"),
                fog5: getPerfectFogNcount(5, "duck"),
                fog6: getPerfectFogNcount(6, "duck"),
                fog7: getPerfectFogNcount(7, "duck"),
                fog8: getPerfectFogNcount(8, "duck"),
                fog9: getPerfectFogNcount(9, "duck"),
                fog10: getPerfectFogNcount(10, "duck"),
                fog11: getPerfectFogNcount(11, "duck"),
            },
        };

        const getTotal = (type: "bhop" | "duck") => {
            if (type === "bhop") {
                return fogs[type].fog1 + fogs[type].fog2 + fogs[type].fog3 + fogs[type].fog4 + fogs[type].fog5 + fogs[type].fog6 + fogs[type].fog7 + fogs[type].fog8 + fogs[type].fog9 + fogs[type].fog10;
            }

            return fogs[type].fog2 + fogs[type].fog3 + fogs[type].fog4 + fogs[type].fog5 + fogs[type].fog6 + fogs[type].fog7 + fogs[type].fog8 + fogs[type].fog9 + fogs[type].fog10 + fogs[type].fog11;
        };

        const total = {
            bhop: getTotal("bhop"),
            duck: getTotal("duck"),
        };

        const calculatePercent = (n: number, type: "bhop" | "duck") => {
            return (n / total[type]) * 100.0;
        };

        const relevantBhopFogs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] as const;
        const relevantDuckFogs = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const;

        const dataSets: any[] = [];

        if (total.bhop !== 0) {
            dataSets.push({
                label: "Total (bhop)",
                data: relevantBhopFogs.map(f => calculatePercent(fogs.bhop[`fog${f}`], "bhop")),
                backgroundColor: 'rgba(0, 117, 231, 0.2)',
                borderColor: 'rgba(0, 100, 150, 1)',
            });

            dataSets.push({
                label: "Perfect (bhop)",
                data: relevantBhopFogs.map(f => calculatePercent(fogs.bhopPerfect[`fog${f}`], "bhop")),
                backgroundColor: 'rgba(0, 255, 0, 0.3)',
                borderColor: 'rgba(0, 255, 0, 1)',
            });
        }

        if (total.duck !== 0) {
            dataSets.push({
                label: "Total (duck)",
                data: relevantDuckFogs.map(f => calculatePercent(fogs.duck[`fog${f}`], "duck")),
                backgroundColor: 'rgba(194, 80, 192, 0.2)',
                borderColor: 'rgba(194, 80, 192, 1)',
            });

            dataSets.push({
                label: "Perfect (duck)",
                data: relevantDuckFogs.map(f => calculatePercent(fogs.duckPerfect[`fog${f}`], "duck")),
                backgroundColor: 'rgba(231, 121, 0, 0.2)',
                borderColor: 'rgba(231, 121, 0, 1)',
            });
        }

        return {
            labels: [1, ...relevantDuckFogs].map(i => i),
            datasets: dataSets
        };
    }, [playerRecordings]);

    const duckAndBhopDistributionFogChartOptions: ChartOptions<"radar"> = {
        plugins: {
            title: {
                display: true,
                text: t("map.graph.fog_distribution_title"),
            },
            subtitle: {
                display: true,
                text: t("map.graph.all_runs"),
                padding: {
                    bottom: theme.spacing(2)
                }
            },
            tooltip: {
                intersect: false,
                filter: (_, index, array) => {
                    if (array[index].dataset.data[array[index].dataIndex] === 0) {
                        return false;
                    }

                    return true;
                },
                callbacks: {
                    label: (tooltipItem) => {
                        return `${tooltipItem.dataset.label} ${tooltipItem.dataset.data[tooltipItem.dataIndex]?.toFixed(2)}%`;
                    },
                    title: (tooltipItems) => {
                        if (tooltipItems.length > 0) {
                            return `FOG ${tooltipItems[0].dataIndex + 1}`;
                        }

                        return "";
                    },
                },
                bodyFont: {
                    size: theme.typography.fontSize
                },
            },
            legend: {
                display: true,
                labels: {
                    color: theme.palette.text.primary
                }
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: false
                },
                suggestedMin: 0,
                suggestedMax: 50,
                grid: {
                    color: theme.palette.text.primary
                },
                pointLabels: {
                    color: theme.palette.text.primary,
                    font: {
                        size: theme.typography.fontSize
                    },
                    callback: (label) => {
                        return `FOG ${label}`;
                    }
                },
                ticks: {
                    backdropColor: theme.palette.info.dark,
                    color: theme.palette.getContrastText(theme.palette.info.dark),
                    callback: (tickValue) => {
                        return `${tickValue} %`;
                    },
                },
            },
        },
    };

    return <div className={classes.chartWrapper}>
        <Fade in timeout={500} unmountOnExit>
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: theme.spacing(8)
            }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Typography>
                        {t("map.graph.data_from", { runs: playerRecordings.length })}
                    </Typography>

                    {isAdmin(props.user) && <Typography>
                        {playerRecordings.map(pr => pr.data.md5)}
                    </Typography>}
                </div>

                <ReactApexChart options={fogChartOptions} series={fogChartData} type={chartType} height={550} />
                <ReactApexChart options={averageFogChartOptions} series={averageFogChartData} type={averageFogChartType} height={550} />

                <Radar
                    options={duckAndBhopDistributionFogChartOptions}
                    data={duckAndBhopDistributionFogChartData}
                />
            </div>
        </Fade>
    </div>;
};
