import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CustomPaper from "../../CustomPaper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    },
  })
);

export default function UserBanned() {
  const classes = useStyles();
  const {t} = useTranslation();

  return <CustomPaper>
    <div className={classes.wrapper}>
      {t("banned")}
    </div>
  </CustomPaper>;
};
