import { Skeleton } from "@material-ui/lab";
import CustomPaper from "../CustomPaper";
import { NEW_API_URL } from "../Config";
import React from "react";
import TopDataEntry from "./TopDataEntry";
import { Tab, Tabs, Theme, Typography, createStyles, makeStyles, useTheme } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TabPanel from "../TabPanelComp";
import TopComp from "./TopComp";

interface TabbedTopCompProps {
    jwt: string | null;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            padding: theme.spacing(2),
        },
        itemContentWrapper: {
            display: "flex",
            alignItems: "center"
        }
    })
);


export default function TabbedTopComp(props: TabbedTopCompProps) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();

    const [uspTopEntries, set_uspTopEntries] = React.useState<TopDataEntry[]>([]);
    const [wpnTopEntries, set_wpnTopEntries] = React.useState<TopDataEntry[]>([]);

    const [tabIndex, set_tabIndex] = React.useState(0);

    React.useEffect(() => {
        fetch(`${NEW_API_URL}/demos/kz/top?usp=1&count=5`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${props.jwt}`
            }
        }).then(async (response) => {
            if (response.status !== 200) {
                // TODO error handling
            } else {
                set_uspTopEntries(await response.json());
            }
        });

        fetch(`${NEW_API_URL}/demos/kz/top?usp=0&count=5`, {
            method: "GET",
            headers: {
                "Authorization": `Bearer ${props.jwt}`
            }
        }).then(async (response) => {
            if (response.status !== 200) {
                // TODO error handling
            } else {
                set_wpnTopEntries(await response.json());
            }
        });
    }, [props.jwt]);

    if (uspTopEntries.length === 0) {
        return <CustomPaper>
            <Skeleton
                height="350px"
                width="100%"
                animation="wave"
                variant="rect"
            />
        </CustomPaper>;
    }

    return <CustomPaper>
        <div className={classes.wrapper}>
            <Link
                to="/top/site"
                style={{
                    textDecoration: "none"
                }}
            >
                <Typography
                    variant="overline"
                    style={{
                        color: theme.palette.text.primary
                    }}
                >
                    Top 5
                </Typography>
            </Link>
        </div>

        <Tabs
            value={tabIndex}
            onChange={(_, value) => set_tabIndex(value)}
            indicatorColor="primary"
            centered
        >
            <Tab label="USP" />
            <Tab label={t("top.weapons")} />
        </Tabs>

        <TabPanel value={tabIndex} index={0}>
            <TopComp topEntries={uspTopEntries} />
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
            <TopComp topEntries={wpnTopEntries} />
        </TabPanel>
    </CustomPaper>;
}
