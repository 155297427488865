import { Backdrop, Button, CircularProgress, createStyles, makeStyles, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { API_URL } from "../../Config";
import CustomPaper from "../../CustomPaper";
import User from "../../user/User";
import { isAdmin } from "../../Util";
import Challenge from "../Challenge";
import ChallengeComp from "../ChallengeComp";

export interface ChallengesProps {
  jwt: string | null,
  user: User | undefined
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
  })
);

export default function Challenges(props: ChallengesProps) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const {t} = useTranslation();

  const [challenges, set_challenges] = React.useState<Challenge[]>([]);
  const [loading, set_loading] = React.useState(true);

  React.useEffect(() => {
    fetch(`${API_URL}/challenges`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_challenges(await response.json());
      }
    }).finally(() => {
      set_loading(false);
    });
  }, [props.jwt]);

  if (! props.jwt || ! props.user) {
    return <CustomPaper>
      <div className={classes.wrapperDiv}>
        {t("unauthorized")}
      </div>
    </CustomPaper>;
  }

  return <CustomPaper>
    <div className={classes.wrapperDiv}>
      <Typography variant="overline">
        {t("menu.challenges")}
      </Typography>

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress size="5rem" color="secondary"/>
      </Backdrop>

      <div
        style={{
          padding: theme.spacing(3)
        }}
      >
        {challenges.length === 0 && <Typography>{t("challenge.list.none")}</Typography>}

        {challenges.sort((a, b) => a.id > b.id ? -1 : 1).map((chal, index) =>
          <ChallengeComp
            jwt={props.jwt as string}
            item={chal}
            index={challenges.length - index}
            key={index}
            user={props.user as User}
          />
        )}
      </div>

      {isAdmin(props.user) &&
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push('/challenges/create')}
          disabled={challenges.length !== 0 && challenges[0].end_date === null}
        >
          {t("challenge.create.title")}
        </Button>
      }
    </div>
  </CustomPaper>;
};
