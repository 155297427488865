enum UserType {
    Normal,
    Mapper,
    Admin,
    VIP,
    Bot,
    Root,
    ServerAdmin,
};

export default UserType;

export const userTypeToStr = (type: UserType) => {
    switch (type) {
        case UserType.Normal: return "Normal";
        case UserType.Mapper: return "Mapper";
        case UserType.Admin: return "Admin";
        case UserType.Root: return "RAdmin";
        case UserType.Bot: return "Bot";
        case UserType.VIP: return "VIP";
        case UserType.ServerAdmin: return "ServerAdmin";

        default:
            console.error(type);
        return "Necunoscut";
    }
};
