import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string,
  noMarginTop?: boolean
}

const useTabPanelStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabContentWrapper: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(4)
    }
  })
);

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, noMarginTop, ...other } = props;
  const theme = useTheme();

  const classes = useTabPanelStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
      >
      {value === index &&
        <div className={classes.tabContentWrapper} style={{ marginTop: noMarginTop ? 0 : theme.spacing(4) }}>
          {children}
        </div>
      }
    </div>
  );
};
