import {
  createStyles,
  IconButton,
  Link,
  makeStyles,
  Theme,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../Config";

export interface ViewMapLinkCompProps {
  map: string;
  jwt: string | null;
  set_downloading: (state: boolean) => void;
  on_unauthorized: () => void;
  big?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      cursor: "grabbing",
      transition: "color 0.5s",
      transitionTimingFunction: "ease-in-out",
    },
  })
);

export default function ViewMapLinkComp(props: ViewMapLinkCompProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const [hovering, set_hovering] = React.useState(false);

  if (!props.jwt) {
    return null;
  }

  return (
    <Tooltip title={`${t("viewmap.title")}`}>
      {props.jwt ? (
        <Link
          target="_blank"
          className={classes.link}
          style={{
            color: hovering
              ? theme.palette.error.dark
              : theme.palette.text.primary,
          }}
          href={`${API_URL}/viewDemo/3/${props.jwt}/${props.map}`}
          onMouseEnter={() => set_hovering(true)}
          onMouseLeave={() => set_hovering(false)}
        >
          <VisibilityIcon fontSize={props.big ? "large" : "small"} />
        </Link>
      ) : (
        <IconButton
          onClick={() => {
            props.on_unauthorized && props.on_unauthorized();
          }}
        >
          <VisibilityIcon fontSize={props.big ? "large" : "small"} />
        </IconButton>
      )}
    </Tooltip>
  );
}
