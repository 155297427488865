import React, { CSSProperties } from "react";
import {
  createStyles,
  FormControlLabel,
  makeStyles,
  Switch,
  TextField,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";

import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

import { useTranslation } from "react-i18next";

import { formatMS } from "../../Util";
import MapLinkComp from "../MapLinkComp";

import { FixedSizeList as RWList } from "react-window";

import { Record } from "../DemoWaitlistData";
import ViewDemoLink from "../../viewdemo/ViewDemoLink";
import DemoTimeLinkComp from "../DemoTimeLinkComp";
import AuthorLink from "../../user/AuthorLink";
import { wpnToString } from "../Weapon";
import AnalyzeLink from "../../challenge/AnalyzeLink";
import User from "../../user/User";
import ViewMapLinkComp from "./ViewMapLinkComp";
import MapDownloadLinkComp from "../MapDownloadLinkComp";

export interface SiteRecordsProps {
  jwt: string | null;
  user: User | undefined;
  records: Record[];
  set_loading: (state: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    itemsWrapper: {
      display: "grid",
      alignItems: "center",
    },
    itemWrapper: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(1),
    },
    sourceTabsWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(4),
    },
  })
);

export default function SiteRecords(props: SiteRecordsProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [onlyWithRecord, set_onlyWithRecord] = React.useState(false);
  const [onlyWithouthRecord, set_onlyWithouthRecord] = React.useState(false);

  const [sortField, set_sortField] = React.useState("map");
  const [sortAscending, set_sortAscending] = React.useState(true);

  const [filterText, set_filterText] = React.useState("");

  const sortFunc = React.useCallback(
    (a: Record, b: Record) => {
      const factor = sortAscending ? 1 : -1;

      if (sortField === "map") {
        return a.map.localeCompare(b.map) * factor;
      }

      if (a.data === null && b.data !== null) {
        return 1;
      } else if (a.data !== null && b.data === null) {
        return -1;
      } else if (a.data === null && b.data === null) {
        return 0;
      }

      if (sortField === "wr") {
        if (a.wr && b.wr) {
          return (a.wr.time < b.wr.time ? -1 : 1) * factor;
        } else if (a.wr && !b.wr) {
          return -1;
        } else if (!a.wr && b.wr) {
          return 1;
        }

        return 0;
      }

      if (sortField === "weapon") {
        return (
          wpnToString(a.data!.weapon).localeCompare(
            wpnToString(b.data!.weapon)
          ) * factor
        );
      }

      if (sortField === "player" && a.data!.uploader && b.data!.uploader) {
        return (
          a.data!.uploader.displayName.localeCompare(
            b.data!.uploader.displayName
          ) * factor
        );
      }

      if (a.data && b.data) {
        return (a.data!.time < b.data!.time ? -1 : 1) * factor;
      }

      return factor;
    },
    [sortField, sortAscending]
  );

  const getSortingIcon = React.useCallback(
    (field: string) => {
      if (field !== sortField) {
        return null;
      }

      if (sortAscending) {
        return <ArrowUpwardIcon />;
      }

      return <ArrowDownwardIcon />;
    },
    [sortAscending, sortField]
  );

  const filter = React.useCallback(
    (a: Record) => {
      if (filterText.length === 0) {
        return true;
      }

      const filters = filterText.toLowerCase().split(",");

      let matches = false;

      for (const localFilter of filters) {
        if (localFilter.length === 0) {
          continue;
        }

        if (
          a.data &&
          a.data.uploader &&
          a.data.uploader.displayName.toLowerCase().indexOf(localFilter) !== -1
        ) {
          matches = true;
          continue;
        } else if (a.map.toLowerCase().indexOf(localFilter) !== -1) {
          matches = true;
          continue;
        } else {
          matches = false;
        }
      }

      return matches;
    },
    [filterText]
  );

  const filteredRecords = React.useMemo(() => {
    const originalFilter = filterText.length === 0 ? () => true : filter;
    let adjustedFilter = (_: Record) => true;

    if (onlyWithRecord) {
      adjustedFilter = (a) => {
        return originalFilter(a) && a.data !== undefined;
      };
    } else if (onlyWithouthRecord) {
      adjustedFilter = (a) => {
        return originalFilter(a) && a.data === undefined;
      };
    } else {
      adjustedFilter = originalFilter;
    }

    return props.records.sort(sortFunc).filter(adjustedFilter);
  }, [
    sortFunc,
    filter,
    filterText,
    onlyWithRecord,
    onlyWithouthRecord,
    props.records,
  ]);

  const RowHeight = 75;

  const Row = (input: { index: number; style: CSSProperties }) => {
    const data = filteredRecords[input.index];

    return (
      <div
        className={classes.itemsWrapper}
        style={{
          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
          height: `${RowHeight}px`,
          ...input.style,
        }}
      >
        <div className={classes.itemWrapper}>
          <MapLinkComp map={data.map} />

          <ViewMapLinkComp
            map={data.map}
            jwt={props.jwt}
            on_unauthorized={() => {}}
            set_downloading={props.set_loading}
          />

          <MapDownloadLinkComp
            icon
            hideText
            map={data.map}
            jwt={props.jwt}
            set_downloading={props.set_loading}
          />
        </div>

        <div className={classes.itemWrapper}>
          {data.data && data.data.uploader ? (
            <AuthorLink
              author={data.data.uploader}
              steam_id={data.data.uploader.steamID}
              avatar
            />
          ) : (
            <Typography variant="caption">n/a</Typography>
          )}
        </div>

        <div
          className={classes.itemWrapper}
          style={{
            gap: theme.spacing(1),
          }}
        >
          {data.data ? (
            <DemoTimeLinkComp
              jwt={props.jwt}
              demo={data.data}
              set_downloading={props.set_loading}
              on_unauthorized={() => {}}
            />
          ) : (
            <Typography variant="caption">n/a</Typography>
          )}

          {data.data && (
            <ViewDemoLink
              jwt={props.jwt}
              demo={{
                demoMD5: data.data.demoMD5,
                map: data.map,
                player: data.data.uploader?.displayName,
                time: data.data.time,
              }}
            />
          )}

          {data.data && <AnalyzeLink demo={data.data} user={props.user} />}
        </div>

        <div className={classes.itemWrapper}>
          <Typography>
            {data.data ? (
              wpnToString(data.data.weapon)
            ) : (
              <Typography variant="caption">n/a</Typography>
            )}
          </Typography>
        </div>

        <div className={classes.itemWrapper}>
          {!data.wr || data.wr.player === "n/a" || data.wr.time === 0 ? (
            <Typography variant="caption">n/a</Typography>
          ) : (
            <div
              style={{
                display: "flex",
                gap: theme.spacing(1),
              }}
            >
              <Typography>{data.wr.player}</Typography>

              <Typography>{formatMS(data.wr.time)}</Typography>

              <ViewDemoLink
                jwt={props.jwt}
                demo={{
                  map: data.wr.mapOriginalCase,
                  player: data.wr.player,
                  time: data.wr.time,
                }}
                xj={data.wr.source === "XJ"}
                cosy={data.wr.source === "COSY"}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className={classes.wrapperDiv}>
      <div>
        <TextField
          value={filterText}
          onChange={(e) => {
            set_filterText(e.target.value.toLowerCase());
          }}
          autoComplete="off"
          variant="outlined"
          fullWidth
          label={t("demo.table.filter.map_player")}
          style={{
            paddingBottom: theme.spacing(2),
          }}
        />

        <FormControlLabel
          control={
            <Switch
              checked={onlyWithRecord}
              onChange={(e) => {
                set_onlyWithRecord(e.target.checked);
                set_onlyWithouthRecord(false);
              }}
            />
          }
          label={t("demo.table.only_with_record")}
        />

        <FormControlLabel
          control={
            <Switch
              checked={onlyWithouthRecord}
              onChange={(e) => {
                set_onlyWithouthRecord(e.target.checked);
                set_onlyWithRecord(false);
              }}
            />
          }
          label={t("demo.table.only_without_record")}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingBottom: theme.spacing(6),
          }}
        >
          {t("demo.table.count", {
            maps: filteredRecords.length,
            norecmaps: filteredRecords.filter((rec) => !rec.data).length,
          })}
        </div>

        <div
          className={classes.itemsWrapper}
          style={{
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
            paddingBottom: theme.spacing(6),
          }}
        >
          {[
            ["map", t("demo.table.columns.map")],
            ["player", t("demo.table.columns.player")],
            ["time", t("demo.table.columns.time")],
            ["weapon", t("demo.table.columns.weapon")],
            ["wr", t("demo.table.columns.wr")],
          ].map(([field, label], index) => (
            <div
              key={index}
              className={classes.itemWrapper}
              onClick={() => {
                set_sortField(field);
                set_sortAscending(!sortAscending);
              }}
            >
              <Typography>{label}</Typography>

              {getSortingIcon(field)}
            </div>
          ))}
        </div>

        <RWList
          height={1800}
          width={"100%"}
          itemSize={RowHeight}
          itemCount={filteredRecords.length}
        >
          {Row}
        </RWList>
      </div>
    </div>
  );
}
