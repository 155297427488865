import ServerInfo, { PlayerHistory } from "./ServerInfo";
import React from "react";
import { useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import annotationPlugin from 'chartjs-plugin-annotation';

import {
    Chart as ChartJS,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    ChartOptions,
    Title,
    SubTitle,
    CategoryScale,
    LinearScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    RadialLinearScale,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend,
    Title,
    SubTitle,
    annotationPlugin
);

export interface PlayersSparkLineProps {
    serverInfo: ServerInfo | undefined
};

export default function PlayersSparkLineComp(props: PlayersSparkLineProps) {
    const theme = useTheme();
    const { t } = useTranslation();

    const options: ChartOptions<"line"> = React.useMemo(() => {
        if (!props.serverInfo || !props.serverInfo.history || props.serverInfo.history.length === 0) {
            return {};
        }

        const highestPlayerCount = Math.max(...props.serverInfo.history.filter(h => h.map.length !== 0).map(h => h.count));

        let annotations: any[] = [];

        let prevItem: PlayerHistory = props.serverInfo.history[0];

        if (prevItem.map.length !== 0) {
            for (let i = 0; i < props.serverInfo.history.length; ++i) {
                const item = props.serverInfo.history[i];

                if (item.map.length > 0 && prevItem.map !== item.map) {
                    annotations.push({
                        type: 'line',
                        borderColor: 'rgba(255, 99, 132, 1.0)',
                        borderWidth: 2,
                        xMax: i,
                        xMin: i,
                        xScaleID: 'x',
                        yMax: 0,
                        yMin: highestPlayerCount,
                        yScaleID: 'y',
                        label: {
                            display: false,
                            position: 'end',
                            drawTime: 'afterDatasetsDraw',
                            content: () => [item.map],
                            z: 10
                        },
                        enter: (item: any) => {
                            item.element.label.options.display = true;
                            return true;
                        },
                        leave: (item: any) => {
                            item.element.label.options.display = false;
                            return true;
                        }
                    });

                    prevItem = item;
                }
            }
        }

        const reuslt: ChartOptions<"line"> = {
            responsive: true,
            interaction: {
                intersect: false,
                axis: 'xy',
                mode: 'nearest',
            },
            plugins: {
                title: {
                    display: false,
                },
                subtitle: {
                    display: false,
                },
                legend: {
                    display: false
                },
                annotation: {
                    annotations: annotations
                },
                tooltip: {
                    callbacks: {
                        label: (item) => {
                            const playerCount = item.dataset.data[item.dataIndex];

                            if (playerCount === 1) {
                                return `${item.formattedValue} ${t("server_status.sparkline.player")}`;
                            }

                            return `${item.formattedValue} ${t("server_status.sparkline.players")}`;
                        }
                    },
                }
            },
            scales: {
                x: {
                    display: false,
                },
                y: {
                    beginAtZero: true,
                    suggestedMin: 0,
                    grid: {
                        display: false,
                        color: theme.palette.text.primary
                    },
                    ticks: {
                        stepSize: 1,
                        backdropColor: theme.palette.info.dark,
                        color: theme.palette.getContrastText(theme.palette.info.dark),
                        font: {
                            family: theme.typography.fontFamily,
                        }
                    },
                },
            },
        };

        return reuslt;
    }, [theme, props.serverInfo, t]);

    const data = React.useMemo(() => {
        if (!props.serverInfo || !props.serverInfo.history) {
            return { labels: [], datasets: [] };
        }

        const data = props.serverInfo.history.filter(h => h.map.length !== 0).map(h => h.count);

        const currentPlayerCount = props.serverInfo.player_count - props.serverInfo.bot_count;

        if (data[data.length - 1] !== currentPlayerCount) {
            data.push(currentPlayerCount);
        }

        return {
            labels: props.serverInfo.history.filter(h => h.map.length !== 0).map(h => new Date(h.time).toLocaleTimeString()),
            datasets: [
                {
                    label: "label",
                    data: data,
                    borderColor: 'rgba(194, 80, 192, 1)',
                }
            ]
        };
    }, [props.serverInfo]);

    if (data.datasets.length === 0) {
        return null;
    }

    return <div
        style={{
            height: "fit-content",
            width: "fit-content",
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        }}
    >
        <Line
            options={options}
            data={data}
        />
    </div>;
};
