import './App.css';
import { HashRouter as Router } from "react-router-dom";
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';

import { CookiesProvider } from 'react-cookie';

import { PaperElevation, STRIPE_PUBLIC_KEY } from './Config';
import RoutableApp from './RoutableApp';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    contentPaper: {
      backgroundColor: "#282828",
    },
  })
);

function App() {
  const classes = useStyles();
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

  return <Paper elevation={PaperElevation} className={classes.contentPaper}>
    <Elements stripe={stripePromise}>
      <Router basename="/">
        <CookiesProvider>
          <RoutableApp />
        </CookiesProvider>
      </Router>
    </Elements>
  </Paper>;
}

export default App;
