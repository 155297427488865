import { createStyles, makeStyles, Tab, Tabs, Theme } from "@material-ui/core";
import React from "react";
import TabPanel from "../TabPanelComp";
import ServerRecord from "./ServerRecord";
import ServerRecordListComp, { SortMode } from "./ServerRecordListComp";

export interface WeaponTableCompProps {
  jwt: string | null,
  records: ServerRecord[],
  isNoob?: boolean,
  isAa100: boolean,
  useCustomIndex?: boolean,
  defaultSortMode?: SortMode,
  set_loading: (state: boolean) => void,
  disableSorting?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(4)
    }
  })
);

export default function NewWeaponTableComp(props: WeaponTableCompProps) {
  const classes = useStyles();

  const [tabIndex, set_tabIndex] = React.useState(0);

  const wpnStr = (wpn: string) => {
    if (wpn === "knife" || wpn === "usp") {
      return "USP/Knife";
    }

    return wpn;
  };

  const filteredRecords = props.records.filter(rec => ((props.isNoob && rec.gcs !== 0) || (!props.isNoob && rec.gcs === 0)))
    .map(rec => {
      return {
        ...rec,
        weapon: wpnStr(rec.weapon)
      };
    });
  const weapons = Array.from(new Set(filteredRecords.map(rec => rec.weapon))).filter(wpn => wpn.length !== 0);

  return <div>
    <div className={classes.tabsWrapper}>
      <Tabs
        value={tabIndex}
        onChange={(_, value) => set_tabIndex(value)}
        indicatorColor="primary"
      >
        {weapons.map((wpn, index) => {
          const recordsCount = filteredRecords.filter(rec => rec.weapon === wpn).length;
          let recordsCountStr = "";

          if (recordsCount !== 0) {
            recordsCountStr = ` (${recordsCount})`;
          }

          return <Tab label={`${wpn}${recordsCountStr}`} key={index} />;
        })}
      </Tabs>
    </div>

    {weapons.map((wpn, index) => <TabPanel value={tabIndex} index={index} key={`${wpn}_${index}_${props.isAa100}`} noMarginTop>
      <ServerRecordListComp
        defaultSortMode={props.defaultSortMode}
        jwt={props.jwt}
        useCustomIndex={props.useCustomIndex}
        set_loading={props.set_loading}
        disableSorting={props.disableSorting}
        isNoob={props.isNoob}
        records={filteredRecords.filter(rec => rec.weapon === wpn)}
        isAa100={props.isAa100}
      />
    </TabPanel>)}
  </div>;
};
