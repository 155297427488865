import { Button, CircularProgress, createStyles, makeStyles, Paper, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../../Config";
import StripePrice from "../StripePrice";
import User from "../../user/User";
import { formatDate } from "../../Util";

export interface ShopItemCompProps {
  jwt: string | null,
  item: StripePrice,
  user: User,
  reloadShopItems: () => void,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: theme.palette.background.default,
      borderStyle: "solid",
      borderWidth: theme.spacing(0.1),
      borderColor: theme.palette.primary.light,
      borderRadius: theme.spacing(2),
      paddingTop: theme.spacing(1),
    }
  })
);

export const ActivateItem = (jwt: string, price_id: string, name: string, wpn: string) => {
  return fetch(`${API_URL}/shop/activate_item`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${jwt}`
    },
    body: JSON.stringify({
      item_price_id: price_id,
      item_name: name,
      for_weapon: wpn
    })
  });
};

export default function ShopItemComp(props: ShopItemCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [loading, set_loading] = React.useState(false);
  // const [errorMsg, set_errorMsg] = React.useState<string | null>(null);
  // const [subscriptionData, set_subscriptionData] = React.useState<SubscriptionData | null>(null);

  // const onBuy = React.useCallback(() => {
  //   set_loading(true);

  //   fetch(`${API_URL}/create-subscription/${props.item.id}`, {
  //     method: "POST",
  //     headers: {
  //       "Authorization": `Bearer ${props.jwt}`
  //     },
  //   }).then(async (response) => {
  //     const reply = await response.json();

  //     if (response.status !== 200) {
  //       set_errorMsg(reply.error.message);
  //     } else {
  //       set_errorMsg(null);

  //       console.dir(reply);
  //       set_subscriptionData(reply as SubscriptionData);
  //     }
  //   }).finally(() => {
  //     set_loading(false);
  //   });
  // }, [props]);

  const activateItem = React.useCallback(() => {
    if (!props.item.owned || !props.jwt) {
      return;
    }

    set_loading(true);

    ActivateItem(props.jwt, props.item.id, props.item.item.metadata.name, props.item.item.metadata.weapon)
      .then(async (response) => {
        if (response.status !== 200) {
          console.error(response);
        } else {
          props.reloadShopItems();
        }
      }).finally(() => {
        set_loading(false);
      });
  }, [props]);

  const cancelSubscription = React.useCallback(() => {
    if (!props.item.owned) {
      return;
    }

    set_loading(true);

    fetch(`${API_URL}/shop/cancel_item`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${props.jwt}`
      },
      body: JSON.stringify({
        item_price_id: props.item.id
      })
    }).then(async (response) => {
      if (response.status !== 200) {
        console.log(response);
      } else {
        props.reloadShopItems();
      }
    }).finally(() => {
      set_loading(false);
    });
  }, [props]);

  const deactivateItem = React.useCallback(() => {
    if (!props.item.owned) {
      return;
    }

    set_loading(true);

    fetch(`${API_URL}/shop/deactivate_item`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${props.jwt}`
      },
      body: JSON.stringify({
        item_price_id: props.item.id,
        for_weapon: props.item.item.metadata.weapon
      })
    }).then(async (response) => {
      if (response.status !== 200) {
        console.log(response);
      } else {
        props.reloadShopItems();
      }
    }).finally(() => {
      set_loading(false);
    });
  }, [props]);

  // if (subscriptionData) {
  //   return <Redirect to={{
  //     pathname: '/subscribe',
  //     state: {
  //       subscriptionData: subscriptionData,
  //       item: props.item,
  //       jwt: props.jwt
  //     }
  //   }} />;
  // }

  return <Paper className={classes.paper}>
    <Typography
      variant="overline"
      style={{
        paddingLeft: theme.spacing(2)
      }}
    >
      {props.item.item.name}
    </Typography>

    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src={props.item.item.images[0]}
        alt={props.item.item.name}
        width="100%"
        // style={{
        //   borderBottomRightRadius: theme.spacing(2),
        //   borderBottomLeftRadius: theme.spacing(2)
        // }}
      />
    </div>

    <div>
      <form action={`${API_URL}/create-checkout-session/${props.item.id}/${props.item.item.metadata.name}/${props.item.item.metadata.weapon}/${props.user.steamID}`} method="POST">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading || props.item.owned}
          style={{
            borderBottomLeftRadius: props.item.owned ? 0 : theme.spacing(2),
            borderBottomRightRadius: props.item.owned ? 0 : theme.spacing(2),
          }}
        >
          {props.item.owned
            ? <Typography>
                {t("shop.already_owned")}
              </Typography>
            : loading
              ? <CircularProgress size={theme.spacing(3)} color="secondary" />
              : /* (errorMsg !== null)
                ? <>{errorMsg}</>
                : */
              <div>
                <Typography>{t("shop.buy_label")}</Typography>

                <Typography variant="caption">
                  {props.item.price / 100} {props.item.currency.toUpperCase()}/{t(`shop.${props.item.recurring}`)}
                </Typography>
              </div>
          }
        </Button>
      </form>

      {props.item.ends_on &&
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Typography>
            {t("shop.already_cancelled", {date: formatDate(new Date(props.item.ends_on * 1000))})}
          </Typography>
        </div>
      }

      {(props.item.owned && ! props.item.ends_on) &&
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            rowGap: theme.spacing(2),
            width: "100%"
          }}
        >
          <div style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            width: "100%"
          }}>
            <Tooltip
              title={t(props.item.is_model_active ? "shop.deactivate_item_tooltip" : "shop.activate_item_tooltip")}
            >
              <Button
                onClick={props.item.is_model_active ? deactivateItem : activateItem}
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: props.item.is_model_active ? theme.palette.primary.dark : "green",
                  color: theme.palette.text.primary,
                  borderBottomLeftRadius: theme.spacing(2),
                }}
              >
                <Typography>
                  {props.item.is_model_active ? t("shop.deactivate_item") : t("shop.activate_item")}
                </Typography>
              </Button>
            </Tooltip>

            <Tooltip
              title={t("shop.cancel_item.tooltip")}
            >
              <Button
                onClick={cancelSubscription}
                variant="contained"
                fullWidth
                style={{
                  backgroundColor: "red",
                  color: theme.palette.text.primary,
                  borderBottomRightRadius: theme.spacing(2),
                }}
              >
                <Typography>
                  {t("shop.cancel_item.label")}
                </Typography>
              </Button>
            </Tooltip>
          </div>
        </div>
      }
    </div>
  </Paper>;
};
