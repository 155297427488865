import {
  Accordion,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  createStyles,
  makeStyles,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { API_URL, NEW_API_URL } from "../../Config";
import CustomPaper from "../../CustomPaper";
import ServerTopEntry from "../../server/ServerTopEntry";
import TabPanel from "../../TabPanelComp";
import TopDataEntry from "../../top/TopDataEntry";
import TopListComp from "../../top/TopListComp";
import HelpIcon from "@material-ui/icons/Help";
import { useParams } from "react-router-dom";

export interface TopProps {
  jwt: string | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
    itemsWrapper: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      alignItems: "baseline",
    },
    itemWrapper: {
      display: "flex",
      justifyContent: "center",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fffffff",
    },
    table: {
      maxWidth: theme.spacing(50),
    },
  })
);

export default function Top(props: TopProps) {
  const params = useParams<{ type: string }>();
  const classes = useStyles();
  const { t } = useTranslation();

  const [userBanned, set_userBanned] = React.useState(false);

  const [topEntries, set_topEntries] = React.useState<TopDataEntry[]>([]);
  const [serverTopEntries, set_serverTopEntries] = React.useState<
    ServerTopEntry[]
  >([]);
  const [loading, set_loading] = React.useState(true);

  const [tabIndex, set_tabIndex] = React.useState(
    params.type === "site" ? 0 : 1
  );
  const [expanded, set_expanded] = React.useState(false);

  React.useEffect(() => {
    if (tabIndex === 0 && topEntries.length === 0) {
      fetch(`${NEW_API_URL}/demos/kz/top?usp=1&count=100`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${props.jwt}`,
        },
      })
        .then(async (response) => {
          if (response.status !== 200) {
            if (response.status === 423) {
              set_userBanned(true);
            }
          } else {
            set_topEntries(await response.json());
          }
        })
        .finally(() => {
          set_loading(false);
        });
    }
  }, [props.jwt, tabIndex, topEntries.length]);

  React.useEffect(() => {
    if (tabIndex === 1 && serverTopEntries.length === 0) {
      set_loading(true);

      fetch(`${API_URL}/servertop/all`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${props.jwt}`,
        },
      })
        .then(async (response) => {
          if (response.status !== 200) {
            // TODO error handling
          } else {
            set_serverTopEntries(await response.json());
          }
        })
        .finally(() => {
          set_loading(false);
        });
    }
  }, [props.jwt, tabIndex, serverTopEntries.length]);

  if (!props.jwt) {
    return <div className={classes.wrapperDiv}>{t("unauthorized")}</div>;
  }

  if (userBanned) {
    return <div className={classes.wrapperDiv}>{t("banned")}</div>;
  }

  return (
    <CustomPaper>
      <div className={classes.wrapperDiv}>
        <Typography variant="overline">{t("menu.demosTop")}</Typography>

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress size="5rem" color="secondary" />
        </Backdrop>

        <Tabs
          value={tabIndex}
          onChange={(_, value) => set_tabIndex(value)}
          indicatorColor="primary"
          centered
        >
          <Tab label="Site" />
          <Tab label="Server" />
        </Tabs>

        <TabPanel value={tabIndex} index={0}>
          {topEntries.length !== 0 && <TopListComp entries={topEntries} />}
        </TabPanel>

        <TabPanel value={tabIndex} index={1}>
          {serverTopEntries.length !== 0 && (
            <>
              <Accordion
                style={{
                  backgroundColor: "transparent",
                }}
                square
                expanded={expanded}
                onChange={() => set_expanded((old) => !old)}
              >
                <AccordionSummary expandIcon={<HelpIcon />} />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography>{t("top.points_rules.1")}</Typography>

                  <Typography>{t("top.points_rules.2")}</Typography>

                  <TableContainer
                    component={Paper}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Table className={classes.table}>
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">Pro</TableCell>
                          <TableCell align="center">Noob</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <TableContainer
                              component={Paper}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Table className={classes.table}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">
                                      {t("top.points_rules.table.placement")}
                                    </TableCell>
                                    <TableCell align="center">
                                      {t("top.points_rules.table.points")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {Array.from(Array(14).keys()).map((place) => (
                                    <TableRow>
                                      <TableCell align="center">
                                        {place + 1}
                                      </TableCell>

                                      <TableCell align="center">
                                        {30 - place}
                                      </TableCell>
                                    </TableRow>
                                  ))}

                                  <TableRow>
                                    <TableCell align="center">...</TableCell>
                                    <TableCell align="center">16</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </TableCell>

                          <TableCell>
                            <TableContainer
                              component={Paper}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Table className={classes.table}>
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="center">
                                      {t("top.points_rules.table.placement")}
                                    </TableCell>
                                    <TableCell align="center">
                                      {t("top.points_rules.table.points")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {Array.from(Array(14).keys()).map((place) => (
                                    <TableRow>
                                      <TableCell align="center">
                                        {place + 1}
                                      </TableCell>

                                      <TableCell align="center">
                                        {15 - place}
                                      </TableCell>
                                    </TableRow>
                                  ))}

                                  <TableRow>
                                    <TableCell align="center">...</TableCell>
                                    <TableCell align="center">1</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Accordion>

              <TopListComp entries={serverTopEntries} />
            </>
          )}
        </TabPanel>
      </div>
    </CustomPaper>
  );
}
