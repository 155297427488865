import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { NEW_API_URL } from "../Config";
import CustomPaper from "../CustomPaper";
import ServerRecordings from "../demo/page/ServerRecordings";
import User from "../user/User";
import RecordFileData from "../viewdemo/RecordFileData";
import { UnregisteredUser } from "./ServerRecord";
import { Skeleton } from "@material-ui/lab";

export interface LastServerRecordsCompProps {
  jwt: string,
  set_loading: (state: boolean) => void,
  user: User | undefined
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddedWrapper: {
      padding: theme.spacing(2),
    },
    list: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      maxHeight: theme.spacing(40),
    },
    itemWrapper: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(1)
    },
    leftAlignedItemWrapper: {
      display: "flex",
      justifyContent: "flex-start",
      gap: theme.spacing(1)
    },
    lastSRWrapper: {
      paddingBottom: theme.spacing(3)
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
  })
);

export default function LastServerRecordsComp(props: LastServerRecordsCompProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [recordings, set_recordings] = React.useState<{ recording: RecordFileData, user: UnregisteredUser | null }[]>([]);

  React.useEffect(() => {
    fetch(`${NEW_API_URL}/server/recordings/last_hours?hours=24`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        if (response.status === 423) {
        }
      } else {
        const data = await response.json();

        set_recordings(data);
      }
    });
  }, [props.jwt]);

  const recordingsListComp = <ServerRecordings
    jwt={props.jwt}
    set_loading={props.set_loading}
    recordings={recordings.filter(rec => rec.user && (!rec.recording.server || rec.recording.server !== "cups"))}
    user={props.user}
    disableSearch
    disableHeader
    minHeight={300}
  />;

  if (recordings.length === 0) {
    return <div className={classes.lastSRWrapper}>
      <CustomPaper>
        <Skeleton
          height="355px"
          width="100%"
          animation="wave"
          variant="rect"
        >
          <div className={classes.paddedWrapper}>
            <Typography variant="overline">
              {t("server_records.last.title")}
            </Typography>

            {recordingsListComp}
          </div>
        </Skeleton>
      </CustomPaper>
    </div >
  }

  return <div className={classes.lastSRWrapper}>
    <CustomPaper>
      <div className={classes.paddedWrapper}>
        <Typography variant="overline">
          {t("server_records.last.title")}
        </Typography>

        {recordingsListComp}
      </div>
    </CustomPaper>
  </div>;
};
