import { Backdrop, Button, CircularProgress, createStyles, Fade, FormControl, InputLabel, List, ListItem, ListItemText, makeStyles, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Theme, Typography, useTheme } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React from "react";
import { Link } from "react-router-dom";
import CheckDemosResponse, { CVARCheck, isStatusResponse } from "../../demo/CheckDemosResponse";
import { API_URL } from "../../Config";
import User from "../../user/User";
import { isAdmin } from "../../Util";
import { useTranslation } from "react-i18next";
import UploadType, { uploadTypeToStr } from "../UploadType";
import TabPanel from "../../TabPanelComp";
import MapUploadResponse from "../../map/MapUploadResponse";
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';

export interface UploadProps {
  reloadWaitlistCount: () => void,
  jwt: string | null,
  user: User | undefined,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    previewChip: {
      maxWidth: 210
    },
    dropzone: {
      paddingTop: "50px",
      minHeight: "250px"
    },
    contentWrapper: {
      padding: theme.spacing(2)
    },
    centeredWrapper: {
      marginTop: "20px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center"
    },
    resultsWrapper: {
      marginTop: "50px",
      marginBottom: "50px",
      width: "100%",
      display: "flex",
      alignContent: "center",
      alignItems: "center",
      justifyContent: "center"
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fffffff',
    },
    link: {
      color: "white"
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    tabsWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(4)
    }
  })
);

export default function Upload(props: UploadProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const { reloadWaitlistCount, jwt, user } = props;

  const [files, set_files] = React.useState<File[]>([]);
  const [demoCheckReplies, set_demoCheckReplies] = React.useState<CheckDemosResponse[]>([]);
  const [loading, set_loading] = React.useState(false);
  const [successfulUploadCount, set_successfulUploadCount] = React.useState<number>();
  const [userBanned, set_userBanned] = React.useState(false);
  const [uploadType, set_uploadType] = React.useState<UploadType>(UploadType.RegularDemo);

  const [mapUploadResponse, set_mapUploadResponse] = React.useState<MapUploadResponse[]>([]);

  const [tabIndex, set_tabIndex] = React.useState(0);

  const onUpload = React.useCallback(() => {
    set_loading(true);
    set_successfulUploadCount(undefined);
    set_demoCheckReplies([]);

    const data = new FormData();

    data.append("userData", JSON.stringify({
      user: user?.displayName,
      uploadType: uploadType
    }));

    for (let demo of files) {
      data.append("demos", demo);
    }

    fetch(`${API_URL}/demos`, {
      method: "POST",
      body: data,
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        if (response.status === 423) {
          set_userBanned(true);
        }
      } else {
        const reply = await response.json() as CheckDemosResponse[];
        const badDemos = reply.filter((demo: CheckDemosResponse) => (!isStatusResponse(demo.checks) && demo.checks.diffs.length !== 0) ||
          (demo.extraChecks.length !== 0) ||
          (isStatusResponse(demo.checks) && demo.checks.status === "error"));

        set_demoCheckReplies(badDemos);
        set_successfulUploadCount(files.length - badDemos.length);
      }
    }).catch((reason) => {
      console.log(reason);
    }).finally(() => {
      set_loading(false);

      if (user && isAdmin(user)) {
        reloadWaitlistCount();
      }
    });
  }, [files, jwt, reloadWaitlistCount, user, uploadType]);

  const onUploadMap = () => {
    set_loading(true);

    const data = new FormData();

    data.append("userData", JSON.stringify({
      user: user?.displayName,
    }));

    for (let map of files) {
      data.append("maps", map);
    }

    fetch(`${API_URL}/maps`, {
      method: "POST",
      body: data,
      headers: {
        "Authorization": `Bearer ${jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        if (response.status === 423) {
          set_userBanned(true);
        }
      } else {
        set_mapUploadResponse(await response.json());
      }
    }).catch((reason) => {
      console.log(reason);
    }).finally(() => {
      set_loading(false);
    });
  };

  if (!jwt || !user) {
    return <div className={classes.contentWrapper}>
      {t("unauthorized")}
    </div>;
  }

  if (userBanned) {
    return <div className={classes.contentWrapper}>
      {t("banned")}
    </div>;
  }

  return <div className={classes.contentWrapper}>
    <Typography variant="overline">
      {t("menu.upload")}
    </Typography>

    <div className={classes.tabsWrapper}>
      <Tabs
        value={tabIndex}
        onChange={(_, value) => set_tabIndex(value)}
        indicatorColor="primary"
      >
        <Tab label="Demo" />
        <Tab label={t("maps.label")} />
      </Tabs>
    </div>

    <TabPanel value={tabIndex} index={0} noMarginTop>
      <div className={classes.contentWrapper}>
        {successfulUploadCount === undefined &&
          <>
            <Fade in timeout={500} unmountOnExit>
              <div>
                <div className={classes.contentWrapper}>
                  {t("upload.before_upload.1")} <Link to={`/rules`} className={classes.link}>
                    {t("upload.before_upload.2")}
                  </Link>
                </div>

                <DropzoneArea
                  showAlerts={["error"]}
                  acceptedFiles={['.dem']}
                  dropzoneText={t("upload.help")}
                  onChange={(files: File[]) => set_files(files)}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  filesLimit={100}
                  maxFileSize={1000000000 * 1000}
                  previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText=""
                  getFileLimitExceedMessage={(filesLimit: number) => {
                    return t("upload.max_files_exceeded", { count: filesLimit });
                  }}
                  dropzoneClass={classes.dropzone}
                />
              </div>
            </Fade>

            <Fade in timeout={500} unmountOnExit>
              <div className={classes.centeredWrapper}>
                <div>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="uploadTypeLabelId">{t("challenge.create.type")}</InputLabel>

                    <Select
                      labelId="uploadTypeLabelId"
                      value={uploadType}
                      onChange={(e) => set_uploadType(e.target.value as number)}
                    >
                      <MenuItem value={UploadType.RegularDemo}>
                        {uploadTypeToStr(UploadType.RegularDemo)}
                      </MenuItem>

                      <MenuItem value={UploadType.ChallengeDemo}>
                        {uploadTypeToStr(UploadType.ChallengeDemo)}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <Button
                  color="primary"
                  variant="contained"
                  disabled={files.length === 0}
                  onClick={onUpload}
                >
                  Upload
                </Button>
              </div>
            </Fade>
          </>
        }

        <div className={classes.centeredWrapper}>
          {successfulUploadCount !== undefined && successfulUploadCount !== 0 &&
            <Typography variant="h5">
              {successfulUploadCount === 1 ? t("upload.demo_sent")
                : t("upload.demos_sent", { count: successfulUploadCount })
              }
            </Typography>
          }
        </div>

        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress size="5rem" color="secondary" />
        </Backdrop>

        {demoCheckReplies.length !== 0 &&
          <div className={classes.resultsWrapper}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><Typography>{t("upload.problem.title")}</Typography></TableCell>
                    <TableCell align="center"><Typography>CVAR</Typography></TableCell>
                    <TableCell align="center"><Typography>{t("upload.problem.demo_value")}</Typography></TableCell>
                    <TableCell align="center"><Typography>{t("upload.problem.correct_value")}</Typography></TableCell>
                    <TableCell align="center"><Typography>{t("upload.problem.other")}</Typography></TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {demoCheckReplies.map((row: CheckDemosResponse) => {
                    if (isStatusResponse(row.checks)) {
                      if (row.checks.message) {
                        return <TableRow key={row.demo}>
                          <TableCell component="th" scope="row">
                            {row.demo}
                          </TableCell>

                          <TableCell align="center"><Typography><i>n/a</i></Typography></TableCell>
                          <TableCell align="center"><Typography><i>n/a</i></Typography></TableCell>
                          <TableCell align="center"><Typography><i>n/a</i></Typography></TableCell>

                          <TableCell align="center">
                            <Typography color="error"><b>{row.checks.message}</b></Typography>
                          </TableCell>
                        </TableRow>;
                      }

                      // return <TableRow key={row.demo}>
                      //   <TableCell component="th" scope="row">
                      //     <Typography>{row.demo}</Typography>
                      //   </TableCell>
                      // </TableRow>;

                      return row.extraChecks.map((msg, index) => {
                        return <TableRow key={`${row.demo}_${index}_extra`}>
                          <TableCell component="th" scope="row">
                            {row.demo}
                          </TableCell>

                          <TableCell align="center"><Typography><i>n/a</i></Typography></TableCell>
                          <TableCell align="center"><Typography><i>n/a</i></Typography></TableCell>
                          <TableCell align="center"><Typography><i>n/a</i></Typography></TableCell>

                          <TableCell align="center">
                            <Typography color="error"><b>{msg}</b></Typography>
                          </TableCell>
                        </TableRow>;
                      });
                    }

                    return row.checks.diffs.map((check: CVARCheck, index) => {
                      return <TableRow key={`${row.demo}_${index}`}>
                        <TableCell component="th" scope="row">
                          <Typography>{row.demo}</Typography>
                        </TableCell>

                        <TableCell align="center">
                          <Typography>{check.cvar}</Typography>
                        </TableCell>

                        <TableCell align="center">
                          <Typography color="error"><b>{check.actual}</b></Typography>
                        </TableCell>

                        <TableCell align="center">
                          <Typography>{check.expected}</Typography>
                        </TableCell>
                      </TableRow>
                    })
                  }
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        }
      </div>
    </TabPanel>

    <TabPanel value={tabIndex} index={1} noMarginTop>
      <div className={classes.contentWrapper}>
        <Fade in timeout={500} unmountOnExit>
          <div>
            {mapUploadResponse.length === 0 &&
              <>
                <div className={classes.contentWrapper}>
                  {t("upload.before_upload.1")} <Link to={`/rules`} className={classes.link}>
                    {t("upload.before_upload.2")}
                  </Link>
                </div>

                <DropzoneArea
                  showAlerts={["error"]}
                  acceptedFiles={['.zip']}
                  dropzoneText={t("upload.help_map")}
                  onChange={(files: File[]) => set_files(files)}
                  showPreviews={true}
                  showPreviewsInDropzone={false}
                  useChipsForPreview
                  filesLimit={10}
                  maxFileSize={10000000 * 1000}
                  previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                  previewChipProps={{ classes: { root: classes.previewChip } }}
                  previewText=""
                  getFileLimitExceedMessage={(filesLimit: number) => {
                    return t("upload.max_files_exceeded", { count: filesLimit });
                  }}
                  dropzoneClass={classes.dropzone}
                />

                <Fade in timeout={500} unmountOnExit>
                  <div className={classes.centeredWrapper}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={files.length === 0}
                      onClick={onUploadMap}
                    >
                      Upload
                    </Button>
                  </div>
                </Fade>
              </>
            }

            {mapUploadResponse.length !== 0 &&
              <List>
                {mapUploadResponse.map(response =>
                  <ListItem>
                    <ListItemText
                      primary={
                        <div
                          style={{
                            display: "flex",
                            gap: theme.spacing(4),
                            alignItems: "center"
                          }}
                        >
                          <Typography>
                            {response.name}
                          </Typography>

                          {response.error
                            ? <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: theme.spacing(1)
                              }}
                            >
                              <BlockIcon style={{ color: "rgb(255, 0, 0)" }} fontSize="large" />
                              <Typography>{response.error}</Typography>
                            </div>
                            : <DoneIcon style={{ color: "rgb(0, 255, 0)" }} fontSize="large" />}
                        </div>
                      }
                    />
                  </ListItem>)
                }
              </List>
            }
          </div>
        </Fade>
      </div>
    </TabPanel>
  </div>;
};
