enum LJTechnique {
    LJ,
    CJ,
    DCJ,
    MCJ = DCJ,
    HJ,
    SBJ,
    BJ,
    WJ,
    LDJ,
    SLJ
};

export const toLJTechnique = (str: string) => {
    switch (str.toUpperCase()) {
        case 'LJ': return LJTechnique.LJ;
        case 'CJ': return LJTechnique.CJ;
        case 'DCJ':
        case 'MCJ': return LJTechnique.MCJ;
        case 'HJ': return LJTechnique.HJ;
        case 'SBJ': return LJTechnique.SBJ;
        case 'BJ': return LJTechnique.BJ;
        case 'WJ': return LJTechnique.WJ;
        case 'LDJ': return LJTechnique.LDJ;
        case 'SLJ': return LJTechnique.SLJ;
    }

    console.error(`Invalid LJTechnique '${str}'`);

    throw new Error();
};

export const techniqueToStr = (technique: LJTechnique) => {
    switch (technique) {
        case LJTechnique.LJ: return 'LJ';
        case LJTechnique.CJ: return 'CJ';

        case LJTechnique.DCJ:
        case LJTechnique.MCJ: return 'MCJ';

        case LJTechnique.HJ: return 'HJ';
        case LJTechnique.SBJ: return 'SBJ';
        case LJTechnique.BJ: return 'BJ';
        case LJTechnique.WJ: return 'WJ';
        case LJTechnique.LDJ: return 'LDJ';
        case LJTechnique.SLJ: return 'SLJ';
    }

    console.error(`Invalid LJTechnique '${technique}'`);

    throw new Error();
};

export default LJTechnique;
