import { createStyles, Link, makeStyles, Theme, Tooltip } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../Config";
import LJDemoData from "./LJDemoData";
import { techniqueToStr } from "./LJTechnique";
import { saveAs } from "file-saver";

export interface LJBlockLinkCompProps {
  jwt: string | null,
  demo: LJDemoData,
  children?: React.ReactElement<any, any>,
  className?: string,
  set_downloading: (state: boolean) => void,
  on_unauthorized: () => void,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: "white",
      cursor: "grabbing"
    },
  })
);

export default function LJBlockLinkComp(props: LJBlockLinkCompProps) {
  const classes = useStyles();
  const {t} = useTranslation();

  const onDemoLinkClick = React.useCallback(() => {
    if (! props.jwt) {
      props.on_unauthorized && props.on_unauthorized();

      return;
    }

    props.set_downloading(true);

    fetch(`${API_URL}/demo/lj/${props.demo.demoMD5}`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        const data = await fetch(await response.text());

        saveAs(await data.blob(), `${techniqueToStr(props.demo.technique).toLowerCase()}_${props.demo.block}_${props.demo.uploader!.displayName}.zip`);
      }
    }).finally(() => {
      props.set_downloading(false);
    });
  }, [props]);

  return <Tooltip title={`${t("demo.download_lj", { block: props.demo.block, technique: techniqueToStr(props.demo.technique) })}`}>
    <Link className={props.className ? props.className : classes.link} onClick={onDemoLinkClick}>
      {props.children ? props.children : <>Block {props.demo.block}</>}
    </Link>
  </Tooltip>;
};
