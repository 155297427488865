enum UploadType {
  RegularDemo,
  ChallengeDemo
};

export const uploadTypeToStr = (type: UploadType) => {
  switch (type) {
    case UploadType.RegularDemo: return "Normal";
    case UploadType.ChallengeDemo: return "Challenge";
  }
};

export default UploadType;
