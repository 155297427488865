import { createStyles, List, ListItem, ListItemText, makeStyles, Theme, Tooltip, Typography } from "@material-ui/core";

import AuthorLink from "../user/AuthorLink";
import TopDataEntry from "./TopDataEntry";
import { useTranslation } from "react-i18next";

export interface TopCompProps {
  topEntries: TopDataEntry[]
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    },
    itemContentWrapper: {
      display: "flex",
      alignItems: "center"
    }
  })
);

export default function TopComp(props: TopCompProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  return <List>
    {props.topEntries.sort((a, b) => a.count > b.count ? -1 : 1).map((entry, index) =>
      <ListItem key={index}>
        <ListItemText primary={
          <div className={classes.itemContentWrapper}>
            <Typography color="textSecondary">
              # {index + 1}
            </Typography>

            <div style={{ marginLeft: "auto" }}>
              <AuthorLink author={entry.player} steam_id={entry.player.steamID} avatar />
            </div>

            <Tooltip title={`${t("top.demos_count", { demosCount: entry.count.toLocaleString() })}`}>
              <Typography style={{ marginLeft: "auto" }} color="textSecondary">
                {entry.count.toLocaleString()}
              </Typography>
            </Tooltip>
          </div>
        } />
      </ListItem>
    )}
  </List>;
};
