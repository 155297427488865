import { createStyles, List, ListItem, ListItemText, makeStyles, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { API_URL } from "../Config";
import CustomPaper from "../CustomPaper";
import DemoWaitlistData from "../demo/DemoWaitlistData";
import AuthorLink from "../user/AuthorLink";
import User from "../user/User";
import { groupBy } from "../Util";
import { playerPositionInTopToPoints } from "./Challenge";

export interface ActiveChallengeLeaderboardProps {
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperDiv: {
      padding: theme.spacing(2),
    },
  })
);

export default function ActiveChallengeLeaderboard(props: ActiveChallengeLeaderboardProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [demos, set_demos] = React.useState<DemoWaitlistData[]>([]);

  React.useEffect(() => {
    fetch(`${API_URL}/challenges/active/demos`, {
      method: "GET",
    }).then(async (response) => {
      if (response.status !== 200) {
      } else {
        set_demos(await response.json());
      }
    }).catch((reason) => {
      console.error(reason);
    });
  }, []);

  const byMap = groupBy(demos, (d) => d.map);

  const getOverallScores = React.useCallback(() => {
    interface DataPoint {
      player: User,
      points: number
    };

    let results: DataPoint[] = [];

    byMap.forEach(demos => {
      demos.sort((a, b) => a.time < b.time ? -1 : 1)
        .forEach((demo, demoIndex) => {
          if (!demo.uploader) {
            return;
          }

          const existingDp = results.find(dp => dp.player.steamID === demo.uploader!.steamID);
          const points = playerPositionInTopToPoints(demoIndex);

          if (existingDp) {
            existingDp.points += points;
          } else {
            results.push({
              player: demo.uploader as User,
              points: points
            });
          }
        });
    });

    return results.sort((a, b) => a.points > b.points ? -1 : 1);
  }, [byMap]);

  if (demos.length === 0) {
    return null;
  }

  return <CustomPaper>
    <div className={classes.wrapperDiv}>
      <Typography variant="overline">
        {t("challenge.active_leaderboard")}
      </Typography>

      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <List>
          {getOverallScores().map((dp, index) =>
            <ListItem key={index}>
              <ListItemText primary={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: theme.spacing(4)
                  }}
                >
                  <AuthorLink author={dp.player} steam_id={dp.player.steamID} avatar small />

                  <Tooltip title={`${t("challenge.points")}`}>
                    <Typography>
                      {dp.points}
                    </Typography>
                  </Tooltip>
                </div>
              } />
            </ListItem>
          )}
        </List>
      </div>
    </div>
  </CustomPaper>;
};
