import { Chip, createStyles, FormControl, Input, InputLabel, makeStyles, MenuItem, Select, Theme } from "@material-ui/core";
import React from "react";
import { types, typeToColor, typeToTextColor } from "./Types";

export interface ServerAdminMapTypeCompProps {
  types: number[],
  set_types: (types: number[]) => void,
  fullWidth?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  })
);

export default function ServerAdminMapTypeComp(props: ServerAdminMapTypeCompProps) {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    props.set_types(event.target.value as number[]);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 4;

  return <FormControl
    className={classes.formControl}
    style={props.fullWidth ? undefined : {
      maxWidth: 300
    }}
  >
    <InputLabel id="mutiple-chip-label">Types</InputLabel>

    <Select
      labelId="mutiple-chip-label"
      id="mutiple-chip"
      multiple
      fullWidth={props.fullWidth}
      value={props.types}
      onChange={handleChange}
      input={<Input id="select-multiple-chip" />}
      renderValue={(selected) => (
        <div className={classes.chips}>
          {(selected as number[]).sort().map((value) => (
            <Chip
              key={value}
              label={types[value]}
              className={classes.chip}
              style={{
                backgroundColor: typeToColor(types[value]),
                color: typeToTextColor(types[value])
              }}
            />
          ))}
        </div>
      )}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
          },
        },
      }}
    >
      {types.map((type, index) => (
        <MenuItem key={index} value={index}>
          {type}
        </MenuItem>
      ))}
    </Select>
  </FormControl>;
};
