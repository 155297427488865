import { createStyles, makeStyles, Tab, Tabs, Theme, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import TabPanel from "../TabPanelComp";
import ServerRecord from "./ServerRecord";
import { SortMode } from "./ServerRecordListComp";
import UnfinishedMapsComp from './UnfinishedMapsComp';
import { MapInfo } from "../map/MapDataDTO";
import ServerRecordsRouteTabs from "./ServerRecordsRouteTabs";

export interface ServerRecordsTabsProps {
  jwt: string | null,
  records: ServerRecord[],
  unfinishedMaps: MapInfo[],
  useCustomIndex?: boolean,
  defaultSortMode?: SortMode,
  set_loading: (state: boolean) => void,
  disableSorting?: boolean
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tabsWrapper: {
      display: "flex",
      justifyContent: "center",
      paddingBottom: theme.spacing(4)
    },
  })
);

export default function ServerRecordsTabs(props: ServerRecordsTabsProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const [tabIndex, set_tabIndex] = React.useState(0);

  const noCpRecs = React.useMemo(() => {
    return props.records ?
      props.records
        .filter(rec => rec.gcs === 0) : [];
  }, [props.records]);

  const noCpRecsCountStr = React.useMemo(() => {
    if (noCpRecs.length === 0) {
      return "";
    }

    return ` (${noCpRecs.length})`;
  }, [noCpRecs]);

  const cpRecs = React.useMemo(() => {
    return props.records ?
      props.records
        .filter(rec => rec.gcs !== 0) : [];
  }, [props.records]);

  const cpRecsCountStr = React.useMemo(() => {
    if (cpRecs.length === 0) {
      return "";
    }

    return ` (${cpRecs.length})`;
  }, [cpRecs]);

  return <>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: theme.spacing(2)
      }}
    >
      <Typography variant="caption">
        Total {props.records.length} {t(props.records.length === 1 ? 'server_records.count.singular' : 'server_records.count.plural')}
      </Typography>
    </div>

    <div className={classes.tabsWrapper}>
      <Tabs
        value={tabIndex}
        onChange={(_, value) => set_tabIndex(value)}
        indicatorColor="primary"
      >
        <Tab label={`Pro${noCpRecsCountStr}`} />
        <Tab label={`Noob${cpRecsCountStr}`} />

        {(!props.unfinishedMaps || props.unfinishedMaps.length !== 0) && <Tab label={t("server_records.tabs.unfinished")} />}
      </Tabs>
    </div>

    <TabPanel value={tabIndex} index={0} noMarginTop>
      <ServerRecordsRouteTabs
        defaultSortMode={props.defaultSortMode}
        jwt={props.jwt}
        useCustomIndex={props.useCustomIndex}
        set_loading={props.set_loading}
        disableSorting={props.disableSorting}
        records={noCpRecs}
        isNoob={false}
      />
    </TabPanel>

    <TabPanel value={tabIndex} index={1} noMarginTop>
      <ServerRecordsRouteTabs
        defaultSortMode={props.defaultSortMode}
        jwt={props.jwt}
        useCustomIndex={props.useCustomIndex}
        set_loading={props.set_loading}
        disableSorting={props.disableSorting}
        records={cpRecs}
        isNoob={true}
      />
    </TabPanel>

    {(!props.unfinishedMaps || props.unfinishedMaps.length !== 0) &&
      <TabPanel value={tabIndex} index={2} noMarginTop>
        <UnfinishedMapsComp
          maps={props.unfinishedMaps ? props.unfinishedMaps : []}
          jwt={props.jwt}
          set_loading={props.set_loading}
        />
      </TabPanel>
    }
  </>;
};
