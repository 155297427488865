import moment from "moment";
import { API_URL } from "./Config";

import User from "./user/User";
import UserType from "./user/UserType";

export const formatMS = (inputMs: number, includeMinSeparator: boolean = true) => {
    const s = (inputMs / 1000.0) % 60;
    const m = Math.floor((inputMs / 1000) / 60);

    const pad = (input: number) => {
        return input === 0 ? "00" : input < 10 ? `0${input.toString().substr(0, 4)}` : `${input.toString().substr(0, 5)}`;
    };

    let paddedS = pad(s);

    if (paddedS.length === 2) {
        paddedS = `${paddedS}.00`;
    } else if (paddedS.length === 4) {
        paddedS = `${paddedS}0`;
    }

    if (includeMinSeparator) {
        return `${pad(m)}:${paddedS}`;
    }

    return `${pad(m)}${paddedS}`;
};

export const newFormatMS = (inputMs: number, includeMinSeparator: boolean = true) => {
  const min = Math.floor(inputMs / 60000);
  const minStr = min < 10 ? `0${min}` : `${min}`;

  const sec = Math.floor((inputMs - min * 60000) / 1000);
  const secStr = sec < 10 ? `0${sec}` : `${sec}`;

  const ms = Math.floor(inputMs % 1000 / 10);
  const msStr = ms < 10 ? `0${ms}` : `${ms}`;

  return `${minStr}${includeMinSeparator ? ':' : ''}${secStr}.${msStr}`;
};

export const formatDate = (input: Date, noTime?: boolean) => {
  if (noTime) {
    return moment(input).format("DD/MM/YYYY");
  } else {
    return moment(input).format("DD/MM/YYYY HH:mm:ss");
  }
};

export const isAdmin = (user: User | undefined) => {
  if (! user) {
    return false;
  }

  return user.type === UserType.Admin || user.type === UserType.Root;
};

export const isVIP = async (user: User, jwt: string | null) => {
    const result = user.type === UserType.VIP;

    if (result && jwt) {
      const response = await fetch(`${API_URL}/user/${user.steamID}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${jwt}`
        }
      });

      const json = await response.json() as User;

      console.log(json);

      return json.type === UserType.VIP;
    }

    return result;
};

export const groupBy = <T>(list: T[], keyGetter: (_: T) => string) => {
    const map = new Map<string, T[]>();

    list.forEach((item) => {
         const key = keyGetter(item);
         const collection = map.get(key);

         if (!collection) {
             map.set(key, [item]);
         } else {
             collection.push(item);
         }
    });

    return map;
};

export const toTitleCase = (input: string) => {
  const lc = input.toLowerCase();

  return lc[0].toUpperCase() + lc.substring(1);
};