enum NewsType {
    Regular,
    Demo,
    LJ,
    Challenge,
    ChallengeRoundEnd,
    Map
};

export default NewsType;
