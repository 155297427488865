import User from "../user/User";

export enum ChallengeType {
  // ALWAYS add news values at the END of this enum BEFORE LastItem
  Unknown,

  UnbindS,
  BlackFriday,
  SpeedDemon,

  LastItem
};

export const challengeTypeToStr = (type: ChallengeType) => {
  if (type === ChallengeType.UnbindS) {
    return "UnbindS";
  }

  if (type === ChallengeType.BlackFriday) {
    return "BlackFriday";
  }

  if (type === ChallengeType.SpeedDemon) {
    return "SpeedDemon";
  }

  return "Unknown";
};

export const challengeTypeToTranslationKey = (type: ChallengeType) => {
  if (type === ChallengeType.UnbindS) {
    return "challenge.news.start.rules.unbinds";
  }

  if (type === ChallengeType.BlackFriday) {
    return "challenge.news.start.rules.blackfriday";
  }

  if (type === ChallengeType.SpeedDemon) {
    return "challenge.news.start.rules.speeddemon";
  }

  return "";
};

export const playerPositionInTopToPoints = (position: number) => {
  switch (position) {
    case 0: return 12;
    case 1: return 10;
    case 2: return 8;
    case 3: return 6;
    case 4: return 5;
    case 5: return 4;
    case 6: return 3;
    case 7: return 2;

    default: return 1;
  }
};

export default interface Challenge {
  id: number,
  start_date: Date,
  end_date: Date | null,
  type: ChallengeType,
  map_cycle: string,
  map_cycle_duration: number,
  map: string,
  map_start_date: Date,
  winner: User | null,
  winner2: User | null,
  winner3: User | null,
};
