import { createStyles, List, ListItem, ListItemText, makeStyles, Theme, Tooltip, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { API_URL } from "../Config";
import CustomPaper from "../CustomPaper";
import ServerTopEntry from "../server/ServerTopEntry";
import AuthorLink from "../user/AuthorLink";
import { Skeleton } from "@material-ui/lab";

export interface ServerTopCompProps {
  jwt: string | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      padding: theme.spacing(2),
    },
    itemContentWrapper: {
      display: "flex",
      alignItems: "center"
    }
  })
);

export default function ServerTopComp(props: ServerTopCompProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const [topEntries, set_topEntries] = React.useState<ServerTopEntry[]>([]);

  React.useEffect(() => {
    fetch(`${API_URL}/servertop/5`, {
      method: "GET",
      headers: {
        "Authorization": `Bearer ${props.jwt}`
      }
    }).then(async (response) => {
      if (response.status !== 200) {
        // TODO error handling
      } else {
        set_topEntries(await response.json());
      }
    });
  }, [props.jwt]);

  if (topEntries.length === 0) {
    return <CustomPaper>
      <Skeleton
        height="360px"
        width="100%"
        animation="wave"
        variant="rect"
      />
    </CustomPaper>;
  }

  return <CustomPaper>
    <div className={classes.wrapper}>
      <Link
        to="/top/server"
        style={{
          textDecoration: "none"
        }}
      >
        <Typography
          variant="overline"
          style={{
            color: theme.palette.text.primary
          }}
        >
          Server Top 5
        </Typography>
      </Link>

      <List>
        {topEntries
          .sort((a, b) => a.points > b.points ? -1 : 1)
          .map((entry, index) =>
            <ListItem key={index}>
              <ListItemText primary={
                <div className={classes.itemContentWrapper}>
                  <Typography color="textSecondary">
                    # {index + 1}
                  </Typography>

                  <div style={{ marginLeft: "auto" }}>
                    <AuthorLink
                      author={entry.player ? entry.player : entry.player_name}
                      steam_id={entry.player ? entry.player.steamID : entry.player_steam_id}
                      avatar
                    />
                  </div>

                  <Tooltip title={`${t("top.points_count", { pointsCount: entry.points.toLocaleString() })}`}>
                    <Typography style={{ marginLeft: "auto" }} color="textSecondary">
                      {entry.points.toLocaleString()}
                    </Typography>
                  </Tooltip>
                </div>
              } />
            </ListItem>
          )}
      </List>
    </div>
  </CustomPaper>;
};
