enum DemoWaitlistStatus {
    None,
    Aproved,
    Rejected,
    Released,
    Beat,
    Removed,
    Challenge
};

export default DemoWaitlistStatus;

export const demoStatusToStr = (status: DemoWaitlistStatus) => {
  switch (status) {
    case DemoWaitlistStatus.None: return "None";
    case DemoWaitlistStatus.Aproved: return "Aproved";
    case DemoWaitlistStatus.Rejected: return "Rejected";
    case DemoWaitlistStatus.Released: return "Released";
    case DemoWaitlistStatus.Beat: return "Beat";
    case DemoWaitlistStatus.Removed: return "Removed";
    case DemoWaitlistStatus.Challenge: return "Challenge";
  }
};
